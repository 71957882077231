import { MmaProduct } from "../models/MmaProduct";

export const mmaProducts: MmaProduct[] = [
  // {
  //   imageName: 'overviews/dashboard-overview.png',
  //   name: 'Dashboard',
  //   description: 'Visualisez les résultats de vos campagnes',
  //   href: '/dashboard',
  //   color: 'brick',
  //   trackingName: 'dashboard'
  // },
  {
    imageName: 'overviews/create-campaign-overview.webp',
    name: 'Création de campagne',
    description: 'Lancez des campagnes publicitaires en quelques clics',
    href: '/create-campaign',
    trackingName: 'campagnes_ads'
  },
  {
    imageName: 'overviews/e-learning-overview.webp',
    name: 'Formations',
    description: 'Apprenez à améliorer votre stratégie à chaque sortie',
    href: '/e-learning',
    trackingName: 'formations'
  },
  {
    imageName: 'overviews/coaching-overview.webp',
    name: 'Coaching',
    description: 'Réservez des coachings avec des experts',
    href: '/coaching/coachs',
    trackingName: 'coaching'
  }
]