import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  oobCode = '';
  newPasswordForm: FormGroup;
  errorMessage = null;

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  isPasswordValid: boolean = false;
  isConfirmPasswordValid: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private fb: FormBuilder, private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.oobCode = this.activatedRoute.snapshot.queryParams['oobCode'];

    this.newPasswordForm = this.fb.group({
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]]
    });
  }

  togglePasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  validatePassword(): void {
    const password = this.newPasswordForm.controls['password'].value;
    const passwordPattern = this.firebaseService.getPasswordRequirementsRegex();
    this.isPasswordValid = passwordPattern.test(password);
    this.errorMessage = this.isPasswordValid ? '' : this.firebaseService.getPasswordRequirementsMessage();
  }
  
  validateConfirmPassword(): void {
    const password = this.newPasswordForm.controls['password'].value;
    const confirmPassword = this.newPasswordForm.controls['confirmPassword'].value;
    this.isConfirmPasswordValid = password === confirmPassword;
    this.errorMessage = this.isConfirmPasswordValid ? '' : 'Les mots de passe entrés ne correspondent pas';
  }
  
  canSubmit(): boolean {
    return this.newPasswordForm.valid && this.isPasswordValid && this.isConfirmPasswordValid;
  }

  setPassword(): void {
    const password = this.newPasswordForm.controls['password'].value;
    const confirmPassword = this.newPasswordForm.controls['confirmPassword'].value;

    if (!password) {
      this.errorMessage = 'Veuillez renseigner un mot de passe';
      return;
    }

    if (password !== confirmPassword) {
      this.errorMessage = 'Les mots de passe entrés ne correspondent pas';
      return;
    }


    this.firebaseService.confirmPasswordReset(this.oobCode, password)
      .then(() => {
        this.errorMessage = '';
        alert("Mot de passe modifié avec succès !");
        this.router.navigate(['./login'], { relativeTo: this.activatedRoute.parent });
      })
      .catch((err) => {
        this.errorMessage = err;
      });
  }

}
