import { IHowItWorksCards } from "../models/HowItWorksCards";

export const howItWorksCards: IHowItWorksCards[] = [
  {
    title: `Choix d’un coach `,
    description: `Choisissez l’expert qu’il vous faut en fonction de vos besoins.`
  },
  {
    title: `Réservation de la session`,
    description: `Réservez votre appel de découverte gratuitement. Une fois votre appel terminé, choisissez votre formule et réservez votre session sur le créneau de votre choix.`
  },
  {
    title: `Début du coaching`,
    description: `Connectez-vous d’où vous voulez et bénéficiez d’un coaching sur-mesure en visioconférence.`
  }
]