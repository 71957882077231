import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { Observable, firstValueFrom, map, of } from 'rxjs';
import { ICourse, IELearningMetadata, IMasterclass, IMasterclassVersion } from '@shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MasterclassService {

  rootURL = '/api';

  constructor(private firebaseService: FirebaseService, private http: HttpClient) { }

  getMasterclassCourses(masterclass: IMasterclass): ICourse[] {
    const courses = [];
    masterclass.modules.forEach((module) => module.chapters.forEach((chapter) => courses.push(...chapter.courses)));
    return courses;
  }

  getCompletedCoursesProgress$(masterclass: IMasterclass): Observable<number> {
    return this.firebaseService.getELearningData().pipe(map((progress) => {
      const masterclassCourses = this.getMasterclassCourses(masterclass);
      let totalCoursesInMasterclass = masterclassCourses.length;
      if (!totalCoursesInMasterclass) {
        return 0;
      }
      let totalCoursesCompletedFromMasterclass = 0;
      progress?.completedCourses?.forEach((completedCourse) => totalCoursesCompletedFromMasterclass += (masterclassCourses.find((course: ICourse) => course.id === completedCourse) ? 1 : 0));
      return Math.round((totalCoursesCompletedFromMasterclass / totalCoursesInMasterclass) * 100);
    }));
  }

  getObtainedMasterclasses$(): Observable<string[]> {
    return this.firebaseService.getELearningData().pipe(map((data) => data?.obtainedMasterclasses ?? []));
  }

  getMasterclassVersion(versionId?: string): Observable<IMasterclassVersion> {
    return this.http.get<IMasterclassVersion>(`${this.rootURL}/masterclass-versions/${versionId}`);
  }

  updateMasterclassVersion(masterclassVersion: IMasterclassVersion): Observable<IMasterclassVersion> {
    return this.http.post<IMasterclassVersion>(`${this.rootURL}/masterclass-versions/${masterclassVersion.id}`, masterclassVersion);
  }

  createCheckoutSession(metadata: IELearningMetadata): Observable<string> {
    return this.http.post(`${this.rootURL}/stripe/create-checkout-session`, { metadata }, { responseType: 'text' });
  }
}
