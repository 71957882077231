<div class="body-our-interventions">
    <app-sub-title
      [title]="'Les services '"
      [titleBoldPart]="'proposés'"
      [subtitle]="'Pour un accompagnement personnalisé'"
    ></app-sub-title>
    <div class="interventions-card-container">
      <ng-template ngFor let-item [ngForOf]="items" let-i="index">
        <app-intervention-card
          [title]="item.title"
          [description]="item.description"
          [icon]="item.icon"
        ></app-intervention-card>
      </ng-template>
    </div>