import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isMobile = new BehaviorSubject<boolean>(false);

  constructor() {
    fromEvent(window, 'resize')
      .pipe(
        startWith(null),
        map(() => window.innerWidth <= 768)
      )
      .subscribe((isMobile) => {
        this.isMobile.next(isMobile);
      });
  }

  get isMobile$() {
    return this.isMobile.asObservable();
  }
}
