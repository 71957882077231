import { Component } from '@angular/core';
import { ourInterventions } from 'src/app/modules/home/json/our-interventions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-our-interventions',
  templateUrl: './our-interventions.component.html',
  styleUrls: ['./our-interventions.component.scss']
})
export class OurInterventionsComponent {
  subs = new SubSink();

  items = ourInterventions;

  constructor(
  ) {

  }
}
