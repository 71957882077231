import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { IUserSignup } from '@shared';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


const MIN_MOBILE_SIZE = 768;

enum ErrorType {
  EmailInvalid,
  FirstNameRequired,
  LastNameRequired,
  UsernameRequired,
  PasswordInvalid,
  PasswordsDontMatch,
  FirebaseError
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {

  constructor(private firebaseService: FirebaseService, private router: Router, public dialog: MatDialog, private location: Location, private gtmService: GoogleTagManagerService) {
    this.currentErrorMessage$.subscribe(message => {
      if (message && window.innerWidth < MIN_MOBILE_SIZE) {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
          });
        }, 100);
      }
    });
  }

  ngOnInit() {
    if ((this.location.getState() as any).redirectTo) {
      this.redirectTo = (this.location.getState() as any).redirectTo;
    }
  }

  private currentErrorMessageSubject = new BehaviorSubject<string>('');
  currentErrorMessage$ = this.currentErrorMessageSubject.asObservable();

  redirectTo: string;

  email = '';
  password = '';
  confirmPassword = '';
  firstName = '';
  lastName = '';
  username = '';

  ErrorMessages = {
    [ErrorType.EmailInvalid]: 'Adresse e-mail non valide.',
    [ErrorType.FirstNameRequired]: 'Prénom requis.',
    [ErrorType.LastNameRequired]: 'Nom requis.',
    [ErrorType.UsernameRequired]: 'Nom d\'utilisateur requis.',
    [ErrorType.PasswordInvalid]: this.firebaseService.getPasswordRequirementsMessage(),
    [ErrorType.PasswordsDontMatch]: 'Les mots de passe ne correspondent pas.',
    [ErrorType.FirebaseError]: null,
  };

  formTouched: boolean = false;

  private errorMessagesQueue: string[] = [];

  passwordsMatch: boolean = true;
  isEmailValid: boolean = true;
  isFirstNameValid: boolean = true;
  isLastNameValid: boolean = true;
  isUsernameValid: boolean = true;
  isPasswordValid: boolean = true;

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  getErrorTypeForField(fieldName: string): ErrorType {
    switch (fieldName) {
      case 'email':
        return ErrorType.EmailInvalid;
      case 'firstName':
        return ErrorType.FirstNameRequired;
      case 'lastName':
        return ErrorType.LastNameRequired;
      case 'username':
        return ErrorType.UsernameRequired;
      case 'password':
        return ErrorType.PasswordInvalid;
      case 'confirmPassword':
        return ErrorType.PasswordsDontMatch;
      default:
        return null;
    }
  }

  reorderErrorMessageQueueForField(fieldName: string): void {
    const errorType = this.getErrorTypeForField(fieldName);
    if (errorType != null) {
      const message = this.ErrorMessages[errorType];
      const index = this.errorMessagesQueue.indexOf(message);
      if (index > -1) {
        this.errorMessagesQueue.splice(index, 1);
        this.errorMessagesQueue.unshift(message);
      }
    }

    this.updateErrorMessageDisplay();
  }

  onBlur(fieldName: string): void {
    this.reorderErrorMessageQueueForField(fieldName);
  }

  handleFirebaseError(code: string): void {
    const firebaseErrorMessage = this.firebaseService.getErrorMessages(code);
    this.setCurrentErrorMessage(ErrorType.FirebaseError, firebaseErrorMessage);
  }

  updateErrorMessageDisplay(): void {
    if (this.errorMessagesQueue.length > 0) {
      this.currentErrorMessageSubject.next(this.errorMessagesQueue[0]);
    } else {
      this.currentErrorMessageSubject.next('');
    }
  }

  setCurrentErrorMessage(errorType: ErrorType, customMessage: string = ''): void {
    let message = customMessage;
    if (errorType !== ErrorType.FirebaseError) {
      message = this.ErrorMessages[errorType];
    }

    const index = this.errorMessagesQueue.indexOf(message);
    if (index > -1) {
      this.errorMessagesQueue.splice(index, 1);
    }
    this.errorMessagesQueue.unshift(message);

    this.updateErrorMessageDisplay();
  }

  clearSpecificErrorMessage(errorType: ErrorType): void {
    const message = this.ErrorMessages[errorType];
    const index = this.errorMessagesQueue.indexOf(message);
    if (index > -1) {
      this.errorMessagesQueue.splice(index, 1);
      this.updateErrorMessageDisplay();
    }
  }

  async signUp() {
    if (!this.isFormValid())
      return;

    this.validatePasswords();

    if (!this.passwordsMatch) {
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);

      const user: IUserSignup = {
        uid: userCredential.user.uid,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.username
      };

      await this.firebaseService.createUser(user).then(() => {
        const gtmTag = {
          event: "sign_up",
          email_adress: user.email,
          name: `${user.firstName} ${user.lastName.toUpperCase()}`
        };

        this.gtmService.pushTag(gtmTag);
      });
      if (this.redirectTo) {
        this.router.navigate([this.redirectTo]);
      } else {
        this.router.navigate(['home']);
      }

    } catch (err) {
      alert('Une erreur est survenue lors de la création du compte: ' + err.message);
    }
  }

  togglePasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  validateEmail(): void {
    this.markAsTouched();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.isEmailValid = emailPattern.test(this.email);
    if (!this.isEmailValid) {
      this.setCurrentErrorMessage(ErrorType.EmailInvalid);
    } else {
      this.clearSpecificErrorMessage(ErrorType.EmailInvalid);
    }
  }

  validateFirstName(): void {
    this.markAsTouched();
    this.isFirstNameValid = this.firstName.trim().length > 0;
    if (!this.isFirstNameValid) {
      this.setCurrentErrorMessage(ErrorType.FirstNameRequired);
    } else {
      this.clearSpecificErrorMessage(ErrorType.FirstNameRequired);
    }
  }

  validateLastName(): void {
    this.markAsTouched();
    this.isLastNameValid = this.lastName.trim().length > 0;
    if (!this.isLastNameValid) {
      this.setCurrentErrorMessage(ErrorType.LastNameRequired);
    } else {
      this.clearSpecificErrorMessage(ErrorType.LastNameRequired);
    }
  }

  validateUsername(): void {
    this.markAsTouched();
    this.isUsernameValid = this.username.trim().length > 0;
    if (!this.isUsernameValid) {
      this.setCurrentErrorMessage(ErrorType.UsernameRequired);
    } else {
      this.clearSpecificErrorMessage(ErrorType.UsernameRequired);
    }
  }

  validatePasswords(): void {
    this.markAsTouched();

    const passwordPattern = this.firebaseService.getPasswordRequirementsRegex();
    this.isPasswordValid = passwordPattern.test(this.password);
    if (!this.isPasswordValid) {
      this.setCurrentErrorMessage(ErrorType.PasswordInvalid);
      return;
    } else {
      this.clearSpecificErrorMessage(ErrorType.PasswordInvalid);

    }

    this.passwordsMatch = this.password === this.confirmPassword;
    if (!this.passwordsMatch) {
      this.setCurrentErrorMessage(ErrorType.PasswordsDontMatch);
    } else {
      this.clearSpecificErrorMessage(ErrorType.PasswordsDontMatch);
    }
  }

  async signInWithGoogle() {
    try {
      await this.firebaseService.signInWithGoogle();
      if (this.redirectTo) {
        this.router.navigate([this.redirectTo]);
      } else {
        this.router.navigate(['home']);
      }
    } catch (error) {
      this.handleFirebaseError(error.code);
    } finally {
      this.email = '';
      this.password = '';
    }
  }

  markAsTouched(): void {
    this.formTouched = true;
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  isFormValid(): boolean {
    return this.formTouched &&
      this.isEmailValid &&
      this.isFirstNameValid &&
      this.isLastNameValid &&
      this.isUsernameValid &&
      this.isPasswordValid &&
      this.passwordsMatch &&
      this.email !== '' &&
      this.firstName !== '' &&
      this.lastName !== '' &&
      this.username !== '' &&
      this.password !== '' &&
      this.confirmPassword !== ''
  }
}