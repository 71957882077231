import { UserRoleEnum } from "../types";
import { determineUserRole } from "./determine-user-role";

export const isEnoughRole = (claims: any, requiredRole: UserRoleEnum): boolean => {
    const userRole = determineUserRole(claims);
  
    const roleHierarchy = {
      [UserRoleEnum.NONE]: 0,
      [UserRoleEnum.SUBSCRIBER]: 1,
      [UserRoleEnum.COACH]: 2,
      [UserRoleEnum.TRAINER]: 3,
      [UserRoleEnum.ADMIN]: 4
    };
  
    return roleHierarchy[userRole] >= roleHierarchy[requiredRole];
  }