<div class="delete-account">
  <span class="title">Supprimer votre compte</span>
  <div class="texts">
    <span>Votre compte est sur le point d'être supprimé.</span>
    <span>Cette action est irréversible.</span>
    <br />
    <span *ngIf="!isProvider">Confirmez votre mot de passe pour continuer :</span>
  </div>
  <span class="light-input" *ngIf="!isProvider">
    <input type="password" [(ngModel)]="password" placeholder="Mot de passe" />
  </span>
  <span class="message" [ngStyle]="{ color: 'red' }">{{ message }}</span>
  <div class="buttons">
    <app-button theme="danger" size="sm" text="Supprimer mon compte" (onClick)="deleteAccount()" [disabled]="password === '' && !isProvider"></app-button>
  </div>
</div>
