<div class="edit-picture">
  <span class="title">Changer votre photo de profil</span>
  <div class="selected-picture-wrapper">
    <img
      class="selected-picture"
      [ngStyle]="{ opacity: loading ? '0.7' : '1' }"
      [src]="photoUrl || data.user.photoUrl ? (photoUrl ? photoUrl : data.user.photoUrl) : '../../../../../assets/icons/empty-profile.png'"
      referrerpolicy="no-referrer"
    />
    <app-loader size="sm" *ngIf="loading"></app-loader>
  </div>
  <span class="message" [ngStyle]="{ color: error ? 'red' : 'green', visibility: message ? 'visible' : 'hidden' }">{{ message ?? 'placeholder' }}</span>
  <input #uploadFileInput type="file" accept="*.png.jpg.jpeg" (change)="uploadPicture($event)" />
  <div class="buttons">
    <app-button size="sm" text="Choisir un fichier" [outlined]="true" (onClick)="uploadFileInput.click()"></app-button>
    <app-button size="sm" text="Valider" (onClick)="upload()" [disabled]="!photoUrl"></app-button>
  </div>
</div>
