import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { UserRoleEnum } from '@shared';

@Component({
  selector: 'app-promote-user-dialog',
  templateUrl: './promote-user-dialog.component.html',
  styleUrls: ['./promote-user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromoteUserDialogComponent {
  email = '';
  loading = false;

  UserRoleEnum = UserRoleEnum;
  selectedRole: UserRoleEnum = UserRoleEnum.SUBSCRIBER;

  private makeRoleMethods = {
    [UserRoleEnum.SUBSCRIBER]: (email: string) => this.firebaseService.makeSubscriber(email),
    [UserRoleEnum.COACH]: (email: string) => this.firebaseService.makeCoach(email),
    [UserRoleEnum.TRAINER]: (email: string) => this.firebaseService.makeTrainer(email),
    [UserRoleEnum.ADMIN]: (email: string) => this.firebaseService.makeAdmin(email)
  };

  constructor(public dialogRef: MatDialogRef<PromoteUserDialogComponent>, private firebaseService: FirebaseService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  isUserTheBoss(): boolean {
    return (
      this.data.currentUser.email === 'mickael@mymusicads.com' ||
      this.data.currentUser.email === 'dlamarque.pro@gmail.com' ||
      this.data.currentUser.email === 'er.lajouannique@gmail.com' ||
      this.data.currentUser.email === 'kelly@mymusicads.fr'
    );
  }

  async addRole(email: string): Promise<void> {
    if (email && this.makeRoleMethods[this.selectedRole]) {
      this.loading = true;

      try {
        const result = await this.makeRoleMethods[this.selectedRole](email);
        if (result.errorInfo) {
          throw new Error("ÉCHEC DE L'AJOUT : " + JSON.stringify(result.errorInfo.message));
        }
        alert(result.message);
      } catch (err) {
        alert(err.message || 'Une erreur est survenue.');
      } finally {
        this.loading = false;
        this.dialogRef.close({ email, selectedRole: this.selectedRole });
      }
    } else {
      alert('Aucun e-mail fourni ou rôle inconnu.');
    }
  }
}
