import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IDocInfo } from '@shared';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnChanges {
  @Input() videoInfo: IDocInfo;
  @Input() isSelectedCourseLast: boolean;
  @Input() isBackOffice: boolean = false;
  @Output() goToNextCourse = new EventEmitter();

  videoUrl = '';

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoInfo.url) as string;

  }
}
