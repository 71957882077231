import { Component } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  subs = new SubSink();
}
