<a [ngClass]="disabled ? 'disabled' : ''">
  <div class="card">
    <div>
      <div class="img-container">
        <div class="banner" *ngIf="bannerText !== ''">{{ bannerText }}</div>
        <img [src]="getImageUrl()" alt="Logo MyMusicAds" />
      </div>
      <div class="texts">
        <span class="name">{{ name }}</span>
        <span class="description">{{ description }}</span>
      </div>
    </div>
    <ng-container *ngIf="disabled">
      <div class="disabled-canvas"></div>
      <img class="lock" src="../../../../assets/icons/lock.svg" alt="Verrouillé" />
    </ng-container>
  </div>
</a>
