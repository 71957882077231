import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { IModule } from '@shared';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { v4 as uuid } from 'uuid';
import { ELearningElementDialogOperation } from '../../models/ELearningElement';
import { eLearningNameToId } from '../../utils/e-learning-name-to-id';

@Component({
  selector: 'app-new-module-form-dialog',
  templateUrl: './new-module-form-dialog.component.html',
  styleUrls: ['./new-module-form-dialog.component.scss']
})
export class NewModuleFormDialogComponent implements OnInit {
  newModuleForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    duration: ['', Validators.required],
    isAvailableInDemo: [false, Validators.required],
    picture: [null, Validators.required]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { elementInfo: IModule }, private fb: FormBuilder, public dialogRef: MatDialogRef<NewModuleFormDialogComponent>, private backOfficeELearningService: BackOfficeELearningService, private firebaseService: FirebaseService) { }

  loading = false;

  ngOnInit(): void {
    this.mapInfoToForm();
  }

  mapInfoToForm() {
    const elementInfo: IModule = (this.data.elementInfo as IModule);
    if (elementInfo) {
      this.newModuleForm.patchValue({
        name: elementInfo.name,
        description: elementInfo.description,
        duration: elementInfo.duration,
        isAvailableInDemo: elementInfo.isAvailableInDemo,
        picture: elementInfo.iconUrl ?? null
      });
    }
  }

  onPictureChange(event: Event | null) {
    const target = event?.target as HTMLInputElement;

    if (!target) {
      this.newModuleForm.patchValue({
        picture: null
      });
    } else {
      this.newModuleForm.patchValue({
        picture: target.files[0]
      });
    }
  }

  deleteModule(): void {
    this.dialogRef.close({ element: { info: this.data.elementInfo, type: 'module' }, operation: 'delete' });
  }

  async saveModuleWithOperation(operation: ELearningElementDialogOperation): Promise<void> {
    if (this.newModuleForm.valid) {
      this.loading = true;
      const formValue = this.newModuleForm.value;
      await this.firebaseService.downloadFileInFirebase(formValue.picture, '/masterclasses/modules/', ['image/png', 'image/webp']).then((iconUrl) => {
        const finalIconUrl = iconUrl?.length ? iconUrl : formValue.picture ?? null;
        const module: IModule = {
          chapters: [],
          id: eLearningNameToId(formValue.name),
          ...this.data.elementInfo,
          name: formValue.name,
          description: formValue.description,
          isAvailableInDemo: formValue.isAvailableInDemo,
          iconUrl: finalIconUrl,
          duration: parseInt(formValue.duration)
        }
        this.dialogRef.close({ element: { info: module, type: 'module' }, operation });
      }).catch((err) => {
        this.loading = false;
        alert(err);
      });
    }
  }
}
