import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ITimeInterval } from '@shared';
import { CalendarDialogComponent } from './calendar-dialog/calendar-dialog.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() isCalendarDialog = false;
  @Input() initialRange: ITimeInterval;

  @Output() intervalChange = new EventEmitter<ITimeInterval>();

  isOpen = false;

  selectedDateRange: DateRange<Date> = new DateRange(null, null);
  @ViewChild('datePickerContainer') datePickerContainer: ElementRef | undefined;


  constructor(private renderer: Renderer2, private dialog: MatDialog) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.datePickerContainer?.nativeElement.contains(e.target)) {
        this.selectedDateRange?.start && !this.selectedDateRange?.end ? this.onSelectedDatesChange(this.selectedDateRange?.start) : (this.isOpen = false);
      }
    });
  }

  ngOnInit(): void {
    if (this.initialRange) {
      this.selectedDateRange = { ...this.initialRange } as DateRange<Date>;
    }
  }

  areDatesEqual(date1: Date | null, date2: Date | null): boolean {
    if (date1 && date2) {
      return date1.getTime() === date2.getTime();
    }
    return date1 === date2;
  }

  onSelectedDatesChange(date: Date) {
    if (this.selectedDateRange && this.selectedDateRange?.start && date >= this.selectedDateRange?.start && !this.selectedDateRange?.end) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
      this.isOpen = false;
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
    this.intervalChange.emit(this.selectedDateRange);
  }

  resetDates(): void {
    this.selectedDateRange = new DateRange(null, null);
    this.intervalChange.emit(this.selectedDateRange);
  }

  displayCalendar(): void {
    if (this.isCalendarDialog) {
      this.openCalendarDialog();
    } else {
      this.isOpen != this.isOpen;
    }
  }

  openCalendarDialog(): void {
    this.dialog.open(CalendarDialogComponent, {
      width: '400px',
      panelClass: 'calendar-dialog'
    }).afterClosed().subscribe((selectedDateRange) => {
      this.selectedDateRange = selectedDateRange;
      this.intervalChange.emit(this.selectedDateRange);
    });
  }
}
