<div class="coach-card slide" (click)="navigateToCoach(coach.id)">
  <div class="img-content">
    <img class="image-placeholder"
      [src]="coach?.photoUrl ? coach?.photoUrl : '../../../../assets/images/coaching/default-coach-image.png'"
      [ngClass]="!coach?.photoUrl ? 'loading-picture' : ''"
      />
  </div>
  <div class="content">
    <h3 class="name">{{ coach?.firstName }} {{ coach?.lastName }}</h3>
    <div class="categories">
      <span class="category" *ngFor="let category of coach?.coachInfo?.coachingCategories">{{ category }}</span>
    </div>
    <div class="description-content">
      <span class="description">{{ coach?.coachInfo?.catchphrasecard || '' }}</span>
    </div>
  </div>
</div>