import { UserRoleEnum } from "../types";

export const determineUserRole = (claims: any): UserRoleEnum => {
  if (claims?.admin) {
    return UserRoleEnum.ADMIN;
  } else if (claims?.role === UserRoleEnum.TRAINER) {
    return UserRoleEnum.TRAINER;
  } else if (claims?.role === UserRoleEnum.SUBSCRIBER) {
    return UserRoleEnum.SUBSCRIBER;
  } else if (claims?.role === UserRoleEnum.COACH) {
    return UserRoleEnum.COACH;
  } else {
    return UserRoleEnum.NONE;
  }
}