import { Component, Input } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-sub-title',
  templateUrl: './sub-title.component.html',
  styleUrls: ['./sub-title.component.scss']
})
export class SubTitleComponent {
  subs = new SubSink();

  innerWidth = window.innerWidth;

  @Input() title: string;
  @Input() titleBoldPart: string;
  @Input() subtitle: string = '';

  constructor(
  ) {

  }
}
