import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { determineModuleImagePath } from '../../utils/determine-image-path';
import { IMasterclass, IModule } from '@shared';

@Component({
  selector: 'app-module-overview',
  templateUrl: './module-overview.component.html',
  styleUrls: ['./module-overview.component.scss']
})
export class ModuleOverviewComponent implements OnInit {

  @Input() module: IModule;
  @Input() masterclass: IMasterclass;

  @Input() loadingProgress = true;

  @Output() goToModule: EventEmitter<IModule> = new EventEmitter();
  @Output() goToBuyMasterclass: EventEmitter<IMasterclass> = new EventEmitter();
  @Output() closeModuleOverview: EventEmitter<void> = new EventEmitter();

  innerWidth = window.innerWidth;

  constructor() { }

  ngOnInit(): void {
  }

  goToModuleClicked(): void {
    this.goToModule.emit(this.module);
  }

  goToBuyMasterclassClicked(): void {
    this.goToBuyMasterclass.emit(this.masterclass);
  }

  closeModuleOverviewClicked() {
    this.closeModuleOverview.emit();
  }

}
