import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ELearningComponent } from './e-learning.component';
import { SharedModule } from '../shared/shared.module';
import { McqComponent } from './course/mcq/mcq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CourseComponent } from './course/course.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MasterclassesComponent } from './masterclasses/masterclasses.component';
import { MasterclassOverviewComponent } from './masterclasses/masterclass-overview/masterclass-overview.component';
import { MasterclassThumbnailComponent } from './masterclasses/masterclass-thumbnail/masterclass-thumbnail.component';
import { ModulesComponent } from './modules/modules.component';
import { ModuleOverviewComponent } from './modules/module-overview/module-overview.component';
import { ModuleThumbnailComponent } from './modules/module-thumbnail/module-thumbnail.component';
import { VideoComponent } from './course/video/video.component';
import { PdfComponent } from './course/pdf/pdf.component';

@NgModule({
  declarations: [
    ELearningComponent, McqComponent, VideoComponent, ModulesComponent, CourseComponent, PdfComponent, MasterclassesComponent, MasterclassOverviewComponent, MasterclassThumbnailComponent, ModuleOverviewComponent, ModuleThumbnailComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule
  ],
  exports: [
    ELearningComponent, ModulesComponent, CourseComponent, MasterclassesComponent, VideoComponent, PdfComponent, McqComponent
  ]
})
export class ELearningModule { }
