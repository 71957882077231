import { Component, ViewEncapsulation } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-coaching',
  templateUrl: './coaching.component.html',
  styleUrls: ['./coaching.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoachingComponent {
  subs = new SubSink();

  innerWidth = window.innerWidth;

  constructor(
  ) {

  }
}
