import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { IMasterclass } from '@shared';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { v4 as uuid } from 'uuid';
import { ELearningElementDialogOperation } from '../../models/ELearningElement';
import { eLearningNameToId } from '../../utils/e-learning-name-to-id';

@Component({
  selector: 'app-new-masterclass-form-dialog',
  templateUrl: './new-masterclass-form-dialog.component.html',
  styleUrls: ['./new-masterclass-form-dialog.component.scss']
})
export class NewMasterclassFormDialogComponent implements OnInit {
  newMasterclassForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    speakerName: [''],
    speakerPicture: [null],
    category: ['', Validators.required],
    isAvailable: [false, Validators.required],
    isBuyable: [false, Validators.required]
  });

  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { elementInfo: IMasterclass }, private fb: FormBuilder, public dialogRef: MatDialogRef<NewMasterclassFormDialogComponent>, private backOfficeELearningService: BackOfficeELearningService, private afStorage: AngularFireStorage, private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    this.mapInfoToForm();
  }

  mapInfoToForm() {
    const elementInfo: IMasterclass = (this.data.elementInfo as IMasterclass);
    if (elementInfo) {
      this.newMasterclassForm.patchValue({
        name: elementInfo.name,
        description: elementInfo.description,
        speakerName: elementInfo.speaker?.name,
        speakerPicture: elementInfo.speaker?.photoUrl ?? null,
        category: elementInfo.category,
        isAvailable: !elementInfo.isComingSoon,
        isBuyable: elementInfo.isBuyable,
      });
    }
  }

  onSpeakerPictureChange(event: Event | null) {
    const target = event?.target as HTMLInputElement;

    if (!target) {
      this.newMasterclassForm.patchValue({
        speakerPicture: null
      });
    } else {
      this.newMasterclassForm.patchValue({
        speakerPicture: target.files[0]
      });
    }
  }

  deleteMasterclass(): void {
    this.dialogRef.close({ element: { info: this.data.elementInfo, type: 'masterclass' }, operation: 'delete' });
  }

  async saveMasterclassWithOperation(operation: ELearningElementDialogOperation): Promise<void> {
    if (this.newMasterclassForm.valid) {
      this.loading = true;
      const formValue = this.newMasterclassForm.value;

      await this.firebaseService.downloadFileInFirebase(formValue.speakerPicture, '/masterclasses/speakers/', ['image/png', 'image/webp']).then((photoUrl) => {
        const finalPhotoUrl = photoUrl?.length ? photoUrl : formValue.speakerPicture ?? null;
        const masterclass: IMasterclass = {
          id: eLearningNameToId(formValue.name),
          modules: [],
          ...this.data.elementInfo,
          name: formValue.name,
          description: formValue.description,
          speaker: {
            id: eLearningNameToId(formValue.speakerName ?? ''),
            name: formValue.speakerName,
            photoUrl: finalPhotoUrl
          },
          category: formValue.category,
          isComingSoon: !formValue.isAvailable,
          isBuyable: formValue.isBuyable,
        }
        this.dialogRef.close({ element: { info: masterclass, type: 'masterclass' }, operation });
      }).catch((err) => {
        this.loading = false;
        alert(err);
      });
    }
  }
}
