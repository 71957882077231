<a *ngIf="href || download; else onClickButton" [href]="href" [target]="target" [attr.download]="download ? download : null">
  <button [class]="getClass(theme, size, outlined)" [disabled]="disabled" [ngStyle]="{'color': color}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</a>

<ng-template #onClickButton>
  <button [type]="type" [class]="getClass(theme, size, outlined)" [disabled]="disabled" (click)="onClick.emit($event)" [ngStyle]="{'color': color}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-template>

<ng-template #content>
  <div class="content">
    <img class="icon" *ngIf="icon" [src]="'../../../assets/icons/' + icon + '.svg'" />
    {{ text }}
  </div>
</ng-template>
