import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { IChapter } from '@shared';
import { v4 as uuid } from 'uuid';
import { ELearningElementDialogOperation } from '../../models/ELearningElement';
import { eLearningNameToId } from '../../utils/e-learning-name-to-id';

@Component({
  selector: 'app-new-chapter-form-dialog',
  templateUrl: './new-chapter-form-dialog.component.html',
  styleUrls: ['./new-chapter-form-dialog.component.scss']
})
export class NewChapterFormDialogComponent implements OnInit {
  newChapterForm = this.fb.group({
    name: ['', Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { elementInfo: IChapter }, private fb: FormBuilder, public dialogRef: MatDialogRef<NewChapterFormDialogComponent>, private backOfficeELearningService: BackOfficeELearningService) { }

  ngOnInit(): void {
    this.mapInfoToForm();
  }

  mapInfoToForm() {
    const elementInfo: IChapter = (this.data.elementInfo as IChapter);
    if (elementInfo) {
      this.newChapterForm.patchValue({ name: elementInfo.name });
    }
  }

  deleteChapter(): void {
    this.dialogRef.close({ element: { info: this.data.elementInfo, type: 'chapter' }, operation: 'delete' });
  }

  saveChapterWithOperation(operation: ELearningElementDialogOperation): void {
    if (this.newChapterForm.valid) {
      const formValue = this.newChapterForm.value;
      const chapter: IChapter = {
        id: eLearningNameToId(formValue.name),
        courses: [],
        ...this.data.elementInfo,
        name: formValue.name
      }
      this.dialogRef.close({ element: { info: chapter, type: 'chapter' }, operation });
    }
  }

  openChapter(): void {
    this.dialogRef.close(true);
  }
}
