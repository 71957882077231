import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent
    }
  ]
})
export class InputComponent implements ControlValueAccessor {

  @Input() placeholder: string;
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() icon: string;
  @Input() type = 'text';
  @Input() onEnterKey: () => void;
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  public change() {
    this.onChange(this.value);
    this.onTouched();
  }

  onChange = (value: string) => { };

  onTouched = () => { };

  touched = false;

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
