import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function requiredTrue(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    return value !== true ? { wrong: true } : null;
  }
}

export function requiredFalse(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    return value !== false ? { wrong: true } : null;
  }
}