import { Component, Input, OnInit } from '@angular/core';
import { determineModuleImagePath } from '../../utils/determine-image-path';
import { IModule } from '@shared';

@Component({
  selector: 'app-module-thumbnail',
  templateUrl: './module-thumbnail.component.html',
  styleUrls: ['./module-thumbnail.component.scss']
})
export class ModuleThumbnailComponent implements OnInit {

  @Input() module: IModule;
  @Input() disabled = false;
  @Input() isSelected = false;

  constructor() { }

  ngOnInit(): void {
  }
}
