import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../shared/shared.module';
import { CreateCampaignComponent } from './create-campaign.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectableFormFieldImageComponent } from './campaign-form/selectable-form-field-image/selectable-form-field-image.component';
import { StepHeaderComponent } from './campaign-form/step-header/step-header.component';
import { MatIconModule } from '@angular/material/icon';
import { SuccessComponent } from './success/success.component';
import { ErrorComponent } from './error/error.component';
import { AppRoutingModule } from 'src/app/app-routing.module';

@NgModule({
  declarations: [CreateCampaignComponent, CampaignFormComponent, SelectableFormFieldImageComponent, StepHeaderComponent, SuccessComponent, ErrorComponent],
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatStepperModule, SharedModule, MatIconModule, ReactiveFormsModule, AppRoutingModule],
  exports: [
    CreateCampaignComponent,
    CampaignFormComponent,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatIconModule,
    ReactiveFormsModule,
    SelectableFormFieldImageComponent,
    StepHeaderComponent
  ]
})
export class CreateCampaignModule {}
