import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { CoachingService } from '../coaching.service';

import { Observable } from 'rxjs';
import { IUser } from '@shared';

@Component({
  selector: 'app-coachs',
  templateUrl: './coachs.component.html',
  styleUrls: ['./coachs.component.scss']
})
export class CoachsComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  innerWidth = window.innerWidth;

  coachs$: Observable<IUser[]> = new Observable();
  groupedCoachs: { [key: string]: IUser[] } | null = null;
  groupedCoachsTemp: { [key: string]: IUser[] } | null = null;
  allCoaches: Array<IUser> = [];
  redirectUrl = '/coaching/coachs';

  constructor(private router: Router, private coachingService: CoachingService) {}

  ngOnInit() {
    this.coachs$ = this.coachingService.getCoachs();
    this.subs.sink = this.coachs$.subscribe((coachs) => {
      let tempGroupedCoachs = {};
      this.allCoaches = coachs.filter((coach) => coach.coachInfo?.categoryTitle !== '' && coach.coachInfo?.categoryTitle !== null && coach.coachInfo?.categoryTitle !== undefined);

      coachs.forEach((coach) => {
        let categoryTitle = coach?.coachInfo?.categoryTitle;
        if (categoryTitle) {
          if (categoryTitle === 'Beatmakers' || categoryTitle === 'Ingénieurs sons') {
            categoryTitle = 'Beatmakers et ingénieurs sons';
          }
          if (categoryTitle === 'Directeurs artistiques' || categoryTitle === 'Chefs de projet') {
            categoryTitle = 'Directeurs artistiques et chefs de projets';
          }
          if (categoryTitle === 'Avocats' || categoryTitle === 'Experts-comptables') {
            categoryTitle = 'Avocats et experts-comptables';
          }
          if (!tempGroupedCoachs[categoryTitle]) {
            tempGroupedCoachs[categoryTitle] = [];
          }
          tempGroupedCoachs[categoryTitle].push(coach);
        }
      });
      this.groupedCoachs = tempGroupedCoachs;

      //TO REMOVE ONCE MORE CATEGORIES
      if (tempGroupedCoachs['Attachés de presse']) {
        const attachedPressCoaches = tempGroupedCoachs['Attachés de presse'];
        delete tempGroupedCoachs['Attachés de presse'];
        this.groupedCoachsTemp = {};
        this.groupedCoachsTemp['Attachés de presse'] = attachedPressCoaches;
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
