import { Component } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  subs = new SubSink();

  innerWidth = window.innerWidth;

  constructor(
  ) {

  }
}
