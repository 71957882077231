<form [formGroup]="newMasterclassForm" class="form">
  <div class="form-element">
    <label for="name">Nom de la formation <span class="mandatory-symbol">*</span></label>
    <input id="name" type="text" formControlName="name" />
  </div>
  <div class="form-element">
    <label for="description">Description <span class="mandatory-symbol">*</span></label>
    <textarea id="description" type="text" formControlName="description"></textarea>
  </div>
  <div class="form-element">
    <label for="speakerName">Nom de l'intervenant</label>
    <input id="speakerName" type="text" formControlName="speakerName" />
  </div>
  <div class="form-element">
    <label for="speakerPicture">Photo de l'intervenant (format png, 2:3)</label>
    <span class="speaker-picture-wrapper">
      <input #uploadFileInput id="speakerPicture" type="file" accept="*.png" (change)="onSpeakerPictureChange($event)" />
      <app-button size="sm" text="Télécharger une photo" [outlined]="true" (onClick)="uploadFileInput.click()"></app-button>
      <span class="speaker-picture-content-wrapper">
        <ng-container *ngIf="newMasterclassForm.get('speakerPicture').value !== null; else noFileSelected">
          <img src="../../../../../assets/icons/check.svg" />
          <img class="delete-picture" (click)="onSpeakerPictureChange(null)" src="../../../../../assets/icons/delete.svg" />
        </ng-container>
        <ng-template #noFileSelected>
          <span>Pas de fichier selectionné.</span>
        </ng-template>
      </span>
    </span>
  </div>
  <div class="form-element">
    <label for="category">Catégorie <span class="mandatory-symbol">*</span></label>
    <input id="category" type="text" formControlName="category" />
  </div>
  <div class="form-element">
    <label for="isAvailable">Disponible ? <span class="mandatory-symbol">*</span></label>
    <div class="checkbox-wrapper">
      <input id="isAvailable" type="checkbox" formControlName="isAvailable" />
    </div>
  </div>
  <div class="form-element">
    <label for="isBuyable">Achetable ? <span class="mandatory-symbol">*</span></label>
    <div class="checkbox-wrapper">
      <input id="isBuyable" type="checkbox" formControlName="isBuyable" />
    </div>
  </div>
</form>
<div class="element-form-buttons">
  <app-button [disabled]="!this.data.elementInfo" theme="danger" [outlined]="true" text="Supprimer" (click)="deleteMasterclass()"></app-button>
  <app-button [disabled]="!newMasterclassForm.valid" text="Sauvegarder" (click)="saveMasterclassWithOperation('save')"></app-button>
  <app-button [disabled]="!newMasterclassForm.valid" text="Sauvegarder et ouvrir" (click)="saveMasterclassWithOperation('open')"></app-button>
</div>
<div class="loading-layer" *ngIf="loading">
  <app-loader></app-loader>
</div>
