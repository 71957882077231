import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { determineMasterclassImagePath } from '../../utils/determine-image-path';
import { IMasterclass } from '@shared';

@Component({
  selector: 'app-masterclass-overview',
  templateUrl: './masterclass-overview.component.html',
  styleUrls: ['./masterclass-overview.component.scss']
})
export class MasterclassOverviewComponent implements OnInit {

  @Input() masterclass: IMasterclass;
  @Input() loadingProgress = true;

  @Output() goToMasterclass: EventEmitter<IMasterclass> = new EventEmitter();
  @Output() presentMasterclass: EventEmitter<IMasterclass> = new EventEmitter();
  @Output() buyMasterclass: EventEmitter<IMasterclass> = new EventEmitter();
  @Output() closeMasterclassOverview: EventEmitter<void> = new EventEmitter();

  innerWidth = window.innerWidth;

  constructor() { }

  ngOnInit(): void { }

  goToMasterclassClicked(): void {
    this.goToMasterclass.emit(this.masterclass);
  }

  presentationClicked(): void {
    this.presentMasterclass.emit(this.masterclass);
  }

  buyMasterclassClicked(): void {
    this.buyMasterclass.emit(this.masterclass);
  }

  closeMasterclassOverviewClicked() {
    this.closeMasterclassOverview.emit();
  }

}
