<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <app-header-coaching [mobile]="true" [sidenav]="sidenav" [redirectUrl]="redirectUrl"></app-header-coaching>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="body">
      <app-header-coaching [mobile]="false" [sidenav]="sidenav" [redirectUrl]="redirectUrl"></app-header-coaching>
      <div class="photo-container">
        <div class="responsive-image"></div>
        <div class="multicolor"></div>
        <div class="multicolor blur"></div>
        <app-title></app-title>
      </div>
      <div class="page">
        <ng-container *ngIf="groupedCoachs || allCoaches.length > 0; else loading">
          <app-carousel [templateRef1]="template1" [templateRef2]="template2" [cards]="allCoaches" [categoryTitle]="'Tous les coachs'" [uniqueSense]="false" [cardWidth]="216">
            <ng-template #template1 let-card>
              <app-coach-card [coach]="card"></app-coach-card>
            </ng-template>
            <ng-template #template2 let-card>
              <app-coach-card [coach]="card"></app-coach-card>
            </ng-template>
          </app-carousel>
          <ng-container *ngFor="let category of groupedCoachs | keyvalue">
            <ng-container *ngIf="category.key !== 'undefined'">
              <app-carousel [templateRef1]="template1" [templateRef2]="template2" [cards]="groupedCoachs[category.key]" [categoryTitle]="category.key" [uniqueSense]="false" [cardWidth]="216">
                <ng-template #template1 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
                <ng-template #template2 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
              </app-carousel>
            </ng-container>
          </ng-container>
          <!-- to remove  -->
          <ng-container *ngFor="let category of groupedCoachsTemp | keyvalue">
            <ng-container *ngIf="category.key !== 'undefined'">
              <app-carousel [templateRef1]="template1" [templateRef2]="template2" [cards]="groupedCoachsTemp[category.key]" [categoryTitle]="category.key" [uniqueSense]="false" [cardWidth]="216">
                <ng-template #template1 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
                <ng-template #template2 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
              </app-carousel>
            </ng-container>
          </ng-container>
          <!-- .. -->
        </ng-container>
        <ng-template #loading>
          <app-carousel [templateRef1]="template1" [templateRef2]="template2" [cards]="null" [categoryTitle]="'Chargement...'" [uniqueSense]="false" [cardWidth]="216">
            <ng-template #template1 let-card>
              <app-coach-card [coach]="null"></app-coach-card>
            </ng-template>
            <ng-template #template2 let-card>
              <app-coach-card [coach]="null"></app-coach-card>
            </ng-template>
          </app-carousel>
        </ng-template>
        <app-our-interventions></app-our-interventions>
        <app-how-it-works></app-how-it-works>
        <app-faq></app-faq>
        <app-contact-us></app-contact-us>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
