<div class="masterclasses">
  <span class="title">Formations</span>
  <div class="content" *ngIf="masterclassVersion$ | async; else loading">
    <div class="details">
      <app-masterclass-overview
        *ngIf="innerWidth > 768 || isMasterclassOverviewOpen"
        [masterclass]="selectedMasterclass"
        [loadingProgress]="loadingProgress"
        (goToMasterclass)="onMasterclassClick($event)"
        (presentMasterclass)="presentationClicked($event)"
        (buyMasterclass)="buyMasterclass($event)"
        (closeMasterclassOverview)="closeMasterclassOverview()"
      ></app-masterclass-overview>
    </div>
    <div *ngIf="innerWidth > 768" class="splitting-bar"></div>
    <div class="thumbnails">
      <ng-container *ngFor="let masterclass of masterclasses">
        <app-masterclass-thumbnail [masterclass]="masterclass" [isSelected]="masterclass.id === selectedMasterclass.id" (click)="onThumbnailClick(masterclass)"></app-masterclass-thumbnail>
      </ng-container>
    </div>
    <app-button class="validate-version-button" *ngIf="versionId" text="Valider la version" (click)="validateVersionClicked()"></app-button>
  </div>
</div>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
