<div class="how-it-works-card" [@fadeInOut]>
  <div class="texts">
    <span class="index">Étape {{index}}</span>
    <span class="title">{{index}}. {{title}}</span>
    <span class="description">{{description}}</span>
    <div class="progression">
      <div class="steps-arrows">
        <img *ngIf="index > 1; else disabledDecrement" src="../../../../../../assets/icons/arrow-left-orange.svg"
          (click)="decrementStep()" />
        <ng-template #disabledDecrement>
          <img src="../../../../../../assets/icons/arrow-left-orange-disabled.svg" />
        </ng-template>
        <img *ngIf="index < 3; else disabledIncrement" src="../../../../../../assets/icons/arrow-right-orange.svg"
          (click)="incrementStep()" />
        <ng-template #disabledIncrement>
          <img src="../../../../../../assets/icons/arrow-right-orange-disabled.svg" />
        </ng-template>
      </div>
      <div class="progression-wrapper">
        <div class="current-progression" [ngStyle]="{'width': 33.33 * index + '%'}">
        </div>
      </div>
    </div>
  </div>
  <div class="img-container">
    <img class="img" [src]="'../../../../../../assets/images/coaching-how-it-works/step-' + index + '.png'">
  </div>
</div>