import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, of, switchMap, take } from 'rxjs';
import { determineUserRole, IELearningData, isEnoughRole, IUser, IUserSignup, UserRoleEnum } from '@shared';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import * as auth from 'firebase/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { IConnection, IELearningProgress } from '../modules/e-learning/models/Progress';
import { v4 as uuid } from 'uuid';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { HttpClient } from '@angular/common/http';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export enum AuthProvider {
  Password = 'password',
  Google = 'google.com'
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  userData$: BehaviorSubject<firebase.User>;

  rootURL = '/api';

  constructor(
    private angularFireAuth: AngularFireAuth,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private angularFireStorage: AngularFireStorage,
    public router: Router,
    private http: HttpClient,
    private gtmService: GoogleTagManagerService
  ) {
    this.userData$ = new BehaviorSubject(undefined);
    angularFireAuth.authState.subscribe((authUser) => this.userData$.next(authUser));
  }

  private async authLogin(provider: any): Promise<any> {
    return this.angularFireAuth.signInWithPopup(provider).then((res) => res);
  }

  async createUserBackOffice(email: string): Promise<string> {
    const createUser$ = this.functions.httpsCallable('createUser');
    const addSubscriberRole$ = this.functions.httpsCallable('addSubscriberRole');
    return firstValueFrom(createUser$({ email }))
      .then(async (result) => {
        if (!result.errorInfo) {
          return this.db
            .collection('users')
            .doc(result)
            .set({
              id: result,
              firstName: '',
              lastName: '',
              facebookUrl: '',
              instaUrl: '',
              youtubeEmail: '',
              defaultCountries: '',
              email
            })
            .then(async () => {
              await firstValueFrom(addSubscriberRole$({ email }));
              return result;
            })
            .catch((err) => {
              throw new Error(err);
            });
        } else {
          throw new Error(result.errorInfo.message);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async createUser(user: IUserSignup): Promise<string> {
    try {
      await this.db
        .collection('users')
        .doc(user.uid)
        .set({
          id: user.uid,
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          photoUrl: user?.photoUrl || '',
          displayName: user?.displayName || '',
          facebookUrl: user?.campaignInfo?.facebookUrl || '',
          instaUrl: user?.campaignInfo?.instaUrl || '',
          youtubeEmail: user?.campaignInfo?.youtubeEmail || '',
          defaultCountries: user?.campaignInfo?.defaultCountries || '',
          email: user.email
        });

      return user.uid;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  getErrorMessages(code: string) {
    switch (code) {
      case 'auth/account-exists-with-different-credential':
        return 'Ce compte utilise déjà une autre méthode de connexion.';
      default:
        return 'Une erreur est survenue ';
    }
  }

  async isEnoughRole(role: UserRoleEnum): Promise<boolean> {
    const token = await this.angularFireAuth.idTokenResult.pipe(take(1)).toPromise();
    if (!token) {
      return false;
    }
    return isEnoughRole(token.claims, role);
  }

  async makeSubscriber(email: string): Promise<any> {
    // any -> { message: string } | { errorInfo: { message: string }}
    const addSubscriberRole$ = this.functions.httpsCallable('addSubscriberRole');
    return await firstValueFrom(addSubscriberRole$({ email }));
  }

  async makeAdmin(email: string): Promise<any> {
    // any -> { message: string } | { errorInfo: { message: string }}
    const addAdminRole$ = this.functions.httpsCallable('addAdminRole');
    return await firstValueFrom(addAdminRole$({ email }));
  }

  //to modify with coach
  async makeCoach(email: string): Promise<any> {
    // any -> { message: string } | { errorInfo: { message: string }}
    const addCoachRole$ = this.functions.httpsCallable('addCoachRole');
    return await firstValueFrom(addCoachRole$({ email }));
  }

  async makeTrainer(email: string): Promise<any> {
    // any -> { message: string } | { errorInfo: { message: string }}
    const addTrainerRole$ = this.functions.httpsCallable('addTrainerRole');
    return await firstValueFrom(addTrainerRole$({ email }));
  }

  async signIn(email: string, password: string): Promise<void> {
    await this.angularFireAuth.setPersistence('local');
    await this.angularFireAuth.signInWithEmailAndPassword(email, password).catch((err: any) => {
      if (err.code === 'auth/invalid-email') {
        throw `Une erreur est survenue: l'adresse e-mail est invalide`;
      } else if (err.code === 'auth/wrong-password') {
        throw `Une erreur est survenue: le mot de passe est invalide`;
      } else {
        throw `Une erreur est survenue: ${err}`;
      }
    }).catch(err => {
      throw err;
    })
  }

  async signInWithGoogle(): Promise<void> {
    const result = await this.authLogin(new auth.GoogleAuthProvider()).catch((err) => {
      throw err;
    });

    if (result.additionalUserInfo.isNewUser) {
      const user: IUserSignup = {
        uid: result.user.uid,
        email: result.user.email,
        photoUrl: result.user.photoURL
      };

      const gtmTagSignup = {
        event: "sign_up",
        email_adress: result.user.email,
        name: `${result.additionalUserInfo.profile.given_name} ${result.additionalUserInfo.profile.family_name.toUpperCase()}`
      };

      this.gtmService.pushTag(gtmTagSignup);


      this.createUser(user);
    }
    return result;
  }

  async signOut(): Promise<void> {
    await this.angularFireAuth.signOut();
  }

  getCurrentUserProperties(): Observable<IUser | null> {
    return combineLatest([this.angularFireAuth.idTokenResult, this.userData$]).pipe(
      switchMap(([token, user]) => {
        if (!user) {
          return of(null);
        }
        return this.db
          .collection('users')
          .doc(user.uid)
          .get()
          .pipe(
            map((firestoreUser) => {
              const firestoreUserData = firestoreUser.data() as any;
              return {
                id: user.uid,
                photoUrl: firestoreUserData?.photoUrl || user.photoURL,
                displayName: firestoreUserData?.displayName,
                email: user.email,
                firstName: firestoreUserData?.firstName,
                lastName: firestoreUserData?.lastName,
                campaignInfo: firestoreUserData?.campaignInfo,
                role: determineUserRole(token?.claims),
                coachInfo: firestoreUserData?.coachInfo
              };
            })
          );
      })
    );
  }

  async updateUser(newUser: Partial<IUser>): Promise<string | void> {
    if (!newUser.id) {
      throw new Error('User ID is missing');
    }

    const updatedUserData = {
      ...newUser,
      displayName: newUser.displayName ?? '',
      email: newUser.email ?? '',
      photoUrl: newUser.photoUrl ?? '',
      eLearningData: newUser.eLearningData ?? {},
      campaignInfo: newUser.campaignInfo ?? {},
      coachInfo: newUser.coachInfo ?? {},
      firstName: newUser.firstName ?? '',
      lastName: newUser.lastName ?? '',
    };

    return this.db
      .collection('users')
      .doc(newUser.id)
      .set(updatedUserData)
      .then(() => {
        this.angularFireAuth.currentUser.then(async (user) => {
          if (user.uid === newUser.id) {
            this.userData$.next(user);
          }
        });
        return newUser.id;
      });
  }

  async sendPasswordResetEmail(email: string): Promise<string> {
    return this.angularFireAuth
      .sendPasswordResetEmail(email)
      .then(() => 'Email envoyé avec succès !')
      .catch((err) => {
        if (err.code === 'auth/user-not-found') {
          throw new Error('Utilisateur introuvable');
        } else if (err.code === 'auth/invalid-email') {
          throw new Error("Format d'email incorrect");
        } else throw new Error("Une erreur est survenue, veuillez vérifier l'email renseigné");
      });
  }

  async confirmPasswordReset(code: string, password: string): Promise<void> {
    return this.angularFireAuth.confirmPasswordReset(code, password).catch((err) => {
      if (err.code === 'auth/invalid-action-code') {
        throw new Error("Le jeton de reset de mot de passe est invalide. Peut-être l'avez vous déjà utilisé ?");
      } else if (err.code === 'auth/expired-action-code') {
        throw new Error('Le jeton de reset de mot de passe est expiré. Veuillez réeffectuer la demande de réinitialisation de mot de passe.');
      } else if (err.code === 'auth/user-disabled') {
        throw new Error("L'utilisateur ciblé (vous) est désactivé.");
      } else if (err.code === 'auth/user-not-found') {
        throw new Error("L'utilisateur ciblé (vous) est introuvable.");
      } else if (err.code === 'auth/weak-password') {
        throw new Error('Le mot de passe entré est trop simple. Veuillez sélectionner un mot de passe plus complexe');
      } else throw new Error('Une erreur est survenue :' + err);
    });
  }

  getELearningData(): Observable<IELearningData | null> {
    return this.userData$.asObservable().pipe(
      switchMap((currentUser) => {
        if (!currentUser) {
          return of(null);
        }
        return this.db
          .collection('eLearning')
          .doc(currentUser?.uid)
          .get()
          .pipe(
            map((progress) => {
              const progressData = progress.data() as any;
              if (!progressData) {
                return null;
              }
              return {
                id: progressData.id,
                completedCourses: progressData.completedCourses,
                connections: progressData.connections,
                connectionsV2: progressData.connectionsV2,
                obtainedMasterclasses: progressData.obtainedMasterclasses,
                trainingType: progressData.trainingType
              };
            })
          );
      })
    );
  }

  async registerELearningProgress(courseId: string): Promise<void> {
    const currentUser = await this.angularFireAuth.currentUser;
    if (!currentUser) {
      return;
    }
    this.getELearningData().subscribe(async (progress) => {
      if (!progress?.completedCourses?.find((completedCourse) => completedCourse === courseId)) {
        await this.db
          .collection('eLearning')
          .doc(currentUser.uid)
          .set(
            {
              completedCourses: firebase.firestore.FieldValue.arrayUnion(courseId)
            },
            { merge: true }
          );
      }
    });
  }

  async updateObtainedMasterclass(userId: string, masterclassId: string): Promise<void> {
    await this.db
      .collection('eLearning')
      .doc(userId)
      .set(
        {
          obtainedMasterclasses: firebase.firestore.FieldValue.arrayUnion(masterclassId)
        },
        { merge: true }
      );
  }

  async registerELearningConnection(data: IConnection): Promise<void> {
    const currentUser = await this.angularFireAuth.currentUser;
    return this.db
      .collection('eLearning')
      .doc(currentUser.uid)
      .set(
        {
          connections: firebase.firestore.FieldValue.arrayUnion(data)
        },
        { merge: true }
      );
  }

  async reauthenticateUser(currentUser, password?: string): Promise<void> {
    const providerId = currentUser.providerData[0].providerId;

    if (providerId === AuthProvider.Password) {
      const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
      await currentUser.reauthenticateWithCredential(credentials);
    } else if (providerId === AuthProvider.Google) {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      await currentUser.reauthenticateWithPopup(googleProvider);
    } else {
      throw new Error('Unknown auth provider');
    }
  }

  async deleteUserData(currentUser): Promise<void> {
    await this.db.collection('users').doc(currentUser.uid).delete();
    await this.db.collection('eLearning').doc(currentUser.uid).delete();
    if (currentUser.photoURL && currentUser.photoURL.startsWith('https://firebasestorage.googleapis.com/')) {
      this.angularFireStorage.refFromURL(currentUser.photoURL).delete().pipe(take(1));
    }
    await currentUser.delete();
    this.userData$.next(null);
  }

  async deleteUser(password?: string): Promise<void> {
    const currentUser = await this.angularFireAuth.currentUser;
    await this.reauthenticateUser(currentUser, password);
    await this.deleteUserData(currentUser);
  }

  async getProvider(): Promise<string> {
    const currentUser = await this.angularFireAuth.currentUser;
    const provider = currentUser.providerData[0].providerId;
    return provider;
  }

  async downloadFileInFirebase(file: File, targetPath: string, acceptedTypes: string[]): Promise<string> {
    let photoUrl = '';
    console.log(file.type);

    if (file && file instanceof File) {
      if (acceptedTypes.includes(file.type)) {
        await this.angularFireStorage
          .upload(targetPath + uuid(), file)
          .then((photo) =>
            photo.ref.getDownloadURL().then((url) => {
              photoUrl = url;
            })
          )
          .catch((err) => {
            throw new Error(err);
          });
      } else {
        throw new Error('Un problème est survenu - le fichier est-il au bon format ?');
      }
    }

    return photoUrl;
  }

  getRemoteConfig(): Observable<AngularFireRemoteConfig> {
    return this.http.get<AngularFireRemoteConfig>(`${this.rootURL}/remote-config/`);
  }

  selectNewELearningVersion(versionId: string): Observable<string> {
    return this.http.post<string>(`${this.rootURL}/remote-config/select-new-version`, { versionId });
  }

  getPasswordRequirementsRegex(): RegExp {
    return /^.{6,}$/;
  }

  getPasswordRequirementsMessage(): string {
    return 'Le mot de passe doit contenir au moins 6 caractères.';
  }
}
