<mat-tab-group>
  <mat-tab label="Utilisateur" *ngIf="isUser">
    <form [formGroup]="userForm" class="form">
      <div class="form-element">
        <label for="firstName">Prénom<span class="mandatory-symbol">*</span></label>
        <input id="name" type="text" formControlName="firstName" />
      </div>
      <div class="form-element">
        <label for="lastName">Nom<span class="mandatory-symbol">*</span></label>
        <input id="name" type="text" formControlName="lastName" />
      </div>
      <div class="form-element">
        <label for="photoUrl">Photo</label>
        <div class="image-upload-wrapper">
          <div class="image-upload-container" (click)="fileInput.click()">
            <img *ngIf="photoForDisplay" [src]="photoForDisplay" class="selected-picture" />
            <ng-template #defaultContent>
              <div class="default-message">Sélectionner la photo</div>
            </ng-template>
            <input type="file" accept="image/png, image/jpeg" #fileInput (change)="uploadPicture($event)" hidden />
          </div>
        </div>
      </div>
      <div class="form-element">
        <label for="obtainedMasterclasses">Formations</label>
        <mat-form-field class="masterclass-select" appearance="fill">
          <mat-label>Formations débloquées</mat-label>
          <mat-select #masterclassSelect formControlName="obtainedMasterclasses" multiple>
            <mat-option *ngFor="let masterclass of data.masterclasses" [value]="masterclass.id">{{ masterclass.name }}</mat-option>
            <mat-option [value]="0" (click)="toggleAllSelection()">Tout</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="isCoach || isSubscriber">
        <div class="form-element">
          <label for="facebookUrl">Facebook</label>
          <input id="name" type="text" formControlName="facebookUrl" />
        </div>
        <div class="form-element">
          <label for="instagramUrl">Instagram</label>
          <input id="name" type="text" formControlName="instagramUrl" />
        </div>
        <div class="form-element">
          <label for="youtubeUrl">YouTube</label>
          <input id="name" type="text" formControlName="youtubeUrl" />
        </div>
        <div class="form-element">
          <label for="idGoogleAds">ID Google Ads</label>
          <input id="name" type="text" formControlName="idGoogleAds" />
        </div>
        <div class="form-element">
          <label for="idGoogleBusinessManager">ID Google Business Manager<span class="mandatory-symbol"></span></label>
          <input id="name" type="text" formControlName="idGoogleBusinessManager" />
        </div>
        <div class="form-element">
          <label for="defaultCountries">Pays (séparés par une virgule)<span class="mandatory-symbol"></span></label>
          <input id="name" type="text" formControlName="defaultCountries" />
        </div>
      </ng-container>
    </form>
  </mat-tab>
  <mat-tab *ngIf="isCoach">
    <ng-template mat-tab-label *ngIf="isCoach"> Coach <span class="mandatory-symbol">*</span></ng-template>
    <form [formGroup]="coachForm" class="form">
      <div class="form-element top-inputs">
        <div class="wrapper-category-title">
          <label for="categoryTitle">Titre de la catégorie<span class="mandatory-symbol">*</span></label>
          <mat-form-field appearance="fill">
            <mat-label>Sélectionner</mat-label>
            <mat-select formControlName="categoryTitle">
              <mat-option *ngFor="let category of categoriesTitles" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="category-coachings">
          <label for="photoUrl">Catégories de coaching<span class="mandatory-symbol">*</span></label>
          <mat-form-field appearance="fill">
            <mat-label>Selectionner</mat-label>
            <mat-select formControlName="coachingCategories" multiple>
              <mat-option *ngFor="let category of coachingCategoriesOptions" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-element">
        <label>Choisir le coach<span class="mandatory-symbol">*</span></label>
        <mat-form-field appearance="fill">
          <mat-label>Choisir un coach</mat-label>
          <mat-select formControlName="coachName">
            <mat-option *ngFor="let coach of coachesList" [value]="coach.name">
              {{ coach.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-element">
        <label for="catchphrasecard">Phrase d'accroche (carte)<span class="mandatory-symbol">*</span></label>
        <input id="catchphrasecard" type="text" formControlName="catchphrasecard" />
      </div>

      <div formArrayName="sessions" *ngIf="sessionsFormArray.controls.length > 0">
        <span>Définir le prix des sessions<span class="mandatory-symbol">*</span></span>
        <div class="sessionprice" *ngFor="let session of sessionsFormArray.controls; let i = index" [formGroupName]="i">
          <span>durée: {{ formatDuration(session.get('duration').value) }} minutes</span>
          <input formControlName="price" placeholder="Prix (€)">
        </div>
      </div>
      
      <div [formGroup]="descriptionFormGroup" class="descriptionFormGroup">
        <div class="form-element">
          <label for="content">Description du coach<span class="mandatory-symbol">*</span></label>
          <textarea id="content" formControlName="content"></textarea>
        </div>
        <div class="form-element">
          <div class="wrapper-plus-logo">
            <label>Bénéfices de travailler avec le coach</label>
            <img class="icon-action" *ngIf="bulletsPoints.controls.length < 4" src="../../../../../assets/icons/plus.svg" (click)="addBulletPoint()" />
          </div>
          <div formArrayName="bulletsPoints" class="bulletsPoints">
            <div *ngFor="let bulletControl of bulletsPoints.controls; let i = index" class="bulletPoint">
              <input [formControlName]="i" type="text" />
              <img class="icon-action" src="../../../../../assets/icons/delete-cross.svg" (click)="removeBulletPoint(i)" />
            </div>
          </div>
        </div>
        <div class="form-element">
          <label for="catchphrase">Phrase d'accroche</label>
          <input id="catchphrase" type="text" formControlName="catchphrase" />
        </div>
      </div>
      <div class="form-element">
        <div class="wrapper-plus-logo">
          <label>Références</label>
          <img class="icon-action" *ngIf="references.controls.length < 5" src="../../../../../assets/icons/plus.svg" (click)="addReference()" />
        </div>
        <div formArrayName="references">
          <div class="reference" *ngFor="let reference of references.controls; let i = index" [formGroupName]="i">
            <span>Réference #{{ i + 1 }}<span class="mandatory-symbol">*</span></span>
            <input formControlName="title" type="text" placeholder="Titre" />
            <textarea formControlName="description" placeholder="Description"></textarea>
            <input formControlName="link" type="text" placeholder="Lien" />
            <div class="image-upload-container" (click)="fileInputRef.click()">
              <img *ngIf="reference.get('photoUrl').value; else defaultContent" [src]="reference.get('photoUrl').value" class="selected-picture" />
              <ng-template #defaultContent>
                <div class="default-message">Sélectionner la photo</div>
              </ng-template>
              <input type="file" accept="image/png, image/jpeg" #fileInputRef (change)="uploadReferencePicture($event, i)" [hidden]="reference.get('photoUrl').value" />
            </div>
            <img class="icon-action" src="../../../../../assets/icons/delete-cross.svg" (click)="removeReference(i)" />
          </div>
        </div>
      </div>
    </form>
  </mat-tab>
  <div class="loading-layer" *ngIf="loading">
    <app-loader></app-loader>
  </div>
</mat-tab-group>
<div class="element-form-buttons">
  <app-button text="Sauvegarder" (click)="save()"></app-button>
</div>
