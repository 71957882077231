<div class="module-overview-wrapper">
  <div class="module-overview">
    <div class="info">
      <img class="module-image" [src]="module.iconUrl" />
      <span class="name">{{ module.name }}</span>
      <span class="description" [innerHTML]="module.description"></span>
      <div class="duration">
        <img class="timer-icon" src="../../../../../assets/icons/timer.svg" />
        <span class="duration-text">{{ module.duration > 0 ? module.duration + ' heures' : "Moins d'1 heure" }}</span>
      </div>
      <div class="progress-and-next-button">
        <div *ngIf="innerWidth > 768" class="progress-bar-wrapper">
          <div class="progress-bar-info">
            <span>Progression</span>
            <span [ngStyle]="{ visibility: loadingProgress }">{{ module.progress ?? 0 }}%</span>
          </div>
          <div class="progress-bar" [ngClass]="{ loading: loadingProgress }">
            <div class="full" [ngStyle]="{ visibility: loadingProgress ? 'hidden' : 'visible', width: (module.progress ?? 0) + '%' }"></div>
          </div>
        </div>
        <app-button *ngIf="module.isAvailableInDemo || masterclass.isObtained; else buyButton" class="next-button" text="GO !" (click)="goToModuleClicked()"></app-button>
        <ng-template #buyButton>
          <app-button class="next-button" text="OBTENIR" (click)="goToBuyMasterclassClicked()"></app-button>
        </ng-template>
      </div>
    </div>
    <img *ngIf="innerWidth < 768" class="close-icon" src="../../../../../assets/icons/close.svg" (click)="closeModuleOverviewClicked()" />
  </div>
</div>
