import { Component } from '@angular/core';
import { SubSink } from 'subsink';
import { faq } from 'src/app/modules/home/json/faq';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  subs = new SubSink();

  items = faq;
}
