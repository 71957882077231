import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonComponent } from './button/button.component';
import { HeaderComponent } from './header/header.component';
import { ShortNumberPipe } from 'src/app/pipes/short-number.pipe';
import { CapitalizeFirstPipe } from 'src/app/pipes/capitalize-first-letter.pipe';
import { CardComponent } from './card/card.component';
import { ProfileMinifiedComponent } from './profile-minified/profile-minified.component';
import { CallToActionDialogComponent } from './call-to-action-dialog/call-to-action-dialog.component';
import { InputComponent } from './input/input.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarDialogComponent } from './date-picker/calendar-dialog/calendar-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [LoaderComponent, FooterComponent, ButtonComponent, HeaderComponent, ShortNumberPipe, CapitalizeFirstPipe, CardComponent, CallToActionDialogComponent, ProfileMinifiedComponent, InputComponent, DatePickerComponent, CalendarDialogComponent],
  imports: [CommonModule, FormsModule, MatDatepickerModule, MatDialogModule],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  exports: [FormsModule, LoaderComponent, ButtonComponent, FooterComponent, HeaderComponent, ShortNumberPipe, CapitalizeFirstPipe, CardComponent, CallToActionDialogComponent, ProfileMinifiedComponent, InputComponent, DatePickerComponent]
})
export class SharedModule { }
