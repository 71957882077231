import { Component, Input } from '@angular/core';
import { SubSink } from 'subsink';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-coaching',
  templateUrl: './header-coaching.component.html',
  styleUrls: ['./header-coaching.component.scss']
})
export class HeaderCoachingComponent {
  subs = new SubSink();

  @Input() mobile: boolean;
  @Input() sidenav: MatSidenav;
  @Input() redirectUrl: string;

  currentRoute: string;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
      });
  }

  goBackHome() {
    if (this.currentRoute === '/coaching/coachs')
      this.router.navigate(['/home']);
    else
      this.router.navigate(['/coaching/coachs']);
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }
}
