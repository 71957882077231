import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICampaign, IELearningData, IMasterclassVersion, IUser } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackOfficeService {
  constructor(private http: HttpClient) { }

  rootURL = '/api';

  getCampaigns(): Observable<ICampaign[]> {
    return this.http.get<ICampaign[]>(`${this.rootURL}/campaigns/`);
  }

  updateCampaign(campaign: ICampaign): Observable<ICampaign> {
    return this.http.post<ICampaign>(`${this.rootURL}/campaigns/${campaign.id}`, campaign);
  }

  getUserById(uid: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.rootURL}/users/${uid}`);
  }

  getUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.rootURL}/users/`);
  }

  updateUser(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.rootURL}/users/${user.id}`, user);
  }

  deleteUser(user: IUser): Observable<void> {
    return this.http.delete<void>(`${this.rootURL}/users/${user.id}`);
  }

  getELearningDataForUser(userId: string): Observable<IELearningData> {
    return this.http.get<IELearningData>(`${this.rootURL}/e-learning/${userId}`);
  }

  updateELearningDataForUser(userId: string, eLearningData: IELearningData): Observable<IELearningData> {
    return this.http.post<IELearningData>(`${this.rootURL}/e-learning/${userId}`, eLearningData);
  }

  getELearningDataForAll(): Observable<IELearningData[]> {
    return this.http.get<IELearningData[]>(`${this.rootURL}/e-learning`);
  }

  getMasterclassVersions(): Observable<IMasterclassVersion[]> {
    return this.http.get<IMasterclassVersion[]>(`${this.rootURL}/masterclass-versions`);
  }

  createMasterclassVersion(masterclassVersion: IMasterclassVersion): Observable<IMasterclassVersion> {
    return this.http.post<IMasterclassVersion>(`${this.rootURL}/masterclass-versions`, masterclassVersion);
  }

  deleteMasterclassVersion(masterclassVersionId: string): Observable<void> {
    return this.http.delete<void>(`${this.rootURL}/masterclass-versions/${masterclassVersionId}`);
  }
}
