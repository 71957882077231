<div class="home">
  <div class="header">
    <a class="return-button" href="https://www.mymusicads.com/">
      <img class="left-arrow" src="../../../assets/icons/arrow-left-grey.svg" alt="Exit arrow" />
      <span>Retour vers mymusicads.com</span>
    </a>
    <a class="logo">
      <img src="../../../assets/images/MMA_LOGO_SIMPLE.svg" alt="Logo MyMusicAds" />
      <span class="my-space">Mon espace</span>
      <span *ngIf="isUserConnected !== null && isUserConnected === false" class="go-further">Envie d'aller plus loin ? <span class="connect" (click)="goTo('/profile/login')">Me connecter</span></span>
    </a>
    <app-profile-minified></app-profile-minified>
  </div>
  <div class="content-home">
    <div class="products">
      <ng-container *ngFor="let mmaProduct of mmaProducts">
        <app-card
          [imageName]="mmaProduct.imageName"
          [name]="mmaProduct.name"
          [description]="mmaProduct.description"
          [bannerText]="isDemoMode(mmaProduct) ? 'Version gratuite' : ''"
          (click)="innerWidth >= 768 ? selectProduct(mmaProduct) : navigate(mmaProduct)"
          [class.card-selected]="selectedProduct === mmaProduct"
        ></app-card>
      </ng-container>
    </div>
    <app-button
      [disabled]="selectedProduct === null"
      (click)="navigate(selectedProduct)"
      text="C'est parti">
    </app-button>
  </div>
</div>
