<div class="signup-form-wrapper">
  <span class="title">Inscription</span>
  <div class="providers">
    <div class="provider-button" (click)="signInWithGoogle()">
      <img src="../../../../assets/icons/google.svg" />
      <span>Inscrivez-vous avec Google</span>
    </div>
  </div>
  <span class="or">ou</span>
  <div class="inputs">
    <div class="input">
      <span class="label">Mail</span>
      <input type="email" [(ngModel)]="email" (ngModelChange)="validateEmail()" placeholder="Adresse e-mail"
        (blur)="onBlur('email')" />
    </div>
    <div class="names">
      <div class="input">
        <span class="label">Prénom</span>
        <input [(ngModel)]="firstName" (ngModelChange)="validateFirstName()" placeholder="Prénom"
          (blur)="onBlur('firstName')" />
      </div>
      <div class="input">
        <span class="label">Nom</span>
        <input [(ngModel)]="lastName" (ngModelChange)="validateLastName()" placeholder="Nom" (blur)="onBlur('lastName')" />
      </div>
    </div>
    <div class="input">
      <span class="label">Nom d'utilisateur</span>
      <input [(ngModel)]="username" (ngModelChange)="validateUsername()" placeholder="Nom d'utilisateur"
        (blur)="onBlur('username')" />
    </div>
    <div class="input">
      <span class="label">Mot de passe</span>
      <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password" placeholder="Mot de passe"
        (blur)="onBlur('password')" (ngModelChange)="validatePasswords()" />
      <img *ngIf="!showPassword; else barred" src="../../../assets/icons/eyes.svg" class="toggle-password"
        (click)="togglePasswordVisibility($event)" />
      <ng-template #barred>
        <img src="../../../assets/icons/eyes-barred.svg" class="toggle-password"
          (click)="togglePasswordVisibility($event)" />
      </ng-template>
    </div>
    <div class="input">
      <span class="label">Confirmation de mot de passe</span>
      <input [type]="showConfirmPassword ? 'text' : 'password'" [(ngModel)]="confirmPassword"
        (ngModelChange)="validatePasswords()" placeholder="Confirmation de mot de passe" (blur)="onBlur('confirmPassword')"
        (keyup.enter)="signUp()" />
      <img *ngIf="!showConfirmPassword; else barredConfirm" src="../../../assets/icons/eyes.svg" class="toggle-password"
        (click)="toggleConfirmPasswordVisibility($event)" />
      <ng-template #barredConfirm>
        <img src="../../../assets/icons/eyes-barred.svg" class="toggle-password"
          (click)="toggleConfirmPasswordVisibility($event)" />
      </ng-template>
    </div>
  </div>
  <span class="error-message" *ngIf="currentErrorMessage$ | async as currentErrorMessage">{{ currentErrorMessage
    }}</span>
  <div class="finalize">
    <app-button size="sm" (click)="signUp()" text="Je m'inscris" color="#fff" [disabled]="!isFormValid()"></app-button>
    <div class="policy-confidential">
      <span>En vous inscrivant, vous consentez</span>
      <span>à notre <a class="bold" href="https://www.mymusicads.com/cgv">politique de confidentialité.</a></span>
    </div>
  </div>
</div>
<span class="already-an-account">Vous avez un compte? <span (click)="navigate('/profile/login')">Se connecter</span></span>
