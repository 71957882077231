<div class="footer">
  <app-button
    *ngIf="hasCallToAction"
    size="lg"
    icon="email-plain"
    [href]="isUserConnected ? 'https://mail.google.com/mail/?view=cm&fs=1&to=contact@mymusicads.com' : 'https://mymusicads.com/nous-contacter'"
    text="Nous contacter >>"
  ></app-button>
  <div class="networks">
    <span class="footer-text theme-text">© MyMusicAds</span>
    <div class="social-media-icons">
      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mymusicads/?hl=fr">
        <img class="social-media-icon" src="../../../../assets/icons/instagram.svg" alt="Instagram" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@mymusicads.com">
        <img class="social-media-icon" src="../../../../assets/icons/email.svg" alt="Email" />
      </a>
      <a class="social-media-icon-reversed-wrapper" target="_blank" rel="noopener noreferrer" href="https://mymusicads.com/nous-contacter">
        <img class="social-media-icon-reversed svg-black" src="../../../../assets/images/MMA_LOGO_BLEU_RVB_couronne.svg" alt="MMA" />
      </a>
    </div>
  </div>
</div>

<!-- Icons made by Freepik (https://www.freepik.com) and iconixar (https://www.flaticon.com/authors/iconixar) from https://www.flaticon.com/ -->
