<form [formGroup]="formGroup">
  <mat-stepper formArrayName="formArray" linear #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step label="Choix du type de contenu" formGroupName="0" [stepControl]="formArray?.get([0])!">
      <div class="step">
        <app-step-header name="type"></app-step-header>
        <div class="selectable-form-images">
          <ng-container *ngFor="let type of types">
            <app-selectable-form-field-image
              [hasBorder]="true"
              [formFieldImage]="type"
              [selected]="formValueChecker([{ formGroupId: 0, fieldName: 'type', validOptions: [type.value] }])"
              (selectedChange)="onTypeClick($event)"
            ></app-selectable-form-field-image>
          </ng-container>
        </div>
        <div class="step-buttons">
          <button class="form-field next-button" type="button" mat-button matStepperNext [disabled]="!formValueChecker([{ formGroupId: 0, fieldName: 'type', validOptions: ['*'] }])">
            Étape suivante
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Choix de la plateforme" formGroupName="1" [stepControl]="formArray?.get([1])!">
      <div class="step">
        <app-step-header [name]="'platform-' + formArray?.get([0])?.value.type"></app-step-header>
        <div class="selectable-form-images">
          <ng-container *ngFor="let platform of platforms">
            <app-selectable-form-field-image
              *ngIf="formValueChecker(platform.conditions)"
              [formFieldImage]="platform.image"
              [selected]="formValueChecker([{ formGroupId: 1, fieldName: 'platform', validOptions: [platform.image.value] }])"
              (selectedChange)="onPlatformClick($event)"
            ></app-selectable-form-field-image>
          </ng-container>
        </div>
        <div class="step-buttons">
          <button class="form-field" type="button" mat-button matStepperPrevious>Étape précédente</button>
          <button class="form-field next-button" type="button" mat-button matStepperNext [disabled]="!formValueChecker([{ formGroupId: 1, fieldName: 'platform', validOptions: ['*'] }])">
            Étape suivante
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Liens et pays ciblés" formGroupName="2" [stepControl]="formArray?.get([2])!">
      <div class="step">
        <app-step-header name="links"></app-step-header>
        <div class="links">
          <ng-container *ngFor="let link of links">
            <div *ngIf="formValueChecker(link.conditions)" class="label-input">
              <label class="label">{{ link.label }}&nbsp;<span *ngIf="link.required" class="required-field">*</span></label>
              <input class="form-field input-field" [formControlName]="link.formControlName" [placeholder]="link.placeholder" [required]="link.required ?? true" />
              <label *ngIf="link.helper !== ''" class="label helper">{{ link.helper }}</label>
            </div>
          </ng-container>
        </div>
        <div class="label-input">
          <label class="label">Pays (séparés par des virgules si plusieurs)&nbsp;<span class="required-field">*</span></label>
          <input class="form-field input-field" formControlName="countries" placeholder="Ex: France, Canada, Angleterre" required />
        </div>
        <div class="step-buttons">
          <button class="form-field" type="button" mat-button matStepperPrevious>Étape précédente</button>
          <button
            class="form-field next-button"
            type="button"
            mat-button
            matStepperNext
            (click)="onLinkValidationClicked()"
            [disabled]="!formValueChecker([{ formGroupId: 2, fieldName: 'countries', validOptions: ['*'] }])"
          >
            Étape suivante
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Choix du budget" formGroupName="3" [stepControl]="formArray?.get([3])!">
      <div class="step">
        <app-step-header name="budget"></app-step-header>
        <div class="budget-fields">
          <ng-container *ngIf="formValueChecker([{ formGroupId: 1, fieldName: 'platform', validOptions: ['spotify'] }])">
            <span class="disclaimer">NB : le budget minimum pour une campagne Spotify est de 250€.</span>
          </ng-container>
          <div class="budget-buttons">
            <ng-container *ngIf="!formValueChecker([{ formGroupId: 1, fieldName: 'platform', validOptions: ['spotify'] }])">
              <button class="form-field" [ngStyle]="{ color: '#fab41a', 'border-color': '#fab41a' }" type="button" mat-button (click)="estimate(100)">100€</button>
              <button class="form-field" [ngStyle]="{ color: '#de901a', 'border-color': '#de901a' }" type="button" mat-button (click)="estimate(200)">200€</button>
              <button class="form-field" [ngStyle]="{ color: '#de6f1a', 'border-color': '#de6f1a' }" type="button" mat-button (click)="estimate(300)">300€</button>
              <button class="form-field" [ngStyle]="{ color: '#de3f1a', 'border-color': '#de3f1a' }" type="button" mat-button (click)="estimate(400)">400€</button>
            </ng-container>
            <input class="custom-budget-button form-field input-field" type="number" formControlName="budget" placeholder="Personnalisé..." (input)="estimate($any($event.target)?.value)" required />
          </div>
        </div>
        <div class="estimation">
          <ng-container *ngIf="loadingEstimation; else estimationBlock">
            <div class="dot-flashing"></div>
          </ng-container>
          <ng-template #estimationBlock>
            <ng-container *ngIf="estimation && formArray?.get([3, 'budget'])?.value > 0; else noEstimationBlock">
              <span *ngIf="estimation.days"
                ><span class="enhanced-text">{{ estimation.days | shortNumber }}</span> jours</span
              >
              <span *ngIf="estimation.impressions"
                ><span class="enhanced-text">{{ estimation.impressions | shortNumber }}</span> impressions</span
              >
              <span *ngIf="estimation.views"
                ><span class="enhanced-text">{{ estimation.views | shortNumber }}</span> vues</span
              >
              <span *ngIf="estimation.clicks"
                ><span class="enhanced-text">{{ estimation.clicks | shortNumber }}</span> clics</span
              >
              <span *ngIf="estimation.listeners"
                ><span class="enhanced-text">{{ estimation.listeners | shortNumber }}</span> auditeurs</span
              >
              <span *ngIf="estimation.streamings"
                ><span class="enhanced-text">{{ estimation.streamings | shortNumber }}</span> streamings</span
              >
              <span *ngIf="estimation.interactions"
                ><span class="enhanced-text">{{ estimation.interactions | shortNumber }}</span> interactions</span
              >
              <span *ngIf="estimation.attendees"
                ><span class="enhanced-text">{{ estimation.attendees | shortNumber }}</span> participants</span
              >
            </ng-container>
          </ng-template>
          <ng-template #noEstimationBlock>
            <span class="estimation-empty">Veuillez entrer un budget afin d'avoir une estimation de vos résultats</span>
          </ng-template>
        </div>
        <div *ngIf="!demo" class="promocodes">
          <div class="label-input">
            <label class="label">Adresse e-mail<span class="required-field">*</span></label>
            <input [formControlName]="'email'" class="form-field input-field" type="text" placeholder="Ex: contact@mymusicads.com" [disabled]="true" />
          </div>
        </div>
        <div class="budget-step-buttons-wrapper">
          <div class="step-buttons">
            <button class="form-field" type="button" (click)="resetBudget()" mat-button matStepperPrevious>Étape précédente</button>
            <button
              class="form-field next-button"
              type="button"
              (click)="onCheckoutClicked()"
              [disabled]="
                !formValueChecker(
                  !demo
                    ? [
                        { formGroupId: 3, fieldName: 'budget', validOptions: ['*'] },
                        { formGroupId: 3, fieldName: 'email', validOptions: ['*'] }
                      ]
                    : [{ formGroupId: 3, fieldName: 'budget', validOptions: ['*'] }]
                )
              "
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Confirmation</ng-template>
    </mat-step>
  </mat-stepper>
</form>
