import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-coach-card',
  templateUrl: './coach-card.component.html',
  styleUrls: ['./coach-card.component.scss']
})

export class CoachCardComponent implements OnInit {
  @Input() coach: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
    coachInfo: {
      calendlyUrl: string;
      coachingCategories: string[];
      description: any;
      catchphrasecard: string;
    }
  } | null;
  @Input() index: string;

  constructor(private router: Router, private gtmService: GoogleTagManagerService) { }

  navigateToCoach(id: string) {
    const gtmTagClickCoachs = {
      event: 'click_coachs',
      detail_click: 'coach_cards',
      coachs: `${this.coach.firstName} ${this.coach.lastName.toUpperCase()}`
    };

    this.gtmService.pushTag(gtmTagClickCoachs);
    this.router.navigate(['/coaching/coach/', id]);
  }

  ngOnInit(): void {
  }
}
