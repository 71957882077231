import { IFormFieldImage } from '../../models/SelectableFormFieldImage';

export const campaignTypes: IFormFieldImage[] = [
  {
    value: 'clip',
    name: 'Clip vidéo',
    imageUrl: 'icons/clip-white.png'
  },

  {
    value: 'project',
    name: 'Projet musical',
    imageUrl: 'icons/project-white.png'
  },

  {
    value: 'publication',
    name: 'Publication',
    imageUrl: 'icons/publication-white.png'
  },

  {
    value: 'event',
    name: 'Évènement',
    imageUrl: 'icons/event-white.png'
  }
];
