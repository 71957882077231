import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { SharedModule } from '../shared/shared.module';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditPictureDialogComponent } from './manage-profile/edit-picture-dialog/edit-picture-dialog.component';
import { DeleteAccountDialogComponent } from './manage-profile/delete-account-dialog/delete-account-dialog.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordDialogComponent } from './login/forgot-password-dialog/forgot-password-dialog.component';
import { SignupComponent } from './signup/signup.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignPageComponent } from './sign-page/sign-page.component';

@NgModule({
  declarations: [
    ProfileComponent,
    EditPictureDialogComponent,
    ManageProfileComponent,
    ResetPasswordComponent,
    DeleteAccountDialogComponent,
    ForgotPasswordDialogComponent,
    LoginComponent,
    SignupComponent,
    SignPageComponent
  ],
  imports: [
    CommonModule, SharedModule, AppRoutingModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatFormFieldModule
  ],
  exports: [
    ProfileComponent,
    ManageProfileComponent,
    ResetPasswordComponent
  ]
})
export class ProfileModule { }
