import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendlyService {
  rootURL = '/api';

  constructor(private http: HttpClient) { }

  formatDuration(duration: number): string {
    if (duration >= 60) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours}h${minutes > 0 ? minutes : ''}`;
    } else {
      return `${duration}min`;
    }
  }

  getOrganizationMemberships(): Observable<any> {
    return this.http.get<any>(`${this.rootURL}/calendly/organizationMemberships`);
  }

  getSessions(uri: string): Observable<any> {
    try {
      const url = new URL(uri);
      const uriSegments = url.pathname.split('/');
      const coachUri = uriSegments.pop();
      return this.http.get<any>(`${this.rootURL}/calendly/sessions/${coachUri}`);
    } catch (e) {
      throw e;
    }
  }
}
