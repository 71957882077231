import { Component, Input } from '@angular/core';

export type LoaderSize = 'sm' | 'md';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() size: LoaderSize = 'md';
}
