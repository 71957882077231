<div class="header">
  <a class="logo" href="/home">
    <img [src]="innerWidth > 768 ? '../../../assets/images/MMA_LOGO_JAUNE_RVB_long.svg' : '../../../assets/images/MMA_LOGO_JAUNE_RVB_entier.svg'" alt="Logo MyMusicAds" referrerpolicy="no-referrer" />
  </a>
</div>
<div *ngIf="currentUser$ | async as currentUser; else loading" class="manage-profile">
  <form class="manage-profile-form" [formGroup]="updateUserForm" (ngSubmit)="updateUser()">
    <div>
      <form class="subform" formGroupName="personalInfoForm">
        <span class="title">Informations personnelles</span>
        <div class="profile-picture-wrapper">
          <div class="profile-picture">
            <img class="profile-picture-img" [src]="photoUrl && photoUrl !== '' ? photoUrl : '../../../assets/icons/empty-profile.png'" referrerpolicy="no-referrer" />
          </div>
          <div class="edit-profile-picture" (click)="openEditPictureDialog()">
            <img class="edit-profile-picture-img" src="../../../assets/icons/camera.svg" />
          </div>
        </div>
        <div class="input-wrapper">
          <app-input class="input" icon="badge" formControlName="username" placeholder="Nom d'utilisateur"></app-input>
          <app-input class="input" icon="at" formControlName="email" placeholder="Adresse e-mail" [disabled]="true"></app-input>
        </div>
        <div class="input-wrapper">
          <app-input class="input" icon="user" formControlName="firstName" placeholder="Prénom"></app-input>
          <app-input class="input" icon="user" formControlName="lastName" placeholder="Nom"></app-input>
        </div>
        <span class="link" (click)="sendPasswordResetEmail(currentUser.email)">Changer mon mot de passe</span>
        <span class="change-password-message" [ngStyle]="{ color: changePasswordError ? 'red' : 'green', visibility: changePasswordMessage ? 'visible' : 'hidden' }">{{
          changePasswordMessage ?? 'empty'
        }}</span>
      </form>
    </div>
    <div *ngIf="isEnoughSubscriber">
      <form class="subform" formGroupName="createCampaignInfoForm">
        <span class="title">Paramètres de création de campagne</span>
        <div class="input-wrapper">
          <app-input class="input" icon="facebook-b&w" formControlName="facebookUrl" placeholder="Url de la page Facebook"></app-input>
          <app-input class="input" icon="instagram" formControlName="instaUrl" placeholder="Url de la page Instagram"></app-input>
        </div>
        <div class="input-wrapper">
          <app-input class="input" icon="youtube" formControlName="youtubeEmail" placeholder="Email du compte Youtube"></app-input>
          <app-input class="input" icon="location" formControlName="defaultCountries" placeholder="Pays par défaut (séparés par une virgule)"></app-input>
        </div>
        <div class="input-wrapper">
          <app-input class="input" icon="user-settings" formControlName="googleAdsId" placeholder="ID Google Ads"></app-input>
          <app-input class="input" icon="user-settings" formControlName="businessManagerId" placeholder="ID Business Manager"></app-input>
        </div>
      </form>
    </div>
    <div *ngIf="currentUser.role === UserEnumRole.COACH">
      <form class="subform" formGroupName="coachInfo">
        <span class="title">Informations de coach</span>
        <div class="wrapper-catchphrase">
          <span class="description-explanation">Phrase d'accroche (carte de coach)<span class="mandatory-symbol">*</span></span>
          <app-input class="input" formControlName="catchphrasecard" placeholder="Phrase d'accroche"></app-input>
        </div>
        <div formGroupName="description">
          <span class="subtitle">Description</span>
          <div>
            <span class="description-explanation">Description en quelques phrases<span class="mandatory-symbol">*</span></span>
            <textarea placeholder="Description du coach" formControlName="content"></textarea>
            <small class="textarea-subtitle" [ngClass]="getDescriptionClass()">Description du coach</small>
          </div>
          <div formArrayName="bulletsPoints">
            <div class="wrapper-title-bullets-points">
              <span class="description-explanation">Découvrez comment mes services peuvent :</span>
              <img *ngIf="bulletsPoints.controls.length < 4" src="../../../../assets/icons/plus-orange.svg" (click)="addBulletPoint()" />
            </div>
            <div *ngFor="let bullet of bulletsPoints.controls; let i = index" class="bullet-point-wrapper">
              <app-input class="input" [formControlName]="i" placeholder="Point important"></app-input>
              <img src="../../../../assets/icons/delete-cross-orange.svg" (click)="removeBulletPoint(i)" />
            </div>
          </div>
          <div class="wrapper-catchphrase">
            <span class="description-explanation">Phrase d'accroche</span>
            <app-input class="input" formControlName="catchphrase" placeholder="Phrase d'accroche"></app-input>
          </div>
        </div>
        <div class="category-coachings">
          <span class="subtitle">Catégories de coaching<span class="mandatory-symbol">*</span></span>
          <mat-form-field appearance="fill">
            <mat-label>Selectionner</mat-label>
            <mat-select formControlName="coachingCategories" multiple>
              <mat-option *ngFor="let category of coachingCategoriesOptions" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="references" formArrayName="references">
          <span class="subtitle">Références</span>
          <div class="wrapper-title-references">
            <span class="references-title">Références :</span>
            <img *ngIf="references.controls.length < 5" src="../../../../assets/icons/plus-orange.svg" (click)="addReference()" />
          </div>
          <div *ngFor="let referenceGroup of references.controls; let i = index" [formGroupName]="i">
            <span class="reference">Référence {{ i + 1 }}</span>
            <app-input class="input" formControlName="title" placeholder="Titre"></app-input>
            <textarea placeholder="Description de la référence" formControlName="description"></textarea>
            <app-input class="input" formControlName="link" placeholder="Lien"></app-input>
            <div class="image-upload-container" (click)="fileInputRef.click()">
              <img *ngIf="referenceGroup.get('photoUrl').value && referenceGroup.get('photoUrl').value !== ''; else defaultContent" [src]="referenceGroup.get('photoUrl').value" class="selected-picture" />
              <ng-template #defaultContent>
                <div class="default-message">Sélectionner</div>
              </ng-template>
              <input type="file" accept="image/png, image/jpeg" #fileInputRef (change)="uploadReferencePicture($event, i)" hidden />
            </div>

            <img src="../../../../assets/icons/delete-cross-orange.svg" (click)="removeReference(i)" />
          </div>
        </div>
        <div formArrayName="sessions">
          <span class="subtitle">Définir le prix des sessions</span>
          <div class="sessionprice" *ngFor="let session of sessions.controls; let i = index" [formGroupName]="i">
            <span class="infoDuration">Session de {{ formatDuration(session.get('duration').value) }}</span>
            <app-input class="input" type="number" formControlName="price" placeholder="Prix (€)"></app-input>
          </div>
        </div>
      </form>
    </div>
    <div class="delete-account">
      <span class="title">Divers</span>
      <span class="link-danger" (click)="openDeleteAccountDialog()">Supprimer mon compte</span>
    </div>
    <div class="confirm-buttons">
      <div class="save-wrapper">
        <app-button class="save-button" type="submit" icon="save" [outlined]="true" text="Sauvegarder" (onClick)="updateUser()" [disabled]="!updateUserForm.dirty"></app-button>
        <span class="save-profile-message" [ngStyle]="{ color: saveProfileError ? 'red' : 'green', visibility: saveProfileMessage ? 'visible' : 'hidden' }">{{ saveProfileMessage ?? 'empty' }}</span>
      </div>
      <app-button type="submit" text="Confirmer" (onClick)="updateUserAndRedirect()"></app-button>
    </div>
  </form>
</div>
<app-footer></app-footer>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
