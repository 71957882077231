import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { DocType, ICourse, IDocInfo } from '@shared';
import { v4 as uuid } from 'uuid';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { formatYoutubeUrlToEmbed } from '../../utils/format-youtube-url';
import { ELearningElementDialogOperation } from '../../models/ELearningElement';
import { eLearningNameToId } from '../../utils/e-learning-name-to-id';

@Component({
  selector: 'app-new-course-form-dialog',
  templateUrl: './new-course-form-dialog.component.html',
  styleUrls: ['./new-course-form-dialog.component.scss']
})
export class NewCourseFormDialogComponent implements OnInit {
  newCourseForm = this.fb.group({
    name: ['', Validators.required],
    mediaType: ['video', Validators.required],
    videoUrl: [''],
    cheatsheet: [null],
    mcq: this.fb.array([])
  });

  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { elementInfo: ICourse }, private fb: FormBuilder, public dialogRef: MatDialogRef<NewCourseFormDialogComponent>, private backOfficeELearningService: BackOfficeELearningService, private firebaseService: FirebaseService) { }

  ngOnInit(): void {
    const mediaType = this.data.elementInfo?.docInfo.type;
    this.setValidatorsFromMediaType(mediaType);
    this.mapInfoToForm();
  }

  mapInfoToForm() {
    const elementInfo: ICourse = (this.data.elementInfo as ICourse);
    if (elementInfo) {
      const mediaType = elementInfo.docInfo.type;
      this.newCourseForm.patchValue({
        name: elementInfo.name,
        mediaType,
        videoUrl: mediaType === 'video' ? elementInfo.docInfo.url : null,
        cheatsheet: mediaType === 'pdf' ? elementInfo.docInfo.url : null,
        mcq: elementInfo.mcq ?? []
      });
    }
  }

  onMediaTypeChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const mediaType = target.value as DocType;
    this.setValidatorsFromMediaType(mediaType);
  }

  setValidatorsFromMediaType(mediaType: DocType) {
    if (!mediaType) {
      return;
    }
    const videoUrlControl = this.newCourseForm.get('videoUrl');
    const cheatsheetControl = this.newCourseForm.get('cheatsheet');
    if (mediaType === 'video') {
      videoUrlControl.setValidators(Validators.required);
      cheatsheetControl.clearValidators();
    } else {
      videoUrlControl.clearValidators();
      cheatsheetControl.setValidators(Validators.required);
    }
  }

  onCheatsheetChange(event: Event | null) {
    const target = event?.target as HTMLInputElement;

    if (!target) {
      this.newCourseForm.patchValue({
        cheatsheet: null
      });
    } else {
      this.newCourseForm.patchValue({
        cheatsheet: target.files[0]
      });
    }
  }

  getMediaType(): DocType {
    return this.newCourseForm.get('mediaType').value as DocType;
  }

  addQuestion() {
    // ajouter une question au formArray
  }

  deleteCourse(): void {
    this.dialogRef.close({ element: { info: this.data.elementInfo, type: 'course' }, operation: 'delete' });
  }

  async saveCourseWithOperation(operation: ELearningElementDialogOperation): Promise<void> {
    if (this.newCourseForm.valid) {
      this.loading = true;
      const formValue = this.newCourseForm.value;
      const mediaType = formValue.mediaType;
      if (mediaType === 'video') {
        const docInfo: IDocInfo = {
          name: formValue.name,
          type: mediaType,
          url: formatYoutubeUrlToEmbed(formValue.videoUrl)
        }

        const course: ICourse = {
          id: eLearningNameToId(formValue.name),
          ...this.data.elementInfo,
          name: formValue.name,
          docInfo,
          mcq: this.data.elementInfo?.mcq ?? []
        }
        this.dialogRef.close({ element: { info: course, type: 'course' }, operation });
      } else {
        await this.firebaseService.downloadFileInFirebase(formValue.cheatsheet, '/masterclasses/pdfs/', ['application/pdf']).then((cheatsheetUrl) => {
          const docInfo: IDocInfo = {
            name: formValue.name,
            type: mediaType,
            url: cheatsheetUrl?.length ? cheatsheetUrl : null
          }

          const course: ICourse = {
            id: uuid(),
            ...this.data.elementInfo,
            name: formValue.name,
            docInfo,
            mcq: this.data.elementInfo?.mcq ?? []
          }
          this.dialogRef.close({ element: { info: course, type: 'course' }, operation });
        }).catch((err) => {
          this.loading = false;
          alert(err);
        });
      }



    }
  }

  openCourse(): void {
    this.dialogRef.close(true);
  }
}
