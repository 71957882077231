import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { HttpClientModule } from '@angular/common/http';
import { BackOfficeModule } from './modules/back-office/back-office.module';
import { CreateCampaignModule } from './modules/create-campaign/create-campaign.module';
import { PixelModule } from 'ngx-pixel';
import { HomeModule } from './modules/home/home.module';
import { ProfileModule } from './modules/profile/profile.module';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { ELearningModule } from './modules/e-learning/e-learning.module';
import { NgxSplideModule } from 'ngx-splide';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CoachingModule } from './modules/coaching/coaching.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: '', options: {} };

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoachingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireRemoteConfigModule,
    BackOfficeModule,
    CreateCampaignModule,
    HttpClientModule,
    ProfileModule,
    ELearningModule,
    PixelModule.forRoot({ enabled: true, pixelId: '501976448384873' }),
    GoogleTagManagerModule.forRoot({id: 'GTM-TPC8VJ2'}),
    HomeModule,
    NgxSplideModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }