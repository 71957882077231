import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { v4 as uuid } from 'uuid';
import { IUser } from '@shared';

@Component({
  selector: 'app-edit-picture-dialog',
  templateUrl: './edit-picture-dialog.component.html',
  styleUrls: ['./edit-picture-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPictureDialogComponent {
  file: File;
  photoUrl: string | null = null;

  error = false;
  message: string | null = null;
  loading = false;
  currentUser: IUser | null = null;
  path = '';
  inputPhotoUrl = '';


  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: IUser }, public dialogRef: MatDialogRef<EditPictureDialogComponent>, private afStorage: AngularFireStorage, private firebaseService: FirebaseService) { }

  ngOnInit() {
    this.firebaseService.getCurrentUserProperties().subscribe((user) => {
      this.currentUser = user;
    });

  }

  uploadPicture(event: Event): void {
    this.message = null;
    this.error = false;
    this.loading = true;

    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    this.file = (event.target as HTMLInputElement).files[0];

    if (this.file && acceptedImageTypes.includes(this.file.type)) {
      this.afStorage.upload('/profilePicture/' + uuid(), this.file).then((photo) => photo.ref.getDownloadURL().then((url) => {
        this.loading = false;
        this.photoUrl = url;
      }));
    } else {
      this.message = 'Format non supporté.'
      this.error = true;
      this.loading = false;
    }
  }

  async upload(): Promise<string | void> {
    let newUser = {...this.currentUser};
    newUser.photoUrl = this.photoUrl;

    return await this.firebaseService.updateUser(newUser)
      .then(() => {
        this.dialogRef.close();
      })
      .catch((err) => {
        this.error = true;
        this.message = 'Une erreur est survenue.';
        console.error(err);
      });
  }
}
