import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { IUser } from '@shared';
import { CoachingService } from '../coaching.service';
import { ScreenSizeService } from '../../services/mobile/mobile.service';
import { CalendlyService } from '../../services/calendly/calendly.service';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-coach-details',
  templateUrl: './coach-details.component.html',
  styleUrls: ['./coach-details.component.scss']
})
export class CoachDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  subs = new SubSink();
  private routeSub: Subscription;
  id: string;

  readMore = false;

  @ViewChild('calendly') calendly: ElementRef;

  public showReadMoreButton: boolean = false;
  readonly MAX_HEIGHT = 150;

  coach$: Observable<IUser> = new Observable();
  coach = undefined;
  coachs = null;
  otherProfiles = null;
  isMobile = false;
  schedulingUrl: string | null = null;
  selectedSessionScheduling: string | null = null;
  user: IUser = null;
  isConnected: boolean = false;
  selectedSessionDuration: number | null = null;
  redirectUrl: string;
  sessionsWithPrice = [];

  innerWidth = window.innerWidth;

  constructor(
    private route: ActivatedRoute,
    private coachingService: CoachingService,
    private calendlyService: CalendlyService,
    private cdr: ChangeDetectorRef,
    private screenSizeService: ScreenSizeService,
    private firebaseService: FirebaseService,
    private router: Router
  ) {}

  onSelectSession(schedulingUrl: string) {
    this.setCalendlyUrl(schedulingUrl);
    this.refreshCalendlyWidget();
  }

  formatDuration(duration: string): string {
    return this.calendlyService.formatDuration(parseInt(duration));
  }

  scrollToCalendly(): void {
    const elementRect = this.calendly.nativeElement.getBoundingClientRect();
    const offsetTop = elementRect.top + window.scrollY;
    const offset = 84;

    window.scrollTo({
      top: offsetTop - offset,
      behavior: 'smooth'
    });
  }

  setCalendlyUrl(schedulingUrl: string) {
    this.selectedSessionScheduling = schedulingUrl;
    this.schedulingUrl = `${schedulingUrl}?background_color=010C1A&text_color=ffffff&primary_color=fab41a&hide_gdpr_banner=1`;
    this.cdr.detectChanges();
  }

  refreshCalendlyWidget() {
    const tmp = this.schedulingUrl;
    this.schedulingUrl = null;

    const existingScript = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    setTimeout(() => {
      this.schedulingUrl = tmp;
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      document.body.appendChild(script);
    });

    this.cdr.detectChanges();
  }

  navigate(route: string) {
    const queryParams = { queryParams: { redirectUrl: this.redirectUrl } };
    this.router.navigate([route], queryParams);
  }

  loadPage() {
    this.coach$ = this.coachingService.getCoach(this.id);
    this.subs.sink = this.coach$.subscribe(
      (coach) => {
        this.sessionsWithPrice = coach?.coachInfo?.sessions?.filter((session) => session.price === '0') || [];
        const sessions = coach?.coachInfo?.sessions?.filter((session) => session.price !== '') || [];
        this.coach = { ...coach, coachInfo: { ...coach.coachInfo, sessions, sessionsWithPrice: this.sessionsWithPrice } };

        if (this.sessionsWithPrice.length > 0) {
          this.selectedSessionScheduling = this.sessionsWithPrice[0].schedulingUrl;
          this.setCalendlyUrl(this.selectedSessionScheduling);
          this.initCalendly();
        }

        this.subs.sink = this.coachingService.getOtherProfiles(this.id).subscribe((otherProfiles) => {
          this.otherProfiles = otherProfiles;
        });
      },
      () => {
        this.router.navigate(['/coaching/coachs']);
      }
    );
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.firebaseService.getCurrentUserProperties().subscribe((user) => {
        this.user = user;
        if (!user) this.isConnected = false;
        else this.isConnected = true;

        if (this.id) {
          this.loadPage();
          this.redirectUrl = `/coaching/coach/${this.id}`;
        }
      });
    });
  }

  initCalendly() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    document.body.appendChild(script);
  }

  ngAfterViewInit() {}

  toggleReadMore() {
    this.readMore = !this.readMore;
  }

  ngOnDestroy(): void {}
}
