<div class="header" *ngIf="mobile === false; else mobileTemplate">
  <img src="../../../../assets/logos/MMA_LOGO.png" (click)="navigate('/coaching/coachs')" />
  <div class="header-buttons">
    <div class="button"><a href="https://www.mymusicads.com/nous-contacter">Contactez-nous</a></div>
    <div class="button" (click)="goBackHome()"><span>Retour vers accueil</span></div>
    <app-profile-minified [redirectUrl]="redirectUrl"></app-profile-minified>
  </div>
  <img class="mobile-menu" mat-icon-button src="../../../../assets/icons/mobile-menu.svg" (click)="sidenav?.toggle()" />
</div>
<ng-template #mobileTemplate>
  <div class="sidenav-header-buttons" #mobileTemplate>
    <img src="../../../../assets/logos/MMA_LOGO.png" (click)="navigate('/coaching/coachs')" />
    <app-profile-minified [mobile]="mobile" [redirectUrl]="redirectUrl"></app-profile-minified>
    <div class="sidenav-button"><a href="https://www.mymusicads.com/nous-contacter">Contactez-nous</a></div>
    <div class="sidenav-button" (click)="goBackHome()"><span>Retour vers accueil</span></div>
  </div>
</ng-template>