import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordDialogComponent {
  email = "";
  loading = false;

  message = "";
  error = false;
  isEmailValid = false;

  constructor(public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>, private firebaseService: FirebaseService) { }

  async sendPasswordResetEmail(email: string) {
    if (email) {
      this.loading = true;

      await this.firebaseService.sendPasswordResetEmail(email)
        .then((message) => {
          this.message = message;
          this.error = false;
          setTimeout(() => this.dialogRef.close(), 3000)
        })
        .catch((err) => {
          this.message = err;
          this.error = true;
        });

      this.loading = false;
    }
  }

  validateEmail(): void {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.isEmailValid = emailPattern.test(this.email);
    if (!this.isEmailValid && this.email !== '') {
      this.message = 'Adresse e-mail non valide.';
      this.error = true;
    } else {
      this.message = '';
      this.error = false;
    }
  }

  resetMessage() {
    if (this.message !== '') {
      this.message = '';
      this.error = false;
    }
  }

}
