import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, of, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

//requiredRole:
//'subscriber', 'trainer', 'admin', 'any', ou null
@Injectable({
  providedIn: 'root'
})
export class RoleBasedAuthGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const requiredRole = next.data['requiredRole'];
    const redirectIfRole = next.data['redirectIfRole'];
    const redirectIfNoRole = next.data['redirectIfNoRole'];

    return this.auth.user.pipe(
      switchMap(user => {
        if (!user) {
          if (requiredRole === null)
            return of(true);
          return this.handleRedirect(redirectIfNoRole);
        }
        return from(user.getIdTokenResult()).pipe(
          switchMap(idTokenResult => {
            const claims = idTokenResult.claims;
            const userRole = claims.role;
            const isAdmin = claims.admin === true;
            const roleHierarchy = ['null', 'any', 'subscriber', 'coach', 'trainer', 'admin'];

            const userRoleIndex = roleHierarchy.indexOf(userRole);
            const requiredRoleIndex = roleHierarchy.indexOf(requiredRole);
            const hasSufficientRole = userRoleIndex >= requiredRoleIndex || isAdmin;

            if (requiredRole === 'any' || hasSufficientRole) {
              return this.handleRedirect(redirectIfRole);
            } else {
              return this.handleRedirect(redirectIfNoRole);
            }
          })
        );
      })
    );
  }
  private handleRedirect(target: string | null): Observable<boolean | UrlTree> {
    if (target !== null)
      return of(this.router.createUrlTree([target]));
    return of(true);
  }
}

