import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IQuestion } from '@shared';
import { requiredFalse, requiredTrue } from 'src/app/modules/shared/utils/validators';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss']
})
export class McqComponent implements OnInit, OnChanges {
  @Input() mcq: IQuestion[];
  @Input() isSelectedCourseLast: boolean;
  @Input() isBackOffice: boolean = false;
  @Output() goToNextCourse = new EventEmitter();

  mcqForm: FormGroup;
  isSubmitted: boolean = false;
  isMcqValid: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mcq) {
      this.createMcqForm();
    }
  }

  createMcqForm(): void {
    this.isMcqValid = false;
    this.mcqForm = this.fb.group({});
    this.mcq.forEach((question, qIndex) => {
      this.mcqForm.addControl(`Q${qIndex}`, this.fb.array([]));
      question.propositions.forEach((proposition) => {
        const group = this.mcqForm.controls[`Q${qIndex}`] as FormArray;
        group.push(this.fb.control(false, proposition.isCorrect ? requiredTrue() : requiredFalse()));
      });
    });
  }

  setAnswerValue(questionIndex: number, answerIndex: number) {
    this.isSubmitted = false;
    this.isMcqValid = false;
    const group = this.mcqForm.controls[`Q${questionIndex}`] as FormArray;
    if (this.mcq[questionIndex].hasMultipleAnswers) {
      const actualValue: boolean = group.controls[answerIndex].value;
      group.controls[answerIndex].setValue(!actualValue);
    } else {
      group.controls.forEach((control) => control.setValue(false));
      group.controls[answerIndex].setValue(true);
    }
    this.touchControls(group);
  }

  touchControls(group: FormArray) {
    group.controls.forEach((control) => control.value === false ? control.markAsUntouched() : control.markAsTouched());
  }

  checkQuestionValidity(questionForm: FormGroup): boolean {
    return questionForm.valid;
  }

  checkMcqValidity(): void {
    this.isSubmitted = true;
    if (this.mcqForm.valid) {
      this.isMcqValid = true;
    }
  }

  checkAnswer(qIndex: number, pIndex: number) {
    const control = (this.mcqForm.get('Q' + qIndex) as FormArray).controls[pIndex];
    if (control.hasError('wrong') && control.touched && this.isSubmitted) {
      return 'wrong';
    }
    if (control.touched && this.isSubmitted) {
      return 'right';
    }
    return '';
  }

  validateCourse() {
    if (this.isMcqValid) {
      this.goToNextCourse.emit();
    }
  }
}
