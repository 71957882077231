import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoachsComponent } from './modules/coaching/coachs/coachs.component';
import { HomeComponent } from './modules/home/home.component';
import { BackOfficeComponent } from './modules/back-office/back-office.component';
import { CreateCampaignComponent } from './modules/create-campaign/create-campaign.component';
import { SuccessComponent } from './modules/create-campaign/success/success.component';
import { ErrorComponent } from './modules/create-campaign/error/error.component';
import { CampaignFormComponent } from './modules/create-campaign/campaign-form/campaign-form.component';
import { ResetPasswordComponent } from './modules/profile/reset-password/reset-password.component';
import { ManageProfileComponent } from './modules/profile/manage-profile/manage-profile.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ELearningComponent } from './modules/e-learning/e-learning.component';
import { CourseComponent } from './modules/e-learning/course/course.component';
import { MasterclassesComponent } from './modules/e-learning/masterclasses/masterclasses.component';
import { ModulesComponent } from './modules/e-learning/modules/modules.component';
import { RoleBasedAuthGuard } from './modules/services/router/custom-auth-guard.service';
import { AngularFireAuthGuard, hasCustomClaim } from '@angular/fire/compat/auth-guard';
import { CoachingComponent } from './modules/coaching/coaching.component';
import { CoachDetailsComponent } from './modules/coaching/coach-details/coach-details.component';
import { SignPageComponent } from './modules/profile/sign-page/sign-page.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


const adminOnly = () => hasCustomClaim('admin');

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'coaching',
    component: CoachingComponent,
    children: [
      {
        path: 'coachs',
        component: CoachsComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: null,
          redirectIfRole: null,
          redirectIfNoRole: '/home',
        }
      },
      {
        path: 'coach/:id',
        component: CoachDetailsComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: null,
          redirectIfRole: null,
          redirectIfNoRole: '/home',
        }
      },
      {
        path: '**',
        redirectTo: '/home',
      },
    ],
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'subscriber',
          redirectIfRole: null,
          redirectIfNoRole: '/home/demo',
        }
      },
      {
        path: 'demo',
        component: HomeComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'subscriber',
          redirectIfRole: '/home',
          redirectIfNoRole: null,
          demo: true
        }
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: 'admin',
    component: BackOfficeComponent
  },
  {
    path: 'create-campaign',
    component: CreateCampaignComponent,
    children: [
      {
        path: '',
        component: CampaignFormComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'subscriber',
          redirectIfRole: null,
          redirectIfNoRole: '/create-campaign/demo',
        }
      },
      {
        path: 'demo',
        component: CampaignFormComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: null,
          redirectIfRole: null,
          redirectIfNoRole: '/home',
          demo: true
        }

      },
      {
        path: 'success',
        component: SuccessComponent
      },
      {
        path: 'error',
        component: ErrorComponent
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {
        path: 'login',
        component: SignPageComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'any',
          redirectIfRole: '/home',
          redirectIfNoRole: null,
        }
      },
      {
        path: 'signup',
        component: SignPageComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'any',
          redirectIfRole: '/home',
          redirectIfNoRole: null,
        }
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'manage-profile',
        component: ManageProfileComponent,
        canActivate: [RoleBasedAuthGuard],
        data: {
          requiredRole: 'any',
          redirectIfRole: null,
          redirectIfNoRole: '/profile/login',
        }
      },
      {
        path: '**',
        redirectTo: '/home'
      }
    ]
  },
  {
    path: 'e-learning',
    component: ELearningComponent,
    children: [{
      path: 'demo',
      children: [
        {
          path: 'masterclasses',
          children: [
            {
              path: '',
              component: MasterclassesComponent,
              canActivate: [RoleBasedAuthGuard],
              data: {
                requiredRole: null,
                redirectIfRole: null,
                redirectIfNoRole: '/e-learning',
                demo: true
              }
            },
            {
              path: ':id',
              component: MasterclassesComponent,
              canActivate: [RoleBasedAuthGuard],
              data: {
                requiredRole: null,
                redirectIfRole: null,
                redirectIfNoRole: '/e-learning',
                demo: true
              }
            },
            {
              path: ':id/modules',
              children: [
                {
                  path: '',
                  component: ModulesComponent,
                  canActivate: [RoleBasedAuthGuard],
                  data: {
                    requiredRole: null,
                    redirectIfRole: null,
                    redirectIfNoRole: '/e-learning',
                    demo: true
                  }
                },
                {
                  path: ':id',
                  children: [
                    {
                      path: '**',
                      component: CourseComponent,
                      canActivate: [RoleBasedAuthGuard],
                      data: {
                        requiredRole: null,
                        redirectIfRole: null,
                        redirectIfNoRole: '/e-learning',
                        demo: true
                      }
                    }
                  ]
                },
              ]
            },
          ],
        },
        {
          path: '**',
          redirectTo: '/e-learning/demo/masterclasses'
        }
      ]
    },
    {
      path: '',
      children: [
        {
          path: 'masterclasses',
          children: [
            {
              path: '',
              component: MasterclassesComponent,
              canActivate: [RoleBasedAuthGuard],
              data: {
                requiredRole: 'any',
                redirectIfRole: null,
                redirectIfNoRole: '/home/demo',
              }
            },
            {
              path: ':id',
              component: MasterclassesComponent,
              canActivate: [RoleBasedAuthGuard],
              data: {
                requiredRole: 'any',
                redirectIfRole: null,
                redirectIfNoRole: '/home/demo',
              }
            },
            {
              path: ':id/modules',
              children: [
                {
                  path: '',
                  component: ModulesComponent,
                  canActivate: [RoleBasedAuthGuard],
                  data: {
                    requiredRole: 'any',
                    redirectIfRole: null,
                    redirectIfNoRole: '/home/demo',
                  }
                },
                {
                  path: ':id',
                  children: [
                    {
                      path: '**',
                      component: CourseComponent,
                      canActivate: [RoleBasedAuthGuard],
                      data: {
                        requiredRole: 'any',
                        redirectIfRole: null,
                        redirectIfNoRole: '/home/demo',
                      }
                    }
                  ]
                },
              ]
            },
          ],
        },
        {
          path: 'visualize/:id',
          children: [
            {
              path: 'masterclasses',
              children: [
                {
                  path: '',
                  component: MasterclassesComponent,
                  canActivate: [AngularFireAuthGuard],
                  data: { authGuardPipe: adminOnly }
                },
                {
                  path: ':id/modules',
                  children: [
                    {
                      path: '',
                      component: ModulesComponent,
                      canActivate: [AngularFireAuthGuard],
                      data: { authGuardPipe: adminOnly }
                    },
                    {
                      path: ':id',
                      children: [
                        {
                          path: '**',
                          component: CourseComponent,
                          canActivate: [AngularFireAuthGuard],
                          data: { authGuardPipe: adminOnly }
                        }
                      ]
                    },
                  ]
                },
              ],
            }
          ]
        },
        {
          path: 'success',
          component: SuccessComponent
        },
        {
          path: '**',
          redirectTo: '/e-learning/masterclasses'
        }
      ]
    }
    ]
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {

  constructor(private router: Router, private gtmService: GoogleTagManagerService) { 
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const pagePath = event.urlAfterRedirects;
      const pageReferrer = document.referrer;
      const pageURL = window.location.href;
      this.sendPageViewEvent(pagePath, pageURL, pageReferrer);
    });
  }

  sendPageViewEvent(pagePath: string, pageURL: string, pageReferrer: string): void {
    const gtmPageView = {
      event: "page_view",
      page_path: pagePath,
      page_hostname: "mymusicads.com",
      page_URL: pageURL,
      page_referrer: pageReferrer,
    };

    /* TRACKING: Send page_view 
    this.gtmService.pushTag(gtmPageView);
    */
  }
}