import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export type ICardColor = 'purple' | 'brick' | 'blue' | 'navy';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() imageName: string;
  @Input() name: string;
  @Input() description: string;
  @Input() href: string;
  @Input() bannerText = "";
  @Input() disabled = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  getImageUrl() {
    return `../../../../assets/images/${this.imageName}`;
  }
}
