import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FirebaseService, AuthProvider } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteAccountDialogComponent {
  message: string = '';
  password = '';
  provider: string;
  isProvider: boolean = false;

  constructor(public dialogRef: MatDialogRef<DeleteAccountDialogComponent>, private firebaseService: FirebaseService) {
    this.firebaseService.getProvider().then(provider => {
      this.provider = provider;

      if (this.provider === AuthProvider.Google)
        this.isProvider = true;
    })
   }

  deleteAccount(): void {
    this.message = '';
    this.firebaseService.deleteUser(this.password)
      .then(() => window.location.href = "/home")
      .catch((err) => {alert(err); this.message = 'Une erreur est survenue.'});
  }
}
