import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { IMasterclassVersion } from '../../../../../../../shared/src/interfaces';

@Component({
  selector: 'app-confirm-version-change-dialog',
  templateUrl: './confirm-version-change-dialog.component.html',
  styleUrls: ['./confirm-version-change-dialog.component.scss']
})
export class ConfirmVersionChangeDialogComponent {
  @Input() version: IMasterclassVersion;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { version: IMasterclassVersion }, public dialogRef: MatDialogRef<ConfirmVersionChangeDialogComponent>, private firebaseService: FirebaseService) { }

  changeVersion(saveActual: boolean): void {
    this.dialogRef.close(saveActual);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
