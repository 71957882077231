import { Component, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {
  selectedDateRange: DateRange<Date | null> = new DateRange(null, null);

  constructor(public dialogRef: MatDialogRef<CalendarDialogComponent>) { }

  ngOnInit(): void {
  }

  resetDates(): void {
    this.selectedDateRange = new DateRange(null, null);
  }

  onSelectedDatesChange(date: Date) {
    if (this.selectedDateRange && this.selectedDateRange.start && date >= this.selectedDateRange.start && !this.selectedDateRange.end) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
      this.dialogRef.close(this.selectedDateRange);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }
}
