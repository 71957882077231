<span class="title">Rentrez l'adresse e-mail de l'utilisateur à proumouvoir.</span>
<input [(ngModel)]="email" />
<mat-form-field appearance="fill">
  <mat-label>Rôle</mat-label>
  <mat-select [(ngModel)]="selectedRole">
    <mat-option [value]="UserRoleEnum.SUBSCRIBER">Abonné</mat-option>
    <ng-container *ngIf="data.currentUser.role === UserRoleEnum.ADMIN">
      <mat-option [value]="UserRoleEnum.COACH">Coach</mat-option>
      <mat-option [value]="UserRoleEnum.TRAINER">Formateur</mat-option>
      <mat-option *ngIf="isUserTheBoss()" [value]="UserRoleEnum.ADMIN">Admin</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
<app-button text="Valider" (onClick)="addRole(email)" theme="danger" [disabled]="!email || loading"></app-button>
