import { IOurInterventions } from "../models/OurInterventions"

export const ourInterventions: IOurInterventions[] = [
  {
    title: `Presse`,
    description: `Créez un dossier presse, démarchez les bonnes personnes, adoptez la stratégie éditoriale la plus adaptée pour vous.`,
    icon: 'mic'
  },
  {
    title: `Direction artistique`,
    description: `Guidez votre créativité, communiquez avec votre public, différenciez votre travail en travaillant votre identité visuelle et musicale avec des experts reconnus.`,
    icon: 'art'
  },
  {
    title: `Juridique et fiscalité`,
    description: `Créez la bonne structure, signez les bons contrats, protégez vos droits avec des avocats et experts comptables spécialisés dans le secteur musical. `,
    icon: 'books'
  },
  {
    title: `Développement de projet`,
    description: `Garantissez une gestion efficace de votre projet, une coordination entre les différents acteurs impliqués (artistes, producteurs, ingénieurs du son...) et une stratégie de promotion et de distribution planifiée.`,
    icon: 'calendar'
  },
  {
    title: `Vidéos`,
    description: `Trouvez des concepts adaptés à votre art, créez des vidéos dynamiques et performantes, adoptez la bonne stratégie pour chaque réseau social.`,
    icon: 'video'
  },
  {
    title: `Subventions et collecte des droits`,
    description: `Définissez les subventions que vous pouvez décrocher, montez les dossiers, déposez vos œuvres et collectez vos droits.`,
    icon: 'waves'
  }
]