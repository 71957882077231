import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUser } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoachingService {
  constructor(private http: HttpClient) { }

  rootURL = '/api';

  getCoachs(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.rootURL}/coaching/coachs`);
  }

  getOtherProfiles(coachId: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.rootURL}/coaching/getOtherProfiles/${coachId}`);
  }

  getCoach(id: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.rootURL}/coaching/coach/${id}`);
  }
}