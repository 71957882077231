<ng-container *ngIf="!loading && selectedCourse && masterclassVersion$ | async; else loader">
  <app-button class="back-button" theme="info" text="<< Retour" size="xs" [outlined]="true" (onClick)="navigateToModules()"></app-button>
  <button *ngIf="innerWidth < 768" class="menu-button" (click)="sidenav.toggle()">
    <img [src]="'../../../../assets/icons/menu.svg'" />
  </button>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav" [mode]="innerWidth > 768 ? 'side' : 'over'" [opened]="innerWidth > 768">
      <mat-nav-list (click)="innerWidth < 768 && sidenav.toggle()">
        <span class="title">Chapitres</span>
        <div class="course-content">
          <div *ngFor="let chapter of chapters">
            <div class="chapter">
              <svg height="36" width="30">
                <circle cx="15" cy="18" r="12" stroke="#8c64fa" stroke-width="3" [attr.fill]="chapterCompletion(chapter) === 100 ? '#8c64fa' : '#000c00'" />
              </svg>
              <a
                class="chapter-label"
                mat-list-item
                [ngClass]="{ 'chapter-completed': chapterCompletion(chapter) === 100 }"
                [routerLink]="baseUrl + '/modules/' + module.id + '/' + chapter.id + '/' + chapter.courses[0].id"
                (click)="selectCourse(chapter.id, chapter.courses[0].id)"
                ><span>{{ chapter.name }}</span
                ><span>{{ chapterCompletion(chapter) }}%</span></a
              >
            </div>

            <div class="course" *ngFor="let course of chapter.courses">
              <svg height="30" width="30">
                <line
                  x1="15"
                  y1="-20
              "
                  x2="15"
                  y2="62"
                  style="stroke: #8c64fa; stroke-width: 2"
                />
              </svg>
              <img
                class="course-icon"
                [ngClass]="course.isCompleted ? 'course-icon-validated' : null"
                [src]="course.docInfo.type === 'video' ? '../../../../assets/icons/video.svg' : '../../../../assets/icons/document.svg'"
                alt="Exit arrow"
              />
              <a
                class="course-label"
                mat-list-item
                [ngClass]="{ 'course-completed': course.isCompleted, 'course-selected': selectedCourse.id === course.id }"
                [routerLink]="baseUrl + '/modules/' + module.id + '/' + chapter.id + '/' + course.id"
                (click)="selectCourse(chapter.id, course.id)"
                >{{ course.name }}</a
              >
            </div>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sidenav-content">
        <app-video *ngIf="selectedCourse.docInfo.type === 'video'" [videoInfo]="selectedCourse.docInfo" (goToNextCourse)="goToNextCourse()" [isSelectedCourseLast]="isSelectedCourseLast"></app-video>
        <app-pdf *ngIf="selectedCourse.docInfo.type === 'pdf'" [pdfInfo]="selectedCourse.docInfo" (goToNextCourse)="goToNextCourse()" [isSelectedCourseLast]="isSelectedCourseLast"></app-pdf>
        <app-mcq *ngIf="selectedCourse.mcq?.length" [mcq]="selectedCourse.mcq" (goToNextCourse)="goToNextCourse()" [isSelectedCourseLast]="isSelectedCourseLast"></app-mcq>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
