import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CoachsComponent } from './coachs/coachs.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CoachCardComponent } from './coach-card/coach-card.component';
import { SubTitleComponent } from './coachs/sub-title/sub-title.component';
import { FaqComponent } from './coachs/faq/faq.component';
import { QuestionComponent } from './coachs/faq/question/question.component';
import { CoachDetailsComponent } from './coach-details/coach-details.component';
import { CoachMainCardComponent } from './coach-details/coach-presentation/coach-main-card.component';
import { ContactUsComponent } from './coachs/contact-us/contact-us.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderCoachingComponent } from './header/header-coaching.component';
import { NgxSplideModule } from 'ngx-splide';
import { OurInterventionsComponent } from './coachs/our-interventions/our-interventions.component'
import { InterventionCardComponent } from './coachs/our-interventions/intervention-card/intervention-card.component';
import { HowItWorksCardComponent } from './coachs/how-it-works/how-it-works-card/how-it-works-card.component';
import { HowItWorksComponent } from './coachs/how-it-works/how-it-works.component';
import { TitleComponent } from './coachs/title/title.component'
import { CoachingComponent } from './coaching.component';
import { Carousel } from './coachs/carousel/carousel.component';
import { ReferenceCardComponent } from './coach-details/reference-card/reference-card.component';

@NgModule({
  declarations: [
    CoachingComponent,
    CoachDetailsComponent,
    CoachsComponent,
    TitleComponent,
    SubTitleComponent,
    CoachCardComponent,
    HowItWorksComponent,
    HowItWorksCardComponent,
    OurInterventionsComponent,
    InterventionCardComponent,
    ReferenceCardComponent,
    FaqComponent,
    ContactUsComponent,
    QuestionComponent,
    HeaderCoachingComponent,
    CoachMainCardComponent,
    Carousel
  ],
  imports: [CommonModule, SharedModule, AppRoutingModule, MatSidenavModule, NgxSplideModule],
  providers: [

  ],
  exports: [CoachingComponent, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoachingModule { }
