import { ISelectableFormFieldImage } from '../../models/SelectableFormFieldImage';

export const platforms: ISelectableFormFieldImage[] = [
  {
    image: {
      value: 'spotify',
      name: 'Spotify',
      imageUrl: 'logos/spotify.svg'
    },
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['project']
      }
    ]
  },
  {
    image: {
      value: 'youtube',
      name: 'Youtube',
      imageUrl: 'logos/youtube.svg'
    },
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip']
      }
    ]
  },
  {
    image: {
      value: 'instagram',
      name: 'Instagram',
      imageUrl: 'logos/instagram.svg'
    },
    conditions: []
  },
  {
    image: {
      value: 'facebook',
      name: 'Facebook',
      imageUrl: 'logos/facebook.svg'
    },
    conditions: []
  },
  {
    image: {
      value: 'snapchat',
      name: 'Snapchat',
      imageUrl: 'logos/snapchat.svg'
    },
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip', 'project']
      }
    ]
  },
  {
    image: {
      value: 'tiktok',
      name: 'Tiktok',
      imageUrl: 'logos/tiktok.svg'
    },
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip', 'project', 'publication']
      }
    ]
  }
];
