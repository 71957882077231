<div #datePickerContainer class="datepicker">
  <div class="datepicker-button" [ngClass]="selectedDateRange?.start ? 'datepicker-button-completed' : ''" (click)="displayCalendar()">
    <ng-container *ngIf="!selectedDateRange?.start; else dateInterval">Pas de période sélectionnée</ng-container>
    <ng-template #dateInterval>
      <span>{{ selectedDateRange.start | date: 'dd/MM/yyyy':'fr-FR' }}</span>
      <ng-container *ngIf="!areDatesEqual(selectedDateRange.start, selectedDateRange.end)">
        <span> - </span>
        <span>{{ selectedDateRange.end ? (selectedDateRange.end | date: 'dd/MM/yyyy':'fr-FR') : '...' }}</span>
      </ng-container>
    </ng-template>
  </div>
  <ng-container *ngIf="isOpen">
  <div class="calendar">
      <mat-calendar (selectedChange)="onSelectedDatesChange($event)" [selected]="selectedDateRange"> </mat-calendar>
      <button class="calendar-button" (click)="resetDates(); isOpen = !isOpen">Maximum</button>
    </div>
  </ng-container>
</div>
