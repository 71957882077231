<div [ngClass]="'header header-' + theme">
  <div *ngIf="theme === 'e-learning' && innerWidth < 768" class="header-empty-div" [ngStyle]="{ display: centerLogo ? 'block' : 'none' }"></div>
  <div class="logo">
    <a href="/home" target="_parent">
      <img src="../../../../assets/images/MMA_LOGO_JAUNE_RVB_couronne.svg" />
    </a>
  </div>
  <span class="demo-mode" *ngIf="demo">MODE DEMO</span>
  <div class="links-and-profile">
    <app-button *ngIf="theme === 'e-learning'" theme="coaching" text="Demander un coaching" (click)="openCoachingDialog()"></app-button>
    <a class="redirection-link" href="/home" target="_parent">Mon espace</a>
    <app-profile-minified></app-profile-minified>
  </div>
</div>
