import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterclassService } from './masterclass.service';
import { SubSink } from 'subsink';
import {
  CallToActionDialogComponent
} from '../../shared/call-to-action-dialog/call-to-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IELearningMetadata, IMasterclass, IMasterclassVersion, IModule, IUser } from '@shared';
import { Observable, combineLatest, firstValueFrom, forkJoin, switchMap, tap } from 'rxjs';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { environment } from 'src/environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import { NewVersionFormDialogComponent } from '../../back-office/e-learning/new-version-form-dialog/new-version-form-dialog.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Location } from '@angular/common';
import Stripe from 'stripe';
import { SuccessService } from '../../create-campaign/success/success.service';

@Component({
  selector: 'app-masterclasses',
  templateUrl: './masterclasses.component.html',
  styleUrls: ['./masterclasses.component.scss']
})
export class MasterclassesComponent implements OnInit, OnDestroy {

  masterclasses: IMasterclass[] = [];
  masterclassVersion$: Observable<IMasterclassVersion>;
  masterclassVersion: IMasterclassVersion;

  selectedMasterclass: IMasterclass;
  isMasterclassOverviewOpen = false;

  currentUser: IUser;

  demo = false;
  versionId = '';

  loadingProgress = true;

  stripePromise = loadStripe(environment.stripe_key);
  loadingStripe: boolean = false;

  subs: SubSink = new SubSink();

  innerWidth = window.innerWidth;

  session: Stripe.Checkout.Session;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location, private masterclassService: MasterclassService, private firebaseService: FirebaseService, private dialog: MatDialog, private gtmService: GoogleTagManagerService,  private successService: SuccessService) {
    const urlSplit = this.router.url.split('/');

    if (urlSplit.includes('visualize')) {
      this.versionId = urlSplit[urlSplit.indexOf('visualize') + 1];
      this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(this.versionId);
      this.subs.sink = this.masterclassVersion$.subscribe((masterclassVersion) => {
        this.masterclassVersion = masterclassVersion;
        this.masterclasses = masterclassVersion.masterclasses;
        this.loadMasterclassesProgress();
      });
    } else {
      this.subs.sink = combineLatest([this.firebaseService.getRemoteConfig(), this.masterclassService.getObtainedMasterclasses$()]).pipe(switchMap(([remoteConfig, obtainedMasterclasses]) => {

        const selectedELearningVersion = remoteConfig.parameters['selectedELearningVersion'].defaultValue.value;
        this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(selectedELearningVersion).pipe(tap((mcv) => {
          if (!(mcv.id === remoteConfig.parameters['selectedELearningVersion'].defaultValue.value)) {
            this.router.navigate(['home']);
          }
          this.masterclasses = mcv.masterclasses;
          this.masterclasses.forEach((mc) => {
            if (obtainedMasterclasses.includes(mc.id)) {
              mc.isObtained = true;
            }
          });
          this.loadMasterclassesProgress();
        }));
        return this.masterclassVersion$;
      })).subscribe();
    }

    this.subs.sink = activatedRoute.url.subscribe(() => {
      this.demo = activatedRoute.snapshot.data.demo;
    });
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await firstValueFrom(this.firebaseService.getCurrentUserProperties());
  }

  loadMasterclassesProgress(): void {
    const urlSplit = this.router.url.split('/');
    if (urlSplit.indexOf('masterclasses' + 1)) {
      const selectedMasterclassId = urlSplit[urlSplit.indexOf('masterclasses') + 1];
      if (this.masterclasses.map((mc) => mc.id).includes(selectedMasterclassId)) {
        this.selectedMasterclass = this.masterclasses.find((mc) => mc.id === selectedMasterclassId);
      } else {
        this.selectedMasterclass = this.masterclasses[0];
        const newUrl = this.router.createUrlTree([this.selectedMasterclass.id], { relativeTo: this.activatedRoute }).toString();
        this.location.go(newUrl);
      }
    }
    let loadedMasterclasses = 0;
    this.masterclasses.forEach((masterclass) => {
      masterclass.progress = 0;
      this.masterclassService.getCompletedCoursesProgress$(masterclass).subscribe((progress) => {
        if (progress && masterclass.isObtained) {
          masterclass.progress = progress;
          loadedMasterclasses++;
        }
      });
    });
    setTimeout(() => this.loadingProgress = false, 1000)
  }

  onMasterclassClick(masterclass: IMasterclass): void {

    const gtmClickCourse = {
      event: "click_course",
      detail_click: "validate",
      themes: masterclass.name
    };

    this.gtmService.pushTag(gtmClickCourse);

    if (this.demo && !masterclass.isComingSoon && !masterclass.modules.find((module: IModule) => module.isAvailableInDemo)) {
      this.openSubscribeCTADialog();
    } else if (!masterclass.isBuyable && !masterclass.isObtained) {
      this.openContactUsMasterclassDialog();
    } else {
      this.router.navigate([masterclass.id, 'modules'], { relativeTo: this.activatedRoute.parent });
    }
  }

  presentationClicked(masterclass: IMasterclass): void {
    const gtmClickCourse = {
      event: "click_course",
      detail_click: "view_presentation",
      themes: masterclass.name
    };

    /* TRACKING: Send click_course view_presentation 
    this.gtmService.pushTag(gtmClickCourse);
    */

    this.router.navigate([masterclass.id, 'modules'], { relativeTo: this.activatedRoute.parent });
  }

  async buyMasterclass(masterclass: IMasterclass): Promise<void> {
    const gtmClickCourse = {
      event: "click_course",
      detail_click: "validate",
      themes: masterclass.name
    };

    this.gtmService.pushTag(gtmClickCourse);
  
    if (this.currentUser) {
      this.loadingStripe = true;
      const stripe = await this.stripePromise;
      const user = await firstValueFrom(this.firebaseService.getCurrentUserProperties());

      const metadata: IELearningMetadata = {
        userId: user.id,
        masterclassId: masterclass.id,
        masterclassName: masterclass.name
      }

      const sessionId = await firstValueFrom(this.masterclassService.createCheckoutSession(metadata));

      this.session = await firstValueFrom(this.successService.getCheckoutSession(sessionId));

      const gtmTagAddToCart = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: this.session.amount_total / 100,
          items: [{
            item_id: masterclass.id,
            item_name: masterclass.name,
            item_category: "Formations",
            quantity: 1,
          }]
        }
      };

      const gtmTagBeginCheckout = {
        event: 'begin_checkout',
        ecommerce: {
          currency: 'EUR',
          value: this.session.amount_total / 100,
          items: [{
            item_id: masterclass.id,
            item_name: masterclass.name,
            item_category: "Formations",
            quantity: 1,
          }]
        }
      };

      this.gtmService.pushTag(gtmTagAddToCart);
      this.gtmService.pushTag(gtmTagBeginCheckout);

      const { error } = await stripe!.redirectToCheckout({
        sessionId: sessionId
      });
      if (error) {
        console.error(error);
      }
    }
    else {
      this.openSubscribeCTADialog();
    }
  }

  onThumbnailClick(masterclass: IMasterclass): void {
    const gtmClickCourse = {
      event: "click_course",
      detail_click: "themes_cards",
      themes: masterclass.name
    };

    /* TRACKING: Send click_course theme_cards 
    this.gtmService.pushTag(gtmClickCourse);
    */

    this.selectedMasterclass = masterclass;
    const newUrl = this.router.createUrlTree([this.selectedMasterclass.id], { relativeTo: this.activatedRoute.parent }).toString();
    this.location.go(newUrl)
    this.router.url;
    this.isMasterclassOverviewOpen = true;
  }

  closeMasterclassOverview(): void {
    this.isMasterclassOverviewOpen = false;
  }

  openSubscribeCTADialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: { title: 'Pas encore parmi nous ?', text: "Créez vous un compte gratuitement ou connectez-vous afin de souscrire à nos formations !", actionText: "S'inscrire >>", actionUrl: '/profile/signup', redirectionAfterAction: '/e-learning' }
    });
  }

  openContactUsMasterclassDialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: { title: 'Intéressé par la formation ?', text: "N'attendez plus et contactez-nous pour demander votre accès et booster votre visibilité !", actionText: 'Nous contacter >>', actionExtUrl: 'https://mymusicads.com/nous-contacter', redirectionAfterAction: '/e-learning', hideLogin: !!this.currentUser }
    });
  }

  validateVersionClicked(): void {
    if (!this.masterclassVersion.name) {
      this.openNewVersionDialog();
    } else {
      this.subs.sink = this.validateVersion$(this.masterclassVersion).subscribe();
    }
  }

  openNewVersionDialog(): void {
    this.dialog.open(NewVersionFormDialogComponent, {
      width: '700px',
      panelClass: 'new-version-dialog',
      data: { masterclasses: this.masterclasses }
    }).afterClosed().subscribe((version: IMasterclassVersion) => {
      if (version) {
        this.validateVersion$(version).subscribe();
      }
    });
  }

  validateVersion$(version: IMasterclassVersion): Observable<string> {
    return this.masterclassService.updateMasterclassVersion({ ...version, isTemporary: false }).pipe(switchMap(() => {
      return this.firebaseService.selectNewELearningVersion(version.id).pipe(tap(() => {
        alert('Version validée avec succès !');
        this.router.navigate(['..', '..', '..']);
      }))
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
