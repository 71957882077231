<div [ngClass]="['section-coaching', categoryTitle]" #sectionCoaching
  [class.notEnoughCardsMode]="isEnoughCardsMode$ | async" (mousemove)="onMouseMove($event)">
  <div class="top-section-coaching" #wrapperCarousel>
    <div class="wrapper-title-coaching" [ngStyle]="{'margin-left': this.uniqueSense ? (windowWidth >= 768 ? '24px' : 0) : this.cardWidth + this.DEFAULT_GAP + 'px'}">
      <span class="section-coaching-title">{{categoryTitle}}</span>
      <div class="buttons" *ngIf="!isEnoughCardsMode$.value" [ngStyle]="{'margin-right': this.uniqueSense ? this.DEFAULT_GAP * 2 + 'px' : this.cardWidth + this.DEFAULT_GAP + 'px'}">
        <img src="../../../../assets/icons/arrow-left-orange.svg" (click)="goPrev()" />
        <img src="../../../../assets/icons/arrow-right-orange.svg" (click)="goNext()" />
      </div>
    </div>
  </div>
  <splide [options]="options" #splide *ngIf="!isEnoughCardsMode$.value" (onDrag)="removeFirstOrLastClass()"
    (onMoved)="calculateVisibleCards()">
    <splide-slide *ngFor="let card of cards">
      <ng-container *ngTemplateOutlet="templateRef1; context: {$implicit: card}"></ng-container>
    </splide-slide>
  </splide>
  <div class="wrapper-reduced-cards" *ngIf="isEnoughCardsMode$.value" [ngStyle]="{'margin-left': this.uniqueSense ? '24px' : this.cardWidth + this.DEFAULT_GAP + 'px'}">
    <div class="wrapper-title-coaching-spacing">
      <div *ngFor="let card of cards">
        <ng-container *ngTemplateOutlet="templateRef2; context: {$implicit: card}"></ng-container>
      </div>
    </div>
  </div>
</div>