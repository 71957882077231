<div
  class="selectable-image-form-field"
  [ngClass]="{ 'selectable-image-form-field--border': hasBorder, 'selectable-image-form-field--selected': selected, 'selectable-image-form-field--hovered': hovered }"
  (click)="selectedChange.emit(formFieldImage.value)"
  (mouseenter)="onHover(true)"
  (mouseleave)="onHover(false)"
>
  <div class="content" [ngStyle]="{ opacity: selected ? '0.5' : '1' }">
    <img class="image" [src]="'../../../../../assets/' + formFieldImage.imageUrl" [alt]="formFieldImage.name + ' image'" />
    <span class="name">{{ formFieldImage.name }}</span>
  </div>
  <img [ngStyle]="{ opacity: selected ? '1' : '0' }" class="selected-icon" alt="selected" src="../../../../../assets/icons/check.svg" />
</div>
