<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <app-header-coaching [mobile]="true" [sidenav]="sidenav" [redirectUrl]="redirectUrl"></app-header-coaching>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="body">
      <app-header-coaching [mobile]="false" [sidenav]="sidenav" [redirectUrl]="redirectUrl"></app-header-coaching>
      <div class="page" *ngIf="coach; else loader">
        <div *ngIf="!isMobile; else mobileMode" class="photo-container">
          <div class="responsive-image"></div>
          <div class="multicolor"></div>
          <div class="multicolor blur"></div>
        </div>
        <ng-template #mobileMode>
          <div class="photo-container">
            <div class="responsive-image"></div>
            <img class="coach-image" [src]="coach.photoUrl ? coach.photoUrl : '../../../../assets/images/coaching/default-coach-image.png'" alt="Coach" />
            <div class="multicolor"></div>
            <div class="multicolor blur"></div>
          </div>
          <app-coach-main-card *ngIf="coach?.coachInfo?.sessions" [coach]="coach" (scrollToCalendlyEvent)="scrollToCalendly()" [mobileMode]="isMobile"></app-coach-main-card>
        </ng-template>
        <div class="page-content">
          <app-coach-main-card *ngIf="sessionsWithPrice && !isMobile" [coach]="coach" (scrollToCalendlyEvent)="scrollToCalendly()" [mobileMode]="isMobile"></app-coach-main-card>
          <div class="right-content">
            <div class="description" *ngIf="coach?.coachInfo?.description?.content">
              <span class="subtitle"
                >À propos de <span>{{ coach?.firstName }} {{ coach?.lastName }}</span></span
              >
              <p #descriptionText>
                <span class="content">{{ coach?.coachInfo?.description?.content }}</span>
                <span *ngIf="coach?.coachInfo?.description?.bulletsPoints?.length > 0">Découvrez comment mes services peuvent :</span>
                <span *ngIf="coach?.coachInfo?.description?.bulletsPoints?.length > 0" class="bulletPoint">
                  <img src="../../../../assets/icons/bullet-point.svg" />
                  {{ coach?.coachInfo?.description?.bulletsPoints[0] }}
                </span>
                <span *ngFor="let bulletPoint of coach?.coachInfo?.description?.bulletsPoints; let i = index" class="bulletPoint">
                  <ng-container *ngIf="readMore && i > 0">
                    <img src="../../../../assets/icons/bullet-point.svg" />
                    {{ bulletPoint }}
                  </ng-container>
                </span>
                <span *ngIf="coach?.coachInfo?.description?.catchphrase !== '' && readMore" class="catchphrase">
                  {{ coach?.coachInfo?.description?.catchphrase }}
                </span>
              </p>
              <div *ngIf="coach?.coachInfo?.description?.catchphrase" (click)="toggleReadMore()" class="readmore-button">
                <span>Lire {{ readMore ? 'moins' : 'plus' }}</span>
              </div>
            </div>
            <div class="references" *ngIf="coach?.coachInfo?.references?.length > 0">
              <app-carousel [templateRef1]="template1" [templateRef2]="template2" [cards]="coach?.coachInfo?.references" [categoryTitle]="'Mes références'" [uniqueSense]="true" [cardWidth]="320">
                <ng-template #template1 let-card>
                  <app-reference-card [reference]="card"></app-reference-card>
                </ng-template>
                <ng-template #template2 let-card>
                  <app-reference-card [reference]="card"></app-reference-card>
                </ng-template>
              </app-carousel>
            </div>
            <div class="calendly" #calendly>
              <span *ngIf="sessionsWithPrice?.length > 0" class="subtitle"><span>Réservez</span> votre appel de découverte gratuitement</span>
              <ng-container *ngIf="user || sessionsWithPrice?.length > 0; else notConnected">
                <ng-container>
                  <div class="book-buttons">
                    <ng-container *ngFor="let session of sessionsWithPrice">
                      <div class="button" (click)="onSelectSession(session.schedulingUrl)" [class.disabled]="selectedSessionScheduling !== null && selectedSessionScheduling !== session.schedulingUrl">
                        <span>{{ formatDuration(session.duration) }}</span>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="schedulingUrl" class="calendly-inline-widget" [attr.data-url]="schedulingUrl"></div>
                </ng-container>
              </ng-container>
              <ng-template #notConnected>
                <div *ngIf="sessionsWithPrice?.length > 0" class="not-connected">
                  <div>
                    <span>Vous devez être connecté pour accéder aux disponibilités</span>
                    <span (click)="navigate('/profile/login')">Se connecter</span>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="other-profiles" *ngIf="otherProfiles && otherProfiles.length > 0">
              <app-carousel
                [templateRef1]="template1"
                [templateRef2]="template2"
                [cards]="otherProfiles"
                [categoryTitle]="'Ces profils pourraient également vous intéresser'"
                [uniqueSense]="true"
                [cardWidth]="216"
              >
                <ng-template #template1 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
                <ng-template #template2 let-card>
                  <app-coach-card [coach]="card"></app-coach-card>
                </ng-template>
              </app-carousel>
            </div>
          </div>
        </div>
        <app-contact-us></app-contact-us>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
