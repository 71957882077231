<div class="faq-body">
  <app-sub-title [title]="'Foire aux '" [titleBoldPart]="'questions'"></app-sub-title>

  <div class="faq-questions-container">
    <ng-template ngFor let-item [ngForOf]="items" let-i="index">
      <app-question
        [question]="item.question"
        [answers]="item.answers"
      ></app-question>
    </ng-template>
  </div>

</div>