import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  demo = false;

  constructor(route: ActivatedRoute) {
    this.subs.sink = route.url.subscribe(() => {
      this.demo = route.snapshot.firstChild.data.demo;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
