<form class="form" [formGroup]="newVersionForm">
  <div class="form-element">
    <label for="name">Nom de la version <span class="mandatory-symbol">*</span></label>
    <input id="name" type="text" formControlName="name" />
  </div>
</form>
<div class="element-form-buttons">
  <app-button [outlined]="true" text="Annuler" (click)="dialogRef.close()"></app-button>
  <app-button [disabled]="!newVersionForm.valid" text="Sauvegarder" (click)="saveVersion()"></app-button>
</div>
