<div class="body-how-it-works">
  <app-sub-title [title]="'Et donc, '" [titleBoldPart]="'comment ça marche?'"
    [subtitle]="'Un fonctionnement en trois étapes'"></app-sub-title>
  <div class="box-containers">
    <ng-container *ngIf="!mobileMode; else mobileView">
      <ng-container *ngIf="showCard">
        <app-how-it-works-card [title]="items[step].title" [description]="items[step].description" [index]="step + 1"
          (increment)="stepIncrement()" (decrement)="stepDecrement()"></app-how-it-works-card>
      </ng-container>
    </ng-container>
    <ng-template #mobileView>
      <ng-template ngFor let-item [ngForOf]="items" let-i="index">
        <app-how-it-works-card
          [title]="item.title"
          [description]="item.description"
          [index]="i + 1"
        ></app-how-it-works-card>
      </ng-template>
    </ng-template>
  </div>
</div>