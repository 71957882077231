import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { SubSink } from 'subsink';
import { CallToActionDialogComponent } from '../shared/call-to-action-dialog/call-to-action-dialog.component';
import { mmaProducts } from './json/mma-products';
import { MmaProduct } from './models/MmaProduct';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  mmaProducts = mmaProducts;
  innerWidth = window.innerWidth;
  isUserConnected = null;
  selectedProduct: MmaProduct = null;

  demo = false;

  subs = new SubSink();

  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private firebaseService: FirebaseService, private gtmService: GoogleTagManagerService) {
    this.subs.sink = this.firebaseService.getCurrentUserProperties().subscribe((user) => this.isUserConnected = !!user);
  }

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  selectProduct(mmaProduct: MmaProduct) {
    this.selectedProduct = mmaProduct;
  }

  async ngOnInit(): Promise<void> {
    this.demo = this.route.snapshot.data.demo;
  }

  navigate(mmaProduct) {
    const url =  mmaProduct.href + (this.isDemoMode(mmaProduct) ? '/demo' : '')
    this.router.navigate([url]);
    const gtmTagClickCard = {
      event: 'click_cards',
      detail_click: mmaProduct.trackingName
    };
    /* TRACKING: Send click_cards 

    this.gtmService.pushTag(gtmTagClickCard);
    */
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  openContactUsDemoDialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: { title: 'Vous avez aimé la démo ?', text: "N'attendez plus et contactez-nous pour demander votre accès et booster votre visibilité !", actionText: 'Nous contacter >>', actionUrl: 'https://mymusicads.com/nous-contacter' }
    });
  }
  
  isDemoMode(mmaProduct: MmaProduct) {
    return this.demo && mmaProduct?.name !== 'Coaching'
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
