import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormFieldImage } from 'src/app/modules/create-campaign/models/SelectableFormFieldImage';

@Component({
  selector: 'app-selectable-form-field-image',
  templateUrl: './selectable-form-field-image.component.html',
  styleUrls: ['./selectable-form-field-image.component.scss']
})
export class SelectableFormFieldImageComponent implements OnInit {
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<string>();

  @Input() hasBorder = true;

  @Input() formFieldImage: IFormFieldImage;

  hovered = false;

  constructor() {}

  ngOnInit(): void {}

  onHover(isHovered: boolean): void {
    this.hovered = isHovered;
  }
}
