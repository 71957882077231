<div class="confirm-version-change">
  <span class="title">Changer de version</span>
  <div class="texts">
    <span>Vous êtes sur le point d'ouvrir la version du {{ data.version.creationDate | date: 'dd/MM/yyyy HH:mm':'fr-FR' }}</span>
    <br />
    <span>Souhaitez-vous sauvegarder la version actuelle ?</span>
  </div>
  <div class="buttons">
    <app-button size="sm" [outlined]="true" text="Annuler" (onClick)="closeDialog()"></app-button>
    <app-button size="sm" theme="danger" text="Non" (onClick)="changeVersion(false)"></app-button>
    <app-button size="sm" text="Oui" (onClick)="changeVersion(true)"></app-button>
  </div>
</div>
