<div *ngIf="!loading; else loader" class="success">
  <span class="title">{{ stripeSessionId ? 'Paiement validé !' : 'Commande confirmée !' }}</span>
  <span class="description">{{
    stripeSessionId
      ? 'Merci pour votre commande ! Un mail récapitulatif vous sera envoyé très prochainement.'
      : 'Votre demande a bien été prise en charge ! Un expert MMA lancera la campagne ou se rapprochera de vous très prochainement.'
  }}</span>
  <div class="buttons">
    <app-button text="Revenir à l'accueil" theme="secondary" href="/home" target="_parent"></app-button>
    <app-button text="Revenir à la page précédente" (onClick)="redirect()"></app-button>
  </div>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
