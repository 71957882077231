import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IStepHeader } from '../../models/StepHeader';
import { stepHeaders } from '../items-lists/stepHeaders';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent implements OnChanges {
  @Input() name: string;

  stepHeader: IStepHeader;

  constructor() {}

  ngOnChanges(): void {
    this.stepHeader = stepHeaders.find((stepHeader) => stepHeader.name === this.name) ?? stepHeaders[0];
  }
}
