import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import Stripe from 'stripe';
import { SuccessService } from './success.service';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Location } from '@angular/common';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  loading: boolean = false;
  stripeSessionId: string = this.route.snapshot.queryParams.id;
  session: Stripe.Checkout.Session;
  redirectionUrl: string = "home";

  constructor(private route: ActivatedRoute, private router: Router, private successService: SuccessService, private firebaseService: FirebaseService, private gtmService: GoogleTagManagerService, private location: Location) {
  }

  async ngOnInit(): Promise<void> {
    if ((this.location.getState() as any).redirectTo) {
      this.redirectionUrl = (this.location.getState() as any).redirectTo;
    }
    if (this.stripeSessionId) {
      this.loading = true;
      try {
        this.session = await firstValueFrom(this.successService.getCheckoutSession(this.stripeSessionId));
        await this.firebaseService
          .updateObtainedMasterclass(this.session.metadata.userId, this.session.metadata.masterclassId).catch((error) => {
            console.error(error);
            this.router.navigate(['/error']);
          });
        const gtmTagPurchase = {
          event: 'purchase',
          ecommerce: {
            transaction_id: this.stripeSessionId,
            currency: 'EUR',
            value: this.session.amount_total / 100,
            items: [{
              item_id: this.session.metadata.itemId,
              item_name: this.session.metadata.itemName,
              item_category: 'Formations',
              price: this.session.amount_total / 100,
              quantity: 1,
            }]
          }
        };

        this.gtmService.pushTag(gtmTagPurchase);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.router.navigate(['/error']);
      }
    }
  }

  redirect(): void {
    if (this.stripeSessionId) {
      this.router.navigate([this.session.metadata.redirectionUrl])
    } else {
      this.router.navigate([this.redirectionUrl]);
    }
  }
}
