export const categoriesMapping = {
  //Double categorie, temporaire V1
  // V2
  'Attachés de presse': ['Dossier presse', 'Prospection', 'Stratégie éditoriale'],
  'Chefs de projet': ['Gestion de projet', 'Stratégie de sortie', 'Stratégie de communication', 'Développement'],
  'Directeurs artistiques': ['Identité visuelle', 'Identité musicale', 'Direction artistique'],
  'Créateurs vidéos': ['Montage vidéo', 'Tiktok', 'Instagram', 'YouTube', 'Stratégie de contenus', 'VFX'],
  'Community managers': ['Stratégie marketing', 'Rédaction', 'Publicité en ligne', 'Tiktok', 'Instagram', 'Meta', 'YouTube', 'Streamings', 'Stratégie influenceur'],
  'Graphistes': ['Covers', 'Logos', 'Typos'],
  'Avocats': ['Contrats', 'Création de société', 'Déclaration des revenus', 'Gestion des droits', 'Fiscalité'],
  'Experts-comptables': ['Contrats', 'Création de société', 'Déclaration des revenus', 'Gestion des droits', 'Fiscalité'],
  'Subventions et collecte de droits': ['Subventions', 'Copyright', 'Dépôt des œuvres', 'Collecte de droits'],
  'Beatmakers': ['FL Studio', 'Ableton', 'Logic', 'Cubase'],
  'Ingénieurs sons': ['Rec', 'Mixe', 'Master'],
};

export const categoriesMappingPlurialSingular = {
  //Double categorie, temporaire V1
  // V2
  'Attachés de presse': 'Attaché de presse',
  'Chefs de projet': 'Chef de projet',
  'Directeurs artistiques': 'Directeur artistique',
  'Créateurs vidéos': 'Créateur vidéos',
  'Community managers': 'Community manager',
  'Graphistes': 'Graphiste',
  'Avocats': 'Avocat',
  'Experts-comptables': 'Expert-comptable',
  'Subventions et collecte de droits': 'Subventions et collecte de droits',
  'Beatmakers': 'Beatmaker',
  'Ingénieurs sons': 'Ingénieur son',
};
export const categoriesMappingGroup = {
  'Beatmakers et ingénieurs sons': ['FL Studio', 'Ableton', 'Logic', 'Cubase', 'Rec', 'Mixe', 'Master'],
  'Directeurs artistiques et chefs de projets': ['Identité visuelle', 'Identité musicale', 'Direction artistique', 'Gestion de projet', 'Stratégie de sortie', 'Stratégie de communication', 'Développement'],
  'Avocats et experts-comptables': ['Contrats', 'Création de société', 'Déclaration des revenus', 'Gestion des droits', 'Fiscalité']
}
