<div class="error">
  <span class="title">Une erreur est survenue</span>
  <span class="description">
    <span
      >Vous allez être redirigé vers la page de création de campagne d'ici
      quelques secondes.</span
    >
    <span>N'hésitez pas à nous contacter si le problème persiste !</span>
  </span>
  <div class="buttons">
    <app-button
      text="Revenir au site"
      theme="secondary"
      href="/home"
      target="_parent"
    ></app-button>
    <app-button
      text="Recréer une campagne"
      (onClick)="returnToCreateCampaign()"
    ></app-button>
  </div>
</div>
