import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { CallToActionDialogComponent } from '../call-to-action-dialog/call-to-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { IUser } from '../../../../../../shared/src/interfaces';

type HeaderTheme = 'dashboard' | 'create-campaign' | 'e-learning';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  innerWidth = window.innerWidth;

  @Input() theme: HeaderTheme;
  @Input() demo = false;

  centerLogo = false;

  currentUser: IUser;

  subs = new SubSink();

  constructor(private router: Router, private dialog: MatDialog, private firebaseService: FirebaseService) {
    this.subs.sink = router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
      this.centerLogo = navEnd.url.includes('/modules/');
    });
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await firstValueFrom(this.firebaseService.getCurrentUserProperties());
  }

  openCoachingDialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog--coaching',
      data: { title: "Envie d'aller plus loin ?", text: "N’attendez plus et prenez un rendez-vous dès maintenant pour bénéficier de l’accompagnement de nos coachs spécialisés !", actionText: 'Nous contacter >>', actionExtUrl: 'https://calendly.com/kelly-zje/appel-decouverte-mymusicads-15mn', hideLogin: !!this.currentUser }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
