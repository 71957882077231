import { IInputFormField } from '../../models/InputFormField';

export const links: IInputFormField[] = [
  {
    placeholder: 'Ex: www.youtube.com/watch?v=mjntQbdN4SE',
    formControlName: 'youtubeUrl',
    label: 'Url du clip YouTube',
    helper: '',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['youtube', 'facebook', 'instagram', 'snapchat', 'tiktok']
      }
    ]
  },
  {
    placeholder: 'Ex: mickael@mymusicads.com',
    formControlName: 'youtubeEmail',
    label: 'Adresse mail liée à la chaîne YouTube',
    helper: '',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['youtube']
      }
    ]
  },
  {
    placeholder: 'Ex: www.facebook.com/mymusicads',
    formControlName: 'facebookPage',
    label: 'Url de la page Facebook',
    helper: 'Page Facebook artiste nécessaire pour la publicité Instagram et Facebook',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['*']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['facebook', 'instagram']
      }
    ]
  },
  {
    placeholder: 'Ex: www.facebook.com/235798011693687/posts/390078559598964/?d=n',
    formControlName: 'facebookPostUrl',
    label: 'Url du post Facebook',
    helper: '',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['publication']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['facebook']
      }
    ]
  },
  {
    placeholder: 'Ex: www.instagram.com/p/CcYFu9YIr9V/',
    formControlName: 'instaPostUrl',
    label: 'Url du post Instagram',
    helper: '',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['publication']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['instagram']
      }
    ]
  },
  {
    placeholder: 'Ex: open.spotify.com/album/276u69rj7Nk24VMSk8AzpY?si=fnG6X45BR8CcS-BMhempFw',
    formControlName: 'projectUrl',
    label: 'Url du projet',
    helper: 'Lien Spotify ou Smartlink (liens avec toutes les plateformes de streaming)',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['project']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['spotify', 'facebook', 'instagram', 'snapchat', 'tiktok']
      }
    ]
  },
  {
    placeholder: 'Ex: vm.tiktok.com/ZMLg89Bkq/',
    formControlName: 'tiktokPostUrl',
    label: 'ID du post Tiktok',
    helper: 'ID de votre post Tiktok',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['publication']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['tiktok']
      }
    ]
  },
  {
    placeholder: 'Ex: fb.me/e/5ClJuJYCn',
    formControlName: 'eventUrl',
    label: "Url de l'évènement",
    helper: 'Billetterie ou évènement Facebook',
    required: true,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['event']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['instagram', 'facebook']
      }
    ]
  },
  {
    placeholder: 'Ex: we.tl/t-h6hzPrakFL',
    formControlName: 'weTransferUrl',
    label: 'Lien WeTransfer',
    helper: 'Afin de nous communiquer votre contenu ',
    required: false,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip', 'project', 'event']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['facebook', 'instagram', 'snapchat', 'tiktok']
      }
    ]
  },
  {
    placeholder: 'Ex: we.tl/t-h6hzPrakFL',
    formControlName: 'weTransferUrlSpotify',
    label: 'Lien WeTransfer',
    helper: "Afin de nous communiquer l'extrait audio",
    required: false,
    conditions: [
      {
        formGroupId: 0,
        fieldName: 'type',
        validOptions: ['clip', 'project', 'event']
      },
      {
        formGroupId: 1,
        fieldName: 'platform',
        validOptions: ['spotify']
      }
    ]
  }
];
