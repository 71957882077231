<div class="body">
  <div class="header" (click)="navigate('/home')">
    <img class="left-arrow" src="../../../assets/icons/arrow-left-grey.svg" alt="Exit arrow" />
    <span>Retourner vers l'accueil</span>
  </div>
  <div class="content">
    <div class="title-wrapper">
      <div class="left-title">
        <a class="logo-wrapper" href="/home">
          <img class="logo-mma" src="../../../assets/images/MMA_LOGO_JAUNE_RVB_entier.svg" alt="Logo MyMusicAds" />
        </a>
        <h2>Rejoignez-nous et faites</h2>
        <h2>décoller votre carrière artistique</h2>
      </div>
      <div class="left-medias desktop">
        <a href="https://www.mymusicads.com/nous-contacter">
          <img src="../../../../assets/icons/signup/medias/message.svg" />
        </a>
        <a href="https://www.instagram.com/mymusicads">
          <img src="../../../../assets/icons/signup/medias/insta.svg" />
        </a>
        <a href="https://www.mymusicads.com/">
          <img src="../../../../assets/icons/signup/medias/mma.svg" />
        </a>
      </div>
    </div>
    <app-login *ngIf="currentRoute === AuthRoute.LOGIN" [redirectUrl]="redirectUrl"></app-login>
    <app-signup *ngIf="currentRoute == AuthRoute.SIGNUP"></app-signup>
    <app-reset-password *ngIf="currentRoute === AuthRoute.RESET_PASSWORD"></app-reset-password>

    <div class="left-medias mobile">
      <a href="https://www.mymusicads.com/nous-contacter">
        <img src="../../../../assets/icons/signup/medias/message.svg" />
      </a>
      <a href="https://www.instagram.com/mymusicads">
        <img src="../../../../assets/icons/signup/medias/insta.svg" />
      </a>
      <a href="https://www.mymusicads.com/">
        <img src="../../../../assets/icons/signup/medias/mma.svg" />
      </a>
    </div>
  </div>
</div>
