<div class="login-form-wrapper">
  <span class="title">Connexion</span>
  <div class="providers">
    <div class="provider-button" (click)="signInWithGoogle()">
      <img src="../../../../assets/icons/google.svg" />
      <span>Connectez-vous avec Google</span>
    </div>
  </div>
  <span class="or">ou</span>
  <div class="inputs">
    <div class="input">
      <span class="label">Mail</span>
      <input [(ngModel)]="email" placeholder="Adresse e-mail" />
    </div>
    <div class="input">
      <span class="label">Mot de passe</span>
      <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password" placeholder="Mot de passe" (keyup.enter)="signIn()" />
      <img *ngIf="!showPassword; else barred" src="../../../assets/icons/eyes.svg" class="toggle-password" (click)="togglePasswordVisibility($event)" />
      <ng-template #barred>
        <img src="../../../assets/icons/eyes-barred.svg" class="toggle-password" (click)="togglePasswordVisibility($event)" />
      </ng-template>
    </div>
  </div>
  <span class="error-message">{{ currentErrorMessage }}</span>
  <div class="finalize">
    <span (click)="openForgotPasswordDialog()">Mot de passe <span>oublié?</span></span>
    <app-button size="sm" (click)="signIn()" text="Je me connecte" color="#fff"></app-button>
  </div>
</div>
<span class="no-account">Vous n'avez pas de compte? <span (click)="navigate('/profile/signup')">Créer mon compte</span></span>
