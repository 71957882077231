<form class="form" [formGroup]="newModuleForm">
  <div class="form-element">
    <label for="name">Nom du module <span class="mandatory-symbol">*</span></label>
    <input id="name" type="text" formControlName="name" />
  </div>
  <div class="form-element">
    <label for="description">Description <span class="mandatory-symbol">*</span></label>
    <textarea id="description" type="textbox" formControlName="description"></textarea>
  </div>
  <div class="form-element">
    <label for="duration">Durée (si - d'1h mettre "0") <span class="mandatory-symbol">*</span></label>
    <input id="duration" type="number" formControlName="duration" />
  </div>
  <div class="form-element">
    <label for="isAvailableInDemo">Disponible en démo ? <span class="mandatory-symbol">*</span></label>
    <div class="checkbox-wrapper">
      <input id="isAvailableInDemo" type="checkbox" formControlName="isAvailableInDemo" />
    </div>
  </div>
  <div class="form-element">
    <label for="picture">Image (format png, 1:1) <span class="mandatory-symbol">*</span></label>
    <span class="picture-wrapper">
      <input #uploadFileInput id="picture" type="file" accept="*.png" (change)="onPictureChange($event)" />
      <app-button size="sm" text="Télécharger une photo" [outlined]="true" (onClick)="uploadFileInput.click()"></app-button>
      <span class="picture-content-wrapper">
        <ng-container *ngIf="newModuleForm.get('picture').value !== null; else noFileSelected">
          <img src="../../../../../assets/icons/check.svg" />
          <img class="delete-picture" (click)="onPictureChange(null)" src="../../../../../assets/icons/delete.svg" />
        </ng-container>
        <ng-template #noFileSelected>
          <span>Pas de fichier selectionné.</span>
        </ng-template>
      </span>
    </span>
  </div>
</form>
<div class="element-form-buttons">
  <app-button [disabled]="!this.data.elementInfo" theme="danger" [outlined]="true" text="Supprimer" (click)="deleteModule()"></app-button>
  <app-button [disabled]="!newModuleForm.valid" text="Sauvegarder" (click)="saveModuleWithOperation('save')"></app-button>
  <app-button [disabled]="!newModuleForm.valid" text="Sauvegarder et ouvrir" (click)="saveModuleWithOperation('open')"></app-button>
</div>
<div class="loading-layer" *ngIf="loading">
  <app-loader></app-loader>
</div>
