<form class="form" [formGroup]="newChapterForm">
  <div class="form-element">
    <label for="name">Nom du chapitre <span class="mandatory-symbol">*</span></label>
    <input id="name" type="text" formControlName="name" />
  </div>
</form>
<div class="element-form-buttons">
  <app-button [disabled]="!this.data.elementInfo" theme="danger" [outlined]="true" text="Supprimer" (click)="deleteChapter()"></app-button>
  <app-button [disabled]="!newChapterForm.valid" text="Sauvegarder" (click)="saveChapterWithOperation('save')"></app-button>
  <app-button [disabled]="!newChapterForm.valid" text="Sauvegarder et ouvrir" (click)="saveChapterWithOperation('open')"></app-button>
</div>
