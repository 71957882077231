<form class="forgot-form-wrapper" [formGroup]="newPasswordForm" (ngSubmit)="setPassword()">
  <span class="title">Changer son mot de passe</span>

  <div class="inputs">
    <div class="input">
      <span class="label">Mot de passe</span>
      <input [type]="showPassword ? 'text' : 'password'" formControlName="password" name="password"
      placeholder="Mot de passe" (blur)="validatePassword()" />
      <img *ngIf="!showPassword; else barred" src="../../../assets/icons/eyes.svg" class="toggle-password" (click)="togglePasswordVisibility($event)" />
      <ng-template #barred>
        <img src="../../../assets/icons/eyes-barred.svg" class="toggle-password" (click)="togglePasswordVisibility($event)" />
      </ng-template>
    </div>

    <div class="input">
      <span class="label">Confirmation de mot de passe</span>
      <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" name="confirmPassword"
      placeholder="Confirmation de mot de passe" (blur)="validateConfirmPassword()" />
      <img *ngIf="!showConfirmPassword; else barredConfirm" src="../../../assets/icons/eyes.svg" class="toggle-password"
        (click)="toggleConfirmPasswordVisibility($event)" />
      <ng-template #barredConfirm>
        <img src="../../../assets/icons/eyes-barred.svg" class="toggle-password"
        (click)="toggleConfirmPasswordVisibility($event)" />
      </ng-template>
    </div>
  </div>

  <div [ngStyle]="{ visibility: errorMessage ? 'visible' : 'hidden' }" class="error-message">{{ errorMessage ?? 'empty'
    }}</div>
  <app-button size="sm" [disabled]="!canSubmit()" type="submit" text="Réinitialiser le mot de passe" color="#fff"></app-button>

</form>