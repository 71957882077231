import { Component, OnDestroy, OnInit, Input, HostListener, ViewChild, ViewEncapsulation, ElementRef, TemplateRef } from '@angular/core';
import { SubSink } from 'subsink';
import { BehaviorSubject } from 'rxjs';
import { NgxSplideComponent } from 'ngx-splide';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Carousel implements OnInit, OnDestroy {
  subs = new SubSink();

  @Input() cards: any[] | null;
  @Input() categoryTitle: string;
  @Input() uniqueSense: boolean = false;
  @Input() templateRef1: TemplateRef<any>;
  @Input() templateRef2: TemplateRef<any>;
  @Input() cardWidth: number;

  @ViewChild('splide') splide: NgxSplideComponent;
  @ViewChild('sectionCoaching') sectionCoaching: ElementRef;
  @ViewChild('wrapperCarousel') wrapperCarousel: ElementRef;

  cardsInput: [null, null, null, null, null];

  DEFAULT_GAP = 16;

  mousePosition: string = '';

  notEnoughCardsMode: boolean = false;
  type: string = 'loop';
  gap: number = this.DEFAULT_GAP;
  isMounted: boolean = false;
  isEnoughCardsMode$ = new BehaviorSubject<boolean>(true);
  windowWidth: number;
  init: boolean = false;

  options = {
    type: this.type,
    perPage: 2,
    direction: 'ltr',
    pagination: false,
    arrows: false,
    trimSpace: 'move',
    gap: `${this.gap}px`,
    drag: true,
    isNavigation: true,
    dragMinThreshold: {
      mouse: 0,
      touch: 30,
    },
    flickPower: 300
  };

  removeFirstOrLastClass() {
    const allSlides = document.getElementsByClassName(`${this.categoryTitle}`);
    const splideSlides = allSlides[0]?.querySelectorAll('.splide__slide');

    splideSlides?.forEach((slide) => {
      slide.classList.remove('first-or-last');
    });
  }

  updateEdgeVisibleClasses(init?: boolean) {
    const allSlides = document.getElementsByClassName(`${this.categoryTitle}`);
    const splideSlides = allSlides[0]?.querySelectorAll('.splide__slide');

    if (!splideSlides || (splideSlides?.length === 0 && this.type === 'loop')) {
      setTimeout(() => {
        this.updateEdgeVisibleClasses(this.type === 'loop' ? true : undefined);
      }, 1000);
      return;
    }

    const visibleIndexes = Array.from(splideSlides).reduce((indexes, slide, index) => {
      if (slide.classList.contains('is-visible')) {
        indexes.push(index);
      }
      return indexes;
    }, []);

    splideSlides.forEach((slide) => {
      slide.classList.remove('first-or-last');
    });

    if (visibleIndexes.length > 0) {
      let firstVisibleIndex = visibleIndexes[0] - 1;
      let lastVisibleIndex = visibleIndexes[visibleIndexes.length - 1] + 1;

      if (!splideSlides[firstVisibleIndex]) return;

      this.isElementVisible(splideSlides[firstVisibleIndex] as HTMLElement).then((isElementVisible) => {
        if (!isElementVisible && !this.uniqueSense) {
          firstVisibleIndex++;
          lastVisibleIndex--;
        }

        if (firstVisibleIndex >= 0 && !this.uniqueSense) {
          splideSlides[firstVisibleIndex].classList.add('first-or-last');
        }
        if (lastVisibleIndex < splideSlides.length) {
          splideSlides[lastVisibleIndex].classList.add('first-or-last');
        }
      });
    }
    if (init === true) {
      if (this.mousePosition === 'left') {
        // this.goPrev();
      }
      if (this.mousePosition === 'right') {
        // this.goNext();
      }
    } 
  }

  goPrev() {
    if (this.splide) {
      this.removeFirstOrLastClass();
      this.splide.getSplideInstance().go('<');
    }
  }

  goNext() {
    if (this.splide) {
      this.removeFirstOrLastClass();
      this.splide.getSplideInstance().go('>');
    }
  }

  constructor() {}

  calculateVisibleCards() {
    if (!this.wrapperCarousel?.nativeElement?.clientWidth) {
      setTimeout(() => {
        this.calculateVisibleCards();
      }, 1000);
      return;
    }
    this.windowWidth = this.wrapperCarousel?.nativeElement?.clientWidth;
    let nbCards;
    if (this.uniqueSense) nbCards = Math.floor((this.windowWidth + this.DEFAULT_GAP) / (this.cardWidth + this.DEFAULT_GAP));
    else nbCards = Math.round((this.windowWidth - (this.cardWidth + this.DEFAULT_GAP + this.cards.length * this.DEFAULT_GAP) - this.DEFAULT_GAP) / (this.cardWidth + this.DEFAULT_GAP));

    if (!this.cards.length) return;

    if (this.cards.length <= nbCards) {
      this.isEnoughCardsMode$.next(true);
      this.type = 'slide';
      this.gap = 0;

      if (!this.splide) {
        return;
      }
    } else if (this.cards.length > 1) {
      this.isEnoughCardsMode$.next(false);
      this.type = 'loop';
      this.gap = this.DEFAULT_GAP;
    }
    this.updateEdgeVisibleClasses();
  }

  @HostListener('window:resize')
  onResize() {
    this.mousePosition = '';
    this.calculateVisibleCards();
  }

  onMouseMove(event: MouseEvent) {
    const screenWidth = window.innerWidth;
    const mouseX = event.clientX;

    if (mouseX < (screenWidth / 2 - 100)) {
      if (this.mousePosition === '' || this.mousePosition !== 'left') {
        this.mousePosition = 'left';
        if (this.init === false) {
          this.init = true;
        }

        // this.goNext();
      }
    } else if (mouseX > (screenWidth / 2 + 100)) {
      if (this.mousePosition === '' || this.mousePosition !== 'right') {
        this.mousePosition = 'right';
        if (this.init === false) {
          this.init = true;
          // this.onResize();
        }
        // this.goPrev();
      }
    }
  }

  isElementVisible(element: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          resolve(entry.isIntersecting);
          observer.disconnect();
        },
        { threshold: 0.01 }
      );

      observer.observe(element);
    });
  }
  ngOnInit() {
    if (!this.cards) {
      this.cards = [null, null, null, null, null];
    }

    if (!this.uniqueSense) {
      this.options['focus'] = 'center';
    } else {
      this.options['focus'] = 0;
    }
    this.onResize();
  }

  ngOnDestroy(): void {}
}
