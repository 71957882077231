import { Component, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@shared';
import { HttpClient } from '@angular/common/http';
import { CalendlyService } from 'src/app/modules/services/calendly/calendly.service';
import { categoriesMappingPlurialSingular } from '@shared';

@Component({
  selector: 'app-coach-main-card',
  templateUrl: './coach-main-card.component.html',
  styleUrls: ['./coach-main-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoachMainCardComponent {
  subs = new SubSink();

  @Input() coach: Partial<IUser>;
  @Input() mobileMode: boolean;
  @Output() scrollToCalendlyEvent = new EventEmitter<void>();

  categoriesMappingPlurialSingular = categoriesMappingPlurialSingular;

  constructor(private route: ActivatedRoute, private http: HttpClient, private calendlyService: CalendlyService) {
    console.log('this.coach', this.coach);
  }

  formatDuration(duration: string): string {
    return this.calendlyService.formatDuration(parseInt(duration));
  }

  bookSession() {
    this.scrollToCalendlyEvent.emit();
  }
}
