<ng-container *ngIf="!isPictureLoading; else loadingUser">
  <div #profileMinified class="profile-minified" *ngIf="currentUser; else emptyUser" (click)="toggleContextMenu()">
    <img class="profile-picture clickable" [src]="currentUser?.photoUrl && currentUser?.photoUrl !== '' ? currentUser.photoUrl : '../../../assets/icons/empty-profile.png'" referrerpolicy="no-referrer" />
    <div [ngStyle]="{ display: isOpen ? 'flex' : 'none' }" class="context-menu" [ngClass]="mobile ? 'openMobileMode' : ''">
      <img class="profile-picture" [src]="currentUser?.photoUrl && currentUser?.photoUrl !== '' ? currentUser.photoUrl : '../../../assets/icons/empty-profile.png'" referrerpolicy="no-referrer" />
      <div class="texts">
        <ng-container *ngIf="currentUser.displayName; else noDisplayName">
          <span class="name">{{ currentUser.displayName }}</span>
          <span class="email">{{ currentUser.email }}</span>
        </ng-container>
        <ng-template #noDisplayName>
          <span class="name">{{ currentUser.email }}</span>
        </ng-template>
      </div>
      <app-button [size]="innerWidth > 768 ? 'md' : 'sm'" [outlined]="true" text="Paramètres" icon="user-settings" (onClick)="goToManageProfile()"></app-button>
      <app-button [size]="innerWidth > 768 ? 'md' : 'sm'" text="Déconnexion" icon="logout" (onClick)="signOut()"></app-button>
    </div>
  </div>
</ng-container>

<ng-template #emptyUser>
  <img class="profile-picture clickable empty-picture" src="../../../assets/icons/empty-profile.png" (click)="openContactUsDemoDialog()" />
</ng-template>

<ng-template #loadingUser>
  <div class="profile-picture">
    <div class="loading-picture"></div>
  </div>
</ng-template>
