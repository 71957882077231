import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  showPassword = false;
  currentErrorMessage = '';

  @Input() redirectUrl = "home";

  constructor(private firebaseService: FirebaseService, private router: Router, public dialog: MatDialog, private location: Location) {
  }

  ngOnInit() {
    if ((this.location.getState() as any).redirectTo) {
      this.redirectUrl = (this.location.getState() as any).redirectTo;
    }
  }

  async signIn() {
    await this.firebaseService.signIn(this.email, this.password).then(res => {
      this.router.navigate([this.redirectUrl]);
    }).catch(() => {
      this.currentErrorMessage = "L'email ou le mot de passe est incorrect.";
    })
    this.password = '';
  }

  async signInWithGoogle() {
    try {
      await this.firebaseService.signInWithGoogle();
      this.router.navigate([this.redirectUrl]);
    } catch (error) {
      this.currentErrorMessage = this.firebaseService.getErrorMessages(error.code);
    } finally {
      this.email = '';
      this.password = '';
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  openForgotPasswordDialog(): void {
    this.dialog.open(ForgotPasswordDialogComponent, {
      width: '400px',
      panelClass: 'forgot-password-dialog'
    });
  }

  togglePasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }
}