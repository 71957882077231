import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-new-subscriber-dialog',
  templateUrl: './new-subscriber-dialog.component.html',
  styleUrls: ['./new-subscriber-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewSubscriberDialogComponent {
  email = "";
  loading = false;

  constructor(public dialogRef: MatDialogRef<NewSubscriberDialogComponent>, private firebaseService: FirebaseService) { }

  async createNewSubscriber(email: string): Promise<void> {
    if (email) {
      this.loading = true;

      const uid = await this.firebaseService.createUserBackOffice(email).then((res: string) => {
        alert(`Utilisateur ${email} créé avec succès !`);
        return res;
      }).catch((err) => alert(err));

      this.loading = false;
      this.dialogRef.close(uid);
    }
  }
}
