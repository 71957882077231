import { IStepHeader } from '../../models/StepHeader';

export const stepHeaders: IStepHeader[] = [
  {
    name: 'error',
    title: '.',
    description: '.'
  },
  {
    name: 'type',
    title: 'Créer une campagne pour :',
    description:
      'Choisissez le type de contenu pour lequel vous souhaitez lancer une campagne publicitaire'
  },
  {
    name: 'platform-project',
    title: 'Créer la campagne sur :',
    description:
      'Choisissez la plateforme sur laquelle vous souhaitez faire la promotion de votre projet.'
  },
  {
    name: 'platform-publication',
    title: 'Créer la campagne sur :',
    description:
      'Choisissez la plateforme sur laquelle vous souhaitez faire la promotion de votre publication.'
  },
  {
    name: 'platform-clip',
    title: 'Créer la campagne sur :',
    description:
      "Choisissez la plateforme sur laquelle vous souhaitez faire la promotion de votre clip."
  },
  {
    name: 'platform-event',
    title: 'Créer la campagne sur :',
    description: "Choisissez la plateforme sur laquelle vous souhaitez faire la promotion de votre évènement."
  },
  {
    name: 'links',
    title: 'Liens et pays ciblés',
    description: 'Veuillez renseigner les différents champs ci-dessous.'
  },
  {
    name: 'budget',
    title: 'Budget de la campagne',
    description: 'Indiquez votre budget, des estimations des résultats vont apparaître.'
  }
];
