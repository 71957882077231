import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { Observable, filter, map } from 'rxjs';
import { ICourse, IMasterclass, IModule } from '@shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  rootURL = '/api';

  constructor(private firebaseService: FirebaseService, private http: HttpClient) { }

  getModuleCourses(module: IModule): ICourse[] {
    const courses = [];
    module.chapters.forEach((chapter) => courses.push(...chapter.courses));
    return courses;
  }

  getCompletedCoursesProgress$(module: IModule): Observable<number> {
    return this.firebaseService.getELearningData().pipe(map((progress) => {
      const moduleCourses = this.getModuleCourses(module);
      let totalCoursesInModule = moduleCourses.length;
      if (!totalCoursesInModule) {
        return 0;
      }
      let totalCoursesCompletedFromModule = 0;
      progress?.completedCourses?.forEach((completedCourse) => totalCoursesCompletedFromModule += (moduleCourses.find((course: ICourse) => course.id === completedCourse) ? 1 : 0));
      return Math.round((totalCoursesCompletedFromModule / totalCoursesInModule) * 100);
    }));
  }
}
