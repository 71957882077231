import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() hasCallToAction: boolean = false;
  @Input() isUserConnected: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
