import { Component, Input, OnInit } from '@angular/core';
import { determineMasterclassImagePath } from '../../utils/determine-image-path';
import { IMasterclass } from '@shared';

@Component({
  selector: 'app-masterclass-thumbnail',
  templateUrl: './masterclass-thumbnail.component.html',
  styleUrls: ['./masterclass-thumbnail.component.scss']
})
export class MasterclassThumbnailComponent implements OnInit {
  @Input() masterclass: IMasterclass;
  @Input() isSelected = false;

  constructor() { }

  ngOnInit(): void {
  }
}
