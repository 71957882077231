<div class="modules">
  <div class="modules-header">
    <app-button class="back-button" theme="info" text="<< Retour" size="xs" [outlined]="true" (onClick)="navigateToELearning()"></app-button>
    <span class="title">Modules</span>
  </div>
  <div class="content" *ngIf="selectedModule && masterclassVersion$ | async; else loading">
    <div id="module-thumbnails" class="thumbnails">
      <ng-container *ngFor="let module of masterclass.modules">
        <app-module-thumbnail
          [module]="module"
          [isSelected]="module.id === selectedModule.id"
          [disabled]="!module.isAvailableInDemo && (demo || !masterclass.isObtained)"
          (click)="onThumbnailClick(module)"
        ></app-module-thumbnail>
      </ng-container>
    </div>
    <div *ngIf="innerWidth > 768" class="splitting-bar"></div>
    <div class="details">
      <app-module-overview
        *ngIf="innerWidth > 768 || isModuleOverviewOpen"
        [module]="selectedModule"
        [masterclass]="masterclass"
        [loadingProgress]="loadingProgress"
        (goToModule)="onModuleClick($event)"
        (goToBuyMasterclass)="buyMasterclass($event)"
        (closeModuleOverview)="closeModuleOverview()"
      ></app-module-overview>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
