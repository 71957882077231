<div class="mcq">
  <form class="mcq-form" *ngIf="mcq" [formGroup]="mcqForm">
    <form class="question" *ngFor="let question of mcq; index as qIndex" [formGroupName]="'Q' + qIndex">
      <span class="title">{{ question.question }}</span>
      <div class="propositions">
        <div *ngFor="let proposition of question.propositions; index as pIndex" class="proposition" [ngClass]="checkAnswer(qIndex, pIndex) + '-answer'">
          <input class="proposition-input" name="answer" [type]="question.hasMultipleAnswers ? 'checkbox' : 'radio'" (change)="setAnswerValue(qIndex, pIndex)" />
          <span>{{ proposition.proposition }}</span>
        </div>
      </div>
    </form>
    <div class="buttons">
      <app-button class="confirm-button" [outlined]="true" type="submit" text="Confirmer" (onClick)="checkMcqValidity()"></app-button>
      <app-button class="next-course-button" [text]="isSelectedCourseLast ? 'Compléter le module' : 'Cours suivant'" (onClick)="validateCourse()" [disabled]="!isMcqValid"></app-button>
    </div>
  </form>
</div>
