<div class="main-card" [class.background-card]="!mobileMode" [class.mobile-card]="mobileMode">
    <div class="wrapper-coach-infos">
        <img *ngIf="!mobileMode" class="image-placeholder"
            [src]="coach.photoUrl ? coach.photoUrl : '../../../../../assets/images/coaching/default-coach-image.png'" />
        <div class="wrapper-title-category">
            <span>{{coach.firstName}} <span>{{coach.lastName.split('')[0]}}.</span></span>
            <span>{{categoriesMappingPlurialSingular[coach.coachInfo.categoryTitle]}}</span>
        </div>
        <div class="categories">
            <span class="category" *ngFor="let category of coach.coachInfo.coachingCategories">{{ category }}</span>
        </div>
    </div>
    <ng-container *ngIf="coach?.coachInfo?.sessions?.length > 0; else noSessions">
        <div class="sessions">
            <span *ngFor="let session of coach.coachInfo.sessions; index as i">
                <span class="duration">Session {{+session.price > 0 ? 'coaching' : 'découverte' }} <span>{{formatDuration(session.duration)}}</span></span>
                <span class="price">{{ +session.price > 0 ? (session.price + '€') : 'Gratuit' }}</span>
            </span>
        </div>
        <app-button class="book-button" size="md" text="Réserver une session" (click)="bookSession()" [color]="'#fff'"></app-button>
    </ng-container>
    <ng-template #noSessions>
        <span class="no-session">Pas de session disponible actuellement</span>
    </ng-template>
</div>