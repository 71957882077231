import { IFaq } from '../models/Faq';

export const faq: IFaq[] = [
  {
    question: `Comment choisir mon coach ?`,
    answers: [
      `Choisissez le coach idéal en seulement quelques clics:`,
      `1. Définissez vos besoins : création de label, subventions, vidéos, collecte de droits…`,
      `2. Regardez les profils des coachs en fonction de la catégorie et des services proposés`,
      `3. Réservez votre premier appel gratuitement directement sur le profil du coach`
    ]
  },
  {
    question: `Comment réserver une session avec un coach ?`,
    answers: [
      `Pour réserver une session, c’est très simple:`,
      `1. Sélectionnez le coach que vous souhaitez : cliquez sur son profil et choisissez un créneau de 15 minutes dans l’agenda affiché sur son profil pour un premier échange`,
      `2. Échange initial : discussion téléphonique avec un membre de My Music Ads pour échanger sur vos besoins et préparer votre session avec le coach`,
      `3. Planification de la session : nous programmerons ensuite la session avec le coach en fonction de vos disponibilités, vous recevrez une confirmation avec tous les détails nécessaires et le lien de paiement`,
      `Ce procédé garantit que votre session est parfaitement adaptée à votre emploi du temps et à vos besoins.`
    ]
  },
  {
    question: `Comment se déroule ma session ?`,
    answers: [
      `Une fois que vous avez choisi le coach qu’il vous faut, vous pouvez bloquer un appel de 15 minutes gratuitement avec un membre de My Music Ads pour préparer votre session avec le coach : objectifs, date de la session, déroulement...`,
      `Un lien de visioconférence vous sera envoyé avant votre session de coaching.
Lorsqu’il sera l’heure de votre session, connectez-vous et commencez votre session avec l’expert. 
Nous utilisons Google Meet pour la visioconférence, vous n’avez rien à installer au préalable.`
    ]
  },
  {
    question: `Quels sont les tarifs ?`,
    answers: [
      `Chaque coach définit ses tarifs et formules. `,
      `Vous pouvez retrouver l’ensemble de ces informations directement sur les profils des coachs.`,
      `Certains coachs proposent des tarifs dégressifs en fonction du nombre d’heures choisi.`
    ]
  },
  {
    question: `Comment se déroule le paiement ?`,
    answers: [`Le paiement a lieu avant votre session, avec la technologie Stripe qui vous garantit un paiement en toute sécurité.`]
  },
  {
    question: `Existe-t-il un tarif dégressif en fonction du nombre d’heures sélectionnées ?`,
    answers: [`Oui, certains coachs proposent des tarifs dégressifs.`]
  },
  {
    question: `Combien de temps à l'avance dois-je réserver une session ?`,
    answers: [
      `Il est recommandé de réserver une session au moins 48 heures à l'avance pour garantir la disponibilité du coach. Toutefois, certains coachs peuvent avoir des créneaux disponibles à plus court terme.`
    ]
  },
  {
    question: `Puis-je réserver plusieurs sessions à l'avance ?`,
    answers: [`Oui, vous pouvez réserver plusieurs sessions avec le même coach ou avec des coachs différents.`]
  },
  {
    question: `Les sessions sont-elles confidentielles ?`,
    answers: [
      `Oui, toutes les sessions sont strictement confidentielles. Nos coachs sont tenus de respecter la confidentialité de chaque échange et des différents éléments communiqués afin de garantir la protection de vos informations personnelles et professionnelles.`
    ]
  },
  {
    question: `Puis-je inviter quelqu'un d'autre à participer à ma session ?`,
    answers: [
      `Oui, vous pouvez inviter une autre personne à participer à votre session de coaching, sous réserve de l’accord préalable du coach. Il faut donc nous faire part de cette demande lors de l’échange initial avant le coaching.`
    ]
  },
  {
    question: `Comment sont sélectionnés les coachs de la plateforme ?`,
    answers: [`Les coachs sont rigoureusement sélectionnés par nos équipes en fonction de leurs qualifications, de leurs expériences, et de leurs compétences.`]
  },
  {
    question: `D’autres questions ?`,
    answers: [
      `Si vous souhaitez obtenir plus de renseignements vous pouvez faire une demande de contact pour être rappelé : https://www.mymusicads.com/nous-contacter ou nous envoyer un mail sur contact@mymusicads.com `
    ]
  }
];
