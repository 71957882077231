import { Component, Input } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-intervention-card',
  templateUrl: './intervention-card.component.html',
  styleUrls: ['./intervention-card.component.scss']
})
export class InterventionCardComponent {
  subs = new SubSink();

  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;

  constructor(
  ) {

  }
}
