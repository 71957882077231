import { Component, EventEmitter, Output, Input, HostBinding  } from '@angular/core';
import { trigger, transition, animate, style, } from '@angular/animations';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-how-it-works-card',
  templateUrl: './how-it-works-card.component.html',
  styleUrls: ['./how-it-works-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HowItWorksCardComponent {
  subs = new SubSink();

  @Input() index: number;
  @Input() title: string;
  @Input() description: string;

  @Output() increment = new EventEmitter<any>();
  @Output() decrement = new EventEmitter<any>();
  @HostBinding('@fadeInOut') fadeInOut = true;

  

  incrementStep() {
    this.increment.emit();
  }

  decrementStep() {
    this.decrement.emit();
  }

  constructor(
  ) {

  }
}
