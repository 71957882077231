import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackOfficeELearningService } from '../back-office-e-learning.service';
import { IChapter, IMasterclass, IMasterclassVersion } from '@shared';
import { v4 as uuid } from 'uuid';
import { ELearningElementDialogOperation } from '../../models/ELearningElement';

@Component({
  selector: 'app-new-version-form-dialog',
  templateUrl: './new-version-form-dialog.component.html',
  styleUrls: ['./new-version-form-dialog.component.scss']
})
export class NewVersionFormDialogComponent implements OnInit {
  newVersionForm = this.fb.group({
    name: ['', Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { masterclasses: IMasterclass[] }, private fb: FormBuilder, public dialogRef: MatDialogRef<NewVersionFormDialogComponent>) { }

  ngOnInit(): void { }

  saveVersion(): void {
    if (this.newVersionForm.valid) {
      const formValue = this.newVersionForm.value;
      const version: IMasterclassVersion = {
        id: uuid(),
        creationDate: new Date(),
        name: formValue.name,
        masterclasses: this.data.masterclasses
      }
      this.dialogRef.close(version);
    }
  }

  openChapter(): void {
    this.dialogRef.close(true);
  }
}
