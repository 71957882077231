import { Component, Input } from '@angular/core';
import { SubSink } from 'subsink';



@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  subs = new SubSink();

  isResponseVisible: boolean = false;

  @Input() question: string;
  @Input() answers: string[];

  toggleResponse() {
    this.isResponseVisible = !this.isResponseVisible;
  }

}
