import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { BackOfficeComponent } from './back-office.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { getFrenchPaginatorIntl } from './utils/french-paginator-intl';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio'
import { SharedModule } from '../shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ELearningDataParsingService } from '../e-learning/parsing-service/e-learning-data-parsing.service';
import { ELearningElementComponent } from './e-learning/e-learning-element/e-learning-element.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewSubscriberDialogComponent } from './users/new-subscriber-dialog/new-subscriber-dialog.component';
import { EditUserFormDialogComponent } from './users/edit-user-form-dialog/edit-user-form-dialog.component';
import { PromoteUserDialogComponent } from './users/promote-user-dialog/promote-user-dialog.component';
import { NewMasterclassFormDialogComponent } from './e-learning/new-masterclass-form-dialog/new-masterclass-form-dialog.component';
import { NewModuleFormDialogComponent } from './e-learning/new-module-form-dialog/new-module-form-dialog.component';
import { NewChapterFormDialogComponent } from './e-learning/new-chapter-form-dialog/new-chapter-form-dialog.component';
import { NewCourseFormDialogComponent } from './e-learning/new-course-form-dialog/new-course-form-dialog.component';
import { NewQuestionFormDialogComponent } from './e-learning/new-question-form-dialog/new-question-form-dialog.component';
import { ELearningModule } from '../e-learning/e-learning.module';
import { ConfirmVersionChangeDialogComponent } from './e-learning/confirm-version-change-dialog/confirm-version-change-dialog.component';
import { NewVersionFormDialogComponent } from './e-learning/new-version-form-dialog/new-version-form-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [BackOfficeComponent, NewSubscriberDialogComponent, EditUserFormDialogComponent, PromoteUserDialogComponent, ELearningElementComponent, NewMasterclassFormDialogComponent, NewModuleFormDialogComponent, NewCourseFormDialogComponent, NewQuestionFormDialogComponent, NewChapterFormDialogComponent, ConfirmVersionChangeDialogComponent, NewVersionFormDialogComponent],
  imports: [CommonModule, MatFormFieldModule, MatNativeDateModule, MatInputModule, MatPaginatorModule, MatSortModule, MatTableModule, MatRadioModule, MatCheckboxModule, MatSelectModule, SharedModule, MatTooltipModule, MatTabsModule, ReactiveFormsModule, FormsModule, ELearningModule],
  exports: [BackOfficeComponent, MatTableModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MatTabsModule],
  providers: [{ provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl() }, ELearningDataParsingService]
})
export class BackOfficeModule { }
