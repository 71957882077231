import { Component, EventEmitter, Input, Output } from '@angular/core';

export type IButtonTheme = 'primary' | 'secondary' | 'danger' | 'info' | 'white' | 'coaching';
export type IButtonSize = 'xs' | 'sm' | 'md' | 'lg';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() type = 'button';
  @Input() theme: IButtonTheme = 'primary';
  @Input() outlined = false;
  @Input() size: IButtonSize = 'md';
  @Input() disabled: boolean = false;
  @Input() href: string = '';
  @Input() target: string = '';
  @Input() download: string = '';
  @Input() icon: string = '';
  @Input() color: string = '';

  @Output() onClick = new EventEmitter();

  getClass(theme: string, size: string, outlined: boolean): string {
    let className = 'button';
    className += ` button-${theme} button-${size}`;
    if (outlined) {
      className += ' outlined';
    }
    return className;
  }

}
