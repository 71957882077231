export const environment = {
  production: true,
  firebase: {
    projectId: 'mymusicads-b64ae',
    appId: '1:751237425362:web:37dd05e2b60ed625f6650b',
    databaseURL: 'https://mymusicads-b64ae-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'mymusicads-b64ae.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDfCsjyeLR9as41XOnF4Q0Ig9mEI46D5c4',
    authDomain: 'mymusicads-b64ae.firebaseapp.com',
    messagingSenderId: '751237425362',
    measurementId: 'G-18NH66Q2DR'
  },
  stripe_key: 'pk_live_51Js8uMCuB6kcvOFDYEcIv8kLwixqqstwZKw7WwvZ0IU3Iq9gW1CMiNZVAQHd5Fh5JzzydhZOLJen8yqk13Dpw0Ay00xK7acPSN'
};
