import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { howItWorksCards } from 'src/app/modules/home/json/how-it-works-cards';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  items = howItWorksCards;

  step = 0;
  showCard = true;
  mobileMode = false;
  innerWidth = window.innerWidth;

  constructor(
  ) {

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1024)
      this.mobileMode = false;
    else
      this.mobileMode = true;
  }

  stepIncrement() {
    this.showCard = false;
    if (this.step < this.items.length - 1) {
      this.step++;
    }
    setTimeout(() => this.showCard = true, 250);
  }

  stepDecrement() {
    this.showCard = false;
    if (this.step > 0) {
      this.step--;
    }
    setTimeout(() => this.showCard = true, 250);
  }

  async ngOnInit() {
    this.onResize();
  }

  ngOnDestroy(): void {
  }
}
